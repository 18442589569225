<template>
  <DataTable
    ref="dt"
    :value="devoluciones"
    dataKey="id"
    :loading="loading"
    stripedRows
    :rows="20"
    responsiveLayout="scroll"
    @sort="handleSort"
  >
    <Column field="created_at" header="Fecha" :sortable="true">
      <template #body="slotProps">
        {{ formatDate(slotProps.data.created_at) }}
      </template>
    </Column>
    <Column header="Ref. Pedido">
      <template #body="slotProps">
        {{ slotProps.data.referencia_pedido }}
      </template>
    </Column>
    <Column header="Ref. Producto">
      <template #body="slotProps">
        {{ slotProps.data.referencia_producto }}
      </template>
    </Column>
    <Column field="foto" header="Foto" :sortable="false">
      <template #body="slotProps">
        <Image
          :src="slotProps.data.foto"
          alt="Image"
          width="50"
          height="50"
          preview
        />
      </template>
    </Column>
    <Column field="producto" header="Producto" :sortable="false">
      <template #body="slotProps">
        {{ slotProps.data.producto }}
      </template>
    </Column>
    <Column header="Talla">
      <template #body="slotProps">
        {{ slotProps.data.talla }}
      </template>
    </Column>
    <Column header="Color">
      <template #body="slotProps">
        <div class="flex flex-column align-items-center">
          <div class="mb-2">
            {{ capitalizeFirstLetter(slotProps.data.color) }}
          </div>
          <div
            v-if="slotProps.data.color == 'multicolored'"
            :style="{
              width: '20px',
              height: '20px',
              background: slotProps.data.color,
            }"
            class="items_color multicolor"
          ></div>
          <div
            v-else
            :style="{
              width: '20px',
              height: '20px',
              background: slotProps.data.color,
            }"
            class="items_color"
          ></div>
        </div>
      </template>
    </Column>
    <Column header="Precio">
      <template #body="slotProps">
        ${{ formatThousands(slotProps.data.precio) }}
      </template>
    </Column>
    <Column header="Cant.">
      <template #body="slotProps">
        {{ slotProps.data.cantidad }}
      </template>
    </Column>
    <Column header="Estado">
      <template #body="slotProps">
        <span :class="'product-badge status-' + slotProps.data.estado">{{
          slotProps.data.estado
        }}</span>
      </template>
    </Column>
  </DataTable>
  <Paginator
    :rows="1"
    :totalRecords="totalRecords"
    @page="onPage($event, '14')"
  ></Paginator>
</template>
<script>
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { FilterMatchMode } from "primevue/api";
import { obtenerTodo } from "@/api/clienteHttpService";
import Echo from "laravel-echo";
import { decrypt } from "@/auth-guard";
window.Pusher = require("pusher-js");
export default {
  props: {
    referencia_pedido_filtro: {
      type: String,
      default: null,
    },
    identificacion_cliente_filtro: {
      type: String,
      default: null,
    },
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      messages: {
        severity: "",
        content: "",
      },
      loading: true,
      devoluciones: [],
      pedido: {},
      ordernador: null,
      actualizar_pedido: {},
      estados: null,
      filters: {},
      totalRecords: 0,
    };
  },
  validations() {
    return {
      actualizar_pedido: {
        id_detalle_estado: { required },
      },
    };
  },
  mounted() {
    window.Echo = new Echo({
      broadcaster: "pusher",
      key: process.env.VUE_APP_WSKEY,
      wsHost: process.env.VUE_APP_WSHOST,
      wsPort: process.env.VUE_APP_WSPORT,
      forceTLS: false,
      disableStats: true,
      encrypted: true,
    });
  },
  created() {
    this.tienda = decrypt(localStorage.getItem("id"));
    this.obtenerPedidos(0, "14");
    this.initFilters();
  },
  methods: {
    async obtenerPedidos(pagina = 0, estados = "14") {
      this.loading = true;
      this.devoluciones = [];
      let complemento = "";
      if (this.referencia_pedido_filtro !== null) {
        complemento += `&referencia_pedido=${this.referencia_pedido_filtro}`;
      }
      if (this.identificacion_cliente_filtro !== null) {
        complemento += `&numero_identificacion=${this.identificacion_cliente_filtro}`;
      }
      const pedidos = await obtenerTodo(
        "/pedidos/tiendaBack/" +
          this.tienda.id_tienda +
          "?paginado=" +
          pagina +
          "&estados=" +
          estados +
          "&orden=" +
          this.ordernador + 
          complemento
      );
      if (!pedidos.message) {
        this.totalRecords = pedidos.paginas;
        pedidos.pedidos.forEach((producto) => {
          this.devoluciones.push(producto);
        });
      }
      this.loading = false;
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    async onPage(event, estado) {
      await this.obtenerPedidos(event.page, estado);
    },
    handleSort(event) {
      if (event.sortOrder == 1 && event.sortField == "created_at") {
        this.ordernador = "1";
      } else if (event.sortOrder == -1 && event.sortField == "created_at") {
        this.ordernador = "2";
      }
      this.obtenerPedidos(0, "14");
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      return new Intl.DateTimeFormat("es-CO").format(date);
    },
    formatThousands(number) {
      return new Intl.NumberFormat("es-CO").format(number);
    },
  },
};
</script>
