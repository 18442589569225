import Axios from "axios";
import { decrypt } from "../auth-guard";

/* obtenerToken el token desencriptado */

const obtenerToken = () => {
  const token = decrypt(localStorage.getItem("token"))
    ? decrypt(localStorage.getItem("token"))
    : "";

  return token;
};

const instance = Axios.create({
  baseURL: process.env.VUE_APP_BASE_URL_BACKEND,

  headers: {
    "Content-Type": "application/json",

    Authorization: `Bearer ${obtenerToken().token || ""}`,
  },
});

instance.interceptors.request.use((config) => {
  if (obtenerToken()) {
    config.headers.Authorization = `Bearer ${obtenerToken().token}`;

    config.headers["Content-Type"] = "application/json";
  } else {
    // Do something... Usually logout user.
  }

  return config;
});

export default instance;
