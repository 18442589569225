<template>
  <div class="surface-0 flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden">
    <div class="grid justify-content-center p-2 lg:p-0" style="min-width:80%">
      <div class="col-12 mt-5 mb-3 xl:mt-0 text-center">
        <Logo />
      </div>
      <div class="col-12 xl:col-6"
        style="border-radius:56px; padding:0.3rem; background: linear-gradient(180deg, var(--primary-color), rgba(33, 150, 243, 0) 30%);">
        <div class="h-full w-full m-0 py-7 px-4"
          style="border-radius:53px; background: linear-gradient(180deg, var(--surface-50) 38.9%, var(--surface-0));">
          <div class="text-center mb-5">
            <div class="text-900 text-3xl font-medium mb-3">Iniciar sesión</div>
          </div>
          <div class="col-12">
            <Message v-if="showMessage" severity="error">{{messageError}}</Message>
          </div>
          <div class="w-full md:w-10 mx-auto">
            <label for="email1" class="block text-900 text-xl font-medium mb-2">Correo electrónico</label>
            <InputText @click="CerrarMensajeError()" id="email" name="email" v-model="email" type="email"
              class="w-full mb-3" placeholder="ejemplo@correo.com" style="padding: 1rem"
              v-on:keyup.enter="login(!v$.$invalid)" />
            <label for="password1" class="block text-900 font-medium text-xl mb-2">Contraseña</label>
            <Password @click="CerrarMensajeError()" name="password" id="password" :feedback="false" v-model="password"
              placeholder="Contraseña" :toggleMask="true" class="w-full mb-3" inputClass="w-full"
              inputStyle="padding:1rem" v-on:keyup.enter="login(!v$.$invalid)"></Password>
            <div class="flex align-items-center justify-content-between mb-5">
              <div class="flex align-items-center">
                <Checkbox id="remember_me" name="remember_me" v-model="remember_me" :binary="true" class="mr-2">
                </Checkbox>
                <label for="remember_me">Recordarme</label>
              </div>
              <a class="font-medium no-underline ml-2 text-right cursor-pointer" style="color: var(--primary-color)">
                <router-link to="/recuperar_contrasena">Recuperar contraseña</router-link>
              </a>
            </div>
            <Button @click="login(!v$.$invalid)" label="Iniciar sesión" class="w-full p-3 text-xl"
              :loading="cargando"></Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import { loginServices,userServices} from "@/api/loginServices";
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      email: "",
      password: "",
      remember_me: false,
      showMessage: false,
      messageError: "Ingrese un correo y una contraseña validos",
      cargando: false,
    };
  },
  validations() {
    return {
      email: { required, email },
      password: { required },
    };
  },
  methods: {
    async login(isFormValid) {
      this.cargando = true;
      if (!isFormValid) {
        this.showMessage = this.showMessage == true ? false : true;
        this.cargando = false;
        return;
      } else {
        const resp = await loginServices(this.email, this.password, this.remember_me);
        if (resp == "200") {
          this.cargando = false;
          await userServices();
          this.$router.push("/Configuraciones");
        } else {
          this.showMessage = !this.showMessage;
          this.cargando = false;
        }
      }
    },
    async recaptcha() {
      await this.$recaptchaLoaded();
    },
    CerrarMensajeError() {
      this.showMessage = false;
    },
  },
  computed: {
    logoColor() {
      if (this.$appState.darkTheme) return "white";
      return "dark";
    },
  },
};
</script>

<style scoped>
.pi-eye {
  transform: scale(1.6);
  margin-right: 1rem;
}

.pi-eye-slash {
  transform: scale(1.6);
  margin-right: 1rem;
}
</style>
