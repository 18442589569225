<template>
  <div class="grid flex justify-content-center">
    <div class="col-12">
      <div class="card">
        <Toast />
        <Toolbar class="mb-4">
          <template v-slot:start>
            <h5 class="m-4">Suscriptores</h5>
          </template>
          <template v-slot:end>
            <Button
              icon="pi pi-external-link"
              label="Exportar"
              @click="exportCSV($event)"
            />
          </template>
        </Toolbar>
        <DataTable
          ref="dt"
          :value="parametros"
          v-model:selection="selectedProducts"
          dataKey="id"
          :paginator="false"
          :rows="20"
          :filters="filters"
          :loading="loading"
          pagingType="numbers"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          responsiveLayout="scroll"
        >
          <Column field="email" header="Email" :sortable="false">
            <template #body="slotProps">
              {{ slotProps.data.email }}
            </template>
          </Column>
          <Column field="nombres" header="Nombres" :sortable="false">
            <template #body="slotProps">
              {{ slotProps.data.nombres }}
            </template>
          </Column>
          <Column field="celular" header="Celular" :sortable="false">
            <template #body="slotProps">
              {{ slotProps.data.celular }}
            </template>
          </Column>
          <Column field="ciudad" header="Ciudad" :sortable="false">
            <template #body="slotProps">
              {{ slotProps.data.ciudad }}
            </template>
          </Column>
          <Column
            field="created_at"
            header="Fecha de inscripción"
            :sortable="false"
          >
            <template #body="slotProps">
              {{ formatDate(slotProps.data.created_at) }}
              {{
                new Date(slotProps.data.created_at).toLocaleTimeString(
                  undefined,
                  { hour: "2-digit", minute: "2-digit" }
                )
              }}
            </template>
          </Column>
        </DataTable>
        <Paginator
          :rows="1"
          :totalRecords="totalRecord"
          @page="onPage($event)"
        ></Paginator>
      </div>
    </div>
  </div>
</template>
<script>
import { obtenerTodo } from "@/api/clienteHttpService.js";
// import { decrypt } from "../../../auth-guard";
export default {
  name: "Suscriptores",
  data: () => ({
    parametros: null,
    loading: false,
    totalRecords: 0,
  }),
  created() {
    this.obtenerSuscriptores();
  },
  methods: {
    async obtenerSuscriptores(pagina = 0) {
      this.loading = true;
      const respuesta = await obtenerTodo("/suscriptores?paginado=" + pagina);
      this.parametros = respuesta.suscriptores;
      this.totalRecord = respuesta.paginas;
      this.loading = false;
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      return new Intl.DateTimeFormat("es-CO").format(date);
    },
    async exportCSV() {
      this.loading = true;
      const token = localStorage.getItem("id");
      console.log(token);
      location.href = `${
        process.env.VUE_APP_BASE_URL_BACKEND
      }export/suscriptores?token=${
        token
      }`;
      this.loading = false;
      this.$toast.add({
        severity: "success",
        summary: "Exportación exitosa",
        detail: "Se ha exportado el archivo correctamente",
        life: 3000,
      });
    },
    async onPage(event) {
      await this.obtenerSuscriptores(event.page);
    },
  },
};
</script>
<style scoped></style>
