<template>
    <div class="card">
        <h5 class="mb-4 d-flex align-items-center">
            <i class="pi pi-info-circle" style="font-size: 1.2rem"></i> Información general
        </h5>
        <DataTable :value="parametros" :rows="5" :paginator="true" responsiveLayout="scroll" :loading="loading">
            <Column field="descripcion" header="Descripcion" :sortable="true" style="width:10%">
                <template #body="slotProps">
                    {{ slotProps.data.descripcion }}
                </template>
            </Column>
            <Column field="created_at" header="Fecha" :sortable="true" style="width:20%">
                <template #body="slotProps">
                    {{ slotProps.data.created_at }}
                </template>
            </Column>
        </DataTable>
    </div>
</template>
<script>
import { obtenerTodo } from '@/api/clienteHttpService.js';
export default {
    name: 'ValidacionesTable',
    data: () => ({
        parametros: null,
        loading: true,
    }),
    created() {
        this.obtenerIntegraciones();
    },
    methods: {
        async obtenerIntegraciones() {
            this.loading = true;
            const respuesta = await obtenerTodo("/productos/integraciones/obtenerValidacionesLog");
            this.parametros = respuesta;
            this.loading = false;
        },
        calculateClass(estado) {
            if (estado === 34 || estado === 35 || estado === 37) {
                return 'text-teal-500 pi-check-circle';
            } else if (estado === 33 || estado === 36 || estado == 38 || estado == 39 || estado == 40 || estado == 41 || estado == 42 || estado == 43) {
                return 'text-red-500 pi-times-circle';
            } else if (estado === 44 || estado === 45) {
                return 'text-yellow-500 pi-exclamation-circle';
            } else {
                return 'text-red-500 pi-times-circle'; // Clase por defecto o ninguna clase si no se cumple ninguna condición
            }
        }
    },

}
</script>
<style scoped></style>