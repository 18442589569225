<template>
  <div class="grid flex justify-content-center">
    <div class="col-12">
      <div class="card">
        <Toast />
        <Toolbar class="mb-4">
          <template v-slot:start>
            <h5 class="m-4">Usuarios</h5>
          </template>

          <template v-slot:end>
            <Button label="Crear usuario" icon="pi pi-plus" class="p-button-success mr-2" @click="nuevoUsuario" />
            <Button icon="pi pi-external-link" label="Exportar" @click="exportCSV($event)" />
          </template>
        </Toolbar>

        <DataTable ref="dt" :value="users" v-model:selection="selectedProducts" dataKey="id" :paginator="true"
          :rows="10" :filters="filters" :loading="loading"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 25]"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} users" responsiveLayout="scroll">
          <template #header>
            <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
              <span class="block mt-2 md:mt-0 p-input-icon-left">
                <i class="pi pi-search" />
                  <InputText v-model.trim="filters['global'].value" placeholder="Buscar..." />
              </span>
            </div>
          </template>

          <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>

          <Column field="name" header="Nombres" :sortable="true">
            <template #body="slotProps">
              {{ slotProps.data.name }}
            </template>
          </Column>
          <Column field="last_name" header="Apellidos" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Apellidos</span>
              {{ slotProps.data.last_name }}
            </template>
          </Column>
          <Column field="phone" header="Telefono" :sortable="true" headerStyle="width:14%; min-width:10rem;">
            <template #body="slotProps">
              <span class="p-column-title">Telefono</span>
              {{ slotProps.data.phone }}
            </template>
          </Column>
          <Column field="email" header="Correo" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Correo</span>
              {{ slotProps.data.email }}
            </template>
          </Column>
          <Column field="habilitado" header="Habilitada" :sortable="true">
            <template #body="slotProps">
              <em class="pi" :class="{
                'text-teal-500 pi-check-circle': slotProps.data.active,
                'text-red-500 pi-times-circle': !slotProps.data.active,
              }"></em>
            </template>
          </Column>

          <Column headerStyle="min-width:10rem;">
            <template #body="slotProps">
              <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2"
                @click="editarUsuario(slotProps.data)" />
            </template>
          </Column>
        </DataTable>

        <Dialog v-model:visible="userDialog" :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
          :style="{ width: '26vw' }" :header="formData.name ? formData.name : 'Crear usuario'" :modal="true"
          class="p-fluid">
          <Message v-if="showMessage" :sticky="false" :severity="messages.severity" :icon="messages.icon">{{
              messages.content
          }}</Message>
          <div class="grid p-fluid">
            <div class="col-12 md:col-12">
              <div class="field">
                <label for="nombre">Nombres*</label>
                <InputText id="nombre" placeholder="Nombres" name="name" v-model="formData.name" autocomplete="off"
                  :class="{ 'p-invalid': submitted && !formData.name }" />
                <small class="p-invalid" v-if="submitted && !formData.name">Nombre es requerido.</small>
              </div>
            </div>
          </div>
          <div class="grid p-fluid">
            <div class="col-12 md:col-12">
              <div class="field">
                <label for="apellido">Apellidos</label>
                <InputText id="apellido" placeholder="Apellidos" name="last_name" v-model="formData.last_name"
                  autocomplete="off" :class="{ 'p-invalid': submitted && !formData.last_name }" />
                <small class="p-invalid" v-if="submitted && !formData.last_name">Apellido es requerido.</small>
              </div>
            </div>
          </div>

          <div class="grid p-fluid">
            <div class="col-12 md:col-12">
              <div class="field">
                <label for="correo">Correo*</label>
                <InputText id="correo" name="email" placeholder="correo@unico.com.co" v-model="formData.email"
                  autocomplete="off" :class="{
                    'p-invalid': v$.formData.email.$invalid && submitted,
                  }" />
                <small class="p-invalid" v-if="submitted && !formData.email">Correo es requerido.</small>
              </div>
            </div>
          </div>

          <div class="grid p-fluid">
            <div class="col-12 md:col-12">
              <div class="field">
                <label for="telefono">Telefono*</label>
                <InputMask mask="999 999 9999" type="tel" placeholder="Telefono" id="telefono" name="phone"
                  v-model="formData.phone" autocomplete="off" :class="{ 'p-invalid': submitted && !formData.phone }" />
                <small class="p-invalid" v-if="submitted && !formData.phone">Telefono es requerido.</small>
              </div>
            </div>
          </div>

          <div class="grid p-fluid" v-if="exp">
            <div class="col-12 md:col-12">
              <div class="field">
                <label for="password">Contraseña*</label>
                <Password :class="{
                  'p-invalid': v$.formData.password.$invalid && submitted,
                }" placeholder="Contraseña" id="password" toggleMask v-model="formData.password">
                  <template #header>
                    <h4>Ingresa una contraseña</h4>
                  </template>
                  <template #footer="sp">
                    {{ sp.level }}
                    <Divider />
                    <p class="mt-2">Sugerencias</p>
                    <ul class="pl-2 ml-2 mt-0" style="line-height: 1.5">
                      <li>Al menos una minúscula</li>
                      <li>Al menos una mayúscula</li>
                      <li>Al menos un número</li>
                      <li>Mínimo 8 caracteres</li>
                    </ul>
                  </template>
                </Password>
              </div>
            </div>
          </div>

          <div class="grid p-fluid" v-if="exp">
            <div class="col-12 md:col-12">
              <div class="field">
                <label for="password_confirmation">Confirmar contraseña*</label>
                <Password :class="{
                  'p-invalid':
                    v$.formData.password_confirmation.$invalid && submitted,
                }" placeholder="Confirmar Contraseña" id="password_confirmation" toggleMask
                  v-model="formData.password_confirmation" autocomplete="off" :disabled="desabilitado">
                  <template #header>
                    <h4>Ingresa una contraseña</h4>
                  </template>
                  <template #footer="sp">
                    {{ sp.level }}
                    <Divider />
                    <p class="mt-2">Sugerencias</p>
                    <ul class="pl-2 ml-2 mt-0" style="line-height: 1.5">
                      <li>Al menos una minúscula</li>
                      <li>Al menos una mayúscula</li>
                      <li>Al menos un número</li>
                      <li>Mínimo 8 caracteres</li>
                    </ul>
                  </template>
                </Password>
              </div>
            </div>
          </div>

          <template #footer>
            <Button v-if="expBtnContra" label="Recuperar Contraseña" icon="pi pi-key" class="p-button-text"
              @click="reset(!v$.$invalid)"></Button>
            <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="hideDialog" />
            <Button label="Guardar" icon="pi pi-check" class="p-button-text" @click="guardarUsuario(!v$.$invalid)"
              :disabled="desabilitado" />
          </template>
        </Dialog>
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import {
  required,
  sameAs,
  minLength,
  helpers,
  requiredIf,
  email,
} from "@vuelidate/validators";
import { FilterMatchMode } from "primevue/api";
import { obtenerUsuarios } from "@/api/usuariosServices";
import { crearUsuarios } from "@/api/usuariosServices";
import { actualizarUsuarios } from "@/api/usuariosServices";
import { resetPassServices } from "@/api/loginServices";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      desabilitado: false,
      visibleSpinner: false,
      formData: {
        id: "",
        name: "",
        last_name: "",
        phone: "",
        email: "",
        password: "",
        password_confirmation: "",
      },
      exp: true,
      expBtnContra: true,
      loading: true,
      users: null,
      userDialog: false,
      selectedProducts: null,
      filters: {},
      submitted: false,
      showMessage: false,
      messages: {
        severity: "",
        content: "",
      },
      statuses: [
        { label: "HABILITADO", value: "habilitado" },
        { label: "INHABILITADO", value: "inhabilitado" },
      ],
    };
  },
  validations() {
    const strongRegex = helpers.regex(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/
    );
    return {
      formData: {
        name: { required },
        last_name: { required },
        phone: { required },
        email: { required, email },
        password: {
          minLength: minLength(6),
          strongRegex,
          required: requiredIf(this.exp),
        },
        password_confirmation: {
          required: requiredIf(this.exp),
          sameAsPassword: sameAs(this.formData.password),
        },
      },
    };
  },
  created() {
    this.obtenerUsuarios();
    this.initFilters();
  },
  methods: {
    async obtenerUsuarios() {
      const resp = await obtenerUsuarios();
      this.users = resp;
      this.loading = false;
    },
    nuevoUsuario() {
      this.exp = true;
      this.expBtnContra = false;
      this.formData = {};
      this.userDialog = true;
    },
    editarUsuario(formData) {
      this.formData = { ...formData };
      this.userDialog = true;
      this.expBtnContra = true;
      this.exp = false;
    },
    hideDialog() {
      this.userDialog = false;
      this.submitted = false;
    },
    async reset(isFormValid) {
      if (!isFormValid) {
        this.messages.severity = "error";
        this.messages.content = "Ingrese un correo valido.";
        this.showMessage = !this.showMessage;
        return;
      } else {
        this.visibleSpinner = true;
        this.inputDesabilitado = true;
        const resp = await resetPassServices(this.formData.email);
        if (resp == "OK") {
          this.messages.severity = "info";
          this.messages.content = "Mensaje enviado";
          this.messages.icon = "pi pi-send";
          this.showMessage = !this.showMessage;
          this.visibleSpinner = false;
          this.inputDesabilitado = false;
        }
      }
    },
    async guardarUsuario(isFormValid) {
      this.submitted = true;
      if (!isFormValid) {
        this.messages.severity = "error";
        this.messages.content = "Por favor verificar los campos del formulario";
        this.showMessage = !this.showMessage;
        return;
      } else {
        if (this.formData.id) {
          this.formData = await actualizarUsuarios(this.formData);
          this.$toast.add({
            severity: "success",
            summary: "Correcto",
            detail: "El usuario se ha editado correctamente",
            life: 3000,
          });
          this.userDialog = false;
          this.obtenerUsuarios();
        } else {
          if (this.users.find((user) => user.email === this.formData.email)) {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: "El email ya existe",
              life: 3000,
            });
            return;
          } else {
            await crearUsuarios(this.formData);
            this.users.push(this.formData);
            this.$toast.add({
              severity: "success",
              summary: "Correcto",
              detail: "El usuario se ha creado correctamente",
              life: 3000,
            });
          }
        }
        this.userDialog = false;
        this.formData = {};
        this.obtenerUsuarios();
      }
    },

    actualizarUsuarios(id) {
      let index = -1;
      for (let i = 0; i < this.users.length; i++) {
        if (this.users[i]._id === id) {
          index = i;
          break;
        }
      }
      return index;
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
  },
};
</script>
