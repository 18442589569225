<script>
    import { obtenerTodo } from "@/api/clienteHttpService";
    export default {
        props: ['selectedItems', 'itemsInDB'],
        data: () => ({
            productoSeleccionado: [],
            productos: null,
            db_items: [],
            loading: true
        }),
        async created() {
            try {
                const resp = await obtenerTodo("/productos/backoffice");
                this.productos = resp;
                this.loading = false;
                this.findItemsOfDB(this.itemsInDB);
            } catch (error) {
                console.log(error);
            }
        },
        methods: {
            findItemsOfDB(id){
                const regex = /(\d+)/g;
                this.db_items = id.match(regex).map((id_data) => {
                    return this.productos.filter((e) => e.id == id_data);
                });
                this.productoSeleccionado = this.db_items.map((e) => {
                    return e[0];
                });
                this.loading = false;
            },
            handleChange(event) {
                this.selectedItems(event.value);
            }
        }
    }
    </script>
    <template>
        <MultiSelect v-model="productoSeleccionado" :options="productos"
            optionLabel="nombre" @change="handleChange" placeholder="Seleccione..." :loading="loading"/>
    </template>