<script>
import { obtenerTodo } from "@/api/clienteHttpService";
export default {
    props: ['selectedItems', 'itemsInDB'],
    data: () => ({
        tiempoEntregaSeleccionado: [],
        tiemposEntrega: null,
        db_items: [],
        loading: true
    }),
    async created() {
        try {
            const resp = await obtenerTodo("/config/tiempo_entrega");
            this.tiemposEntrega = resp;
            this.loading = false;
            this.findItemsOfDB(this.itemsInDB);
        } catch (error) {
            console.log(error);
        }
    },
    methods: {
        findItemsOfDB(id){
            this.tiempoEntregaSeleccionado = this.tiemposEntrega.filter((e) => e.id == id);
            this.loading = false;
        },
        handleChange(event) {
            this.selectedItems(event.value);
        }
    }
}
</script>
<template>
    <MultiSelect v-model="tiempoEntregaSeleccionado" :options="tiemposEntrega"
        optionLabel="nombre" @change="handleChange" placeholder="Seleccione..." :loading="loading"
        :selectionLimit=1 />
</template>