<script>
import { obtenerTodo } from "@/api/clienteHttpService";
export default {
    props: ['selectedItems', 'itemsInDB', 'limit'],
    data: () => ({
        tiendaSeleccionada: [],
        tiendas: null,
        db_items: [],
        limite: null,
        loading: true
    }),
    async created() {
        try {
            this.limite = this.limit;
            const resp = await obtenerTodo("/tiendas");
            this.tiendas = resp;
            this.loading = false;
            this.findItemsOfDB(this.itemsInDB);
        } catch (error) {
            console.log(error);
        }
    },
    methods: {
        findItemsOfDB(id) {
            const regex = /(\d+)/g;
            this.db_items = id.match(regex).map((id_data) => {
                return this.tiendas.filter((e) => e.id == id_data);
            });
            this.tiendaSeleccionada = this.db_items.map((e) => {
                return e[0];
            });
            this.loading = false;
        },
        handleChange(event) {
            this.selectedItems(event.value);
        }
    }
}
</script>
<template>
    <MultiSelect v-model="tiendaSeleccionada" :options="tiendas" optionLabel="nombre" @change="handleChange"
        placeholder="Seleccione..." :loading="loading" display="chip" :selectionLimit=limite />
</template>