<script>
import { obtenerTodo } from "@/api/clienteHttpService";
export default {
    props: ['selectedItems', 'itemsInDB'],
    data: () => ({
        tipoIntegracionSeleccionado: [],
        tipoIntegraciones: null,
        db_items: [],
        loading: true
    }),
    async created() {
        try {
            const resp = await obtenerTodo("/config/tipo_integracion");
            this.tipoIntegraciones = resp;
            this.loading = false;
            this.findItemsOfDB(this.itemsInDB);
        } catch (error) {
            console.log(error);
        }
    },
    methods: {
        findItemsOfDB(id){
            this.tipoIntegracionSeleccionado = this.tipoIntegraciones.filter((e) => e.id == id);
            this.loading = false;
        },
        handleChange(event) {
            this.selectedItems(event.value);
        }
    }
}
</script>
<template>
    <MultiSelect v-model="tipoIntegracionSeleccionado" :options="tipoIntegraciones"
        optionLabel="nombre" @change="handleChange" placeholder="Seleccione..." :loading="loading"
        :selectionLimit=1 />
</template>