<template>
  <div :class="containerClass" @click="onWrapperClick">
    <BarraNavegacion @menu-toggle="onMenuToggle"></BarraNavegacion>
    <div class="layout-sidebar" @click="onSidebarClick">
      <MenuLateral
        :model="menu"
        @menuitem-click="onMenuItemClick"
      ></MenuLateral>
    </div>
    <div class="layout-main-container" @menu-toggle="onMenuToggle">
      <div class="layout-main">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import BarraNavegacion from "../components/BarraNavegacion.vue";
import MenuLateral from "../components/MenuLateral.vue";
import { userServices } from "../../../api/loginServices";

export default {
  data() {
    return {
      name: "",

      layoutMode: "static",
      staticMenuInactive: false,
      overlayMenuActive: false,
      mobileMenuActive: false,
      menu1: [
        {
          label: "Inicio",
          items: [
            {
              label: "Dashboard",
              icon: "pi pi-fw pi-home",
              to: { name: "configuraciones" },
            },
          ],
        },
        {
          label: "Ventas",
          items: [
            {
              label: "Pedidos",
              icon: "pi pi-shopping-cart",
              to: { name: "pedidos" },
            },
            {
              label: "Clientes",
              icon: "pi pi-users",
              to: { name: "clientes" },
            },
          ],
        },
        {
          label: "Configuración",
          items: [
            {
              label: "Tiendas",
              icon: "pi pi-shopping-bag",
              to: { name: "tiendas" },
            },
            {
              label: "Transportadoras",
              icon: "pi pi-fw pi-car",
              items: [
                {
                  label: "Transportadoras",
                  icon: "pi pi-fw pi-car",
                  to: { name: "transportadoras" },
                },
                {
                  label: "Facturación de Transporte",
                  icon: "pi pi-fw pi-file",
                  to: { name: "facturacion-transporte" },
                },
              ],
            },

            {
              label: "Categorias",
              icon: "pi pi-sitemap",
              to: { name: "categorias" },
            },
            {
              label: "Usuarios",
              icon: "pi pi-fw pi-users",
              to: { name: "usuarios" },
            },
            {
              label: "Politicas cliente",
              icon: "pi pi-briefcase",
              to: { name: "politicas" },
            },
            {
              label: "Productos",
              icon: "pi pi-shopping-bag",
              to: { name: "Productos" },
            },
            {
              label: "Integraciones",
              icon: "pi pi-database",
              to: { name: "Integraciones" },
            },
            {
              label: "Cupones y campañas",
              icon: "pi pi-fw pi-ticket",
              to: { name: "Cupones" },
            },
            {
              label: "Banner superior",
              icon: "pi pi-align-left",
              to: { name: "BannerSuperior" },
            },
            {
              label: "Banner principal",
              icon: "pi pi-image",
              to: { name: "Secciones" },
            },
            {
              label: "Secciones banners",
              icon: "pi pi-images",
              to: { name: "Secciones" },
            },
            {
              label: "Suscriptores",
              icon: "pi pi-users",
              to: { name: "Suscriptores" },
            },
          ],
        },
      ],
      menu2: [
        {
          label: "TIENDA",
          items: [
            {
              label: "Pedidos",
              icon: "pi pi-fw pi-home",
              to: { name: "pedidostienda" },
            },
          ],
        },
      ],
      menu: [],
    };
  },
  created() {
    this.menu;
    this.obtenerUsuarioMenu();
  },
  methods: {
    async obtenerUsuarioMenu() {
      const id = localStorage.getItem("id_rol");
      if (id == 1) {
        this.menu = this.menu1;
      }
      if (id == 5) {
        this.menu = this.menu2;
      }
    },
    onWrapperClick() {
      if (!this.menuClick) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }

      this.menuClick = false;
    },
    onMenuToggle() {
      this.menuClick = true;

      if (this.isDesktop()) {
        if (this.layoutMode === "overlay") {
          if (this.mobileMenuActive === true) {
            this.overlayMenuActive = true;
          }

          this.overlayMenuActive = !this.overlayMenuActive;
          this.mobileMenuActive = false;
        } else if (this.layoutMode === "static") {
          this.staticMenuInactive = !this.staticMenuInactive;
        }
      } else {
        this.mobileMenuActive = !this.mobileMenuActive;
      }

      event.preventDefault();
    },
    onSidebarClick() {
      this.menuClick = true;
    },
    onMenuItemClick(event) {
      if (event.item && !event.item.items) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }
    },
    onLayoutChange(layoutMode) {
      this.layoutMode = layoutMode;
    },
    addClass(element, className) {
      if (element.classList) element.classList.add(className);
      else element.className += " " + className;
    },
    removeClass(element, className) {
      if (element.classList) element.classList.remove(className);
      else
        element.className = element.className.replace(
          new RegExp(
            "(^|\\b)" + className.split(" ").join("|") + "(\\b|$)",
            "gi"
          ),
          " "
        );
    },
    isDesktop() {
      return window.innerWidth >= 992;
    },
    isSidebarVisible() {
      if (this.isDesktop()) {
        if (this.layoutMode === "static") return !this.staticMenuInactive;
        else if (this.layoutMode === "overlay") return this.overlayMenuActive;
      }

      return true;
    },
  },
  computed: {
    containerClass() {
      return [
        "layout-wrapper",
        {
          "layout-overlay": this.layoutMode === "overlay",
          "layout-static": this.layoutMode === "static",
          "layout-static-sidebar-inactive":
            this.staticMenuInactive && this.layoutMode === "static",
          "layout-overlay-sidebar-active":
            this.overlayMenuActive && this.layoutMode === "overlay",
          "layout-mobile-sidebar-active": this.mobileMenuActive,
          "p-input-filled": this.$primevue.config.inputStyle === "filled",
          "p-ripple-disabled": this.$primevue.config.ripple === false,
        },
      ];
    },
    logo() {
      return this.$appState.darkTheme
        ? "images/logo-white.svg"
        : "images/logo.svg";
    },
  },
  beforeUpdate() {
    if (this.mobileMenuActive)
      this.addClass(document.body, "body-overflow-hidden");
    else this.removeClass(document.body, "body-overflow-hidden");
  },
  components: {
    BarraNavegacion,
    MenuLateral,
  },

  mounted() {
    userServices();
  },
};
</script>

<style>
@import "../../../App.scss";
</style>
