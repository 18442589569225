import { createRouter, createWebHistory } from "vue-router";
import Page404 from "../src/views/Page404.vue";
import RecuperarContrasena from "../src/views/RecuperarContrasena.vue";
import Login from "../src/views/Login.vue";
import Dashboard from "./modulos/dashboard/layout/Dashboard.vue";
import CambiarContrasena from "./views/CambiarContrasena.vue";
import { guardsRoute, guardsLogin, checkRole } from "./auth-guard";
import Transportadoras from "./modulos/transportadoras/layout/Transportadoras.vue";
import Configuraciones from "./modulos/dashboard/layout/Configuraciones.vue";
import Politicas from "./modulos/politicas/layout/Politicas.vue";
import Tiendas from "./modulos/tiendas/layout/Tiendas.vue";
import CostoTransportadora from "./modulos/transportadoras/layout/CostoTransportadora.vue";
import Usuarios from "./modulos/usuarios/layout/Usuarios.vue";
import Categorias from "./modulos/categorias/layout/Categorias.vue";
import Pedidos from "./modulos/pedidos/layout/Pedidos.vue";
import Clientes from "./modulos/clientes/layout/Clientes.vue";
import Productos from "./modulos/productos/components/layout/Productos.vue";
import Cupones from "./modulos/cupones/layout/Cupones.vue";
import Pedidostienda from "./modulos/pedidostienda/layout/Pedidostienda.vue";
import BannerSuperior from "./modulos/banners/layout/BannerSuperior.vue";
import Secciones from "./modulos/secciones/layout/Secciones.vue";
import Integraciones from "./modulos/integraciones/layout/Integraciones.vue";
import SeccionesHome from "./modulos/seccionesBannersHome/seccionesHome.vue";
import bannerPrincipal from "./modulos/seccionesBannersHome/bannerPrincipal.vue";
import seccionesBanners from "./modulos/seccionesBannersHome/seccionesBanners.vue";
import Suscriptores from "./modulos/suscriptores/layout/Suscriptores.vue";
import Facturacion from "./modulos/facturacion-transporte/layout/facturacion.vue";

const routes = [
  {
    path: "/",
    name: "login",
    component: Login,
    beforeEnter: [guardsLogin],
  },
  {
    path: "/",
    name: "dashboard",
    component: Dashboard,
    beforeEnter: [guardsRoute],
    children: [
      {
        path: "usuarios",
        component: Usuarios,
        beforeEnter: [guardsRoute, checkRole],
        meta: { admin: true, usuario: false, tienda: false },
        name: "usuarios",
      },
      {
        path: "tiendas",
        component: Tiendas,
        beforeEnter: [guardsRoute, checkRole],
        meta: { admin: true, usuario: true, tienda: false },
        name: "tiendas",
      },
      {
        path: "categorias",
        component: Categorias,
        beforeEnter: [guardsRoute, checkRole],
        meta: { admin: true, usuario: false, tienda: false },
        name: "categorias",
      },
      {
        path: "transportadoras",
        component: Transportadoras,
        beforeEnter: [guardsRoute, checkRole],
        meta: { admin: true, usuario: false, tienda: false },
        name: "transportadoras",
      },
      {
        path: "politicas",
        component: Politicas,
        beforeEnter: [guardsRoute, checkRole],
        meta: { admin: true, usuario: false, tienda: false },
        name: "politicas",
      },
      {
        path: "CostoTransportadora/:id",
        name: "CostoTransportadora",
        component: CostoTransportadora,
        beforeEnter: [guardsRoute, checkRole],
        meta: { admin: true, usuario: false, tienda: false },
        props: true,
      },
      {
        path: "pedidos",
        component: Pedidos,
        beforeEnter: [guardsRoute, checkRole],
        meta: { admin: true, usuario: true, tienda: false },
        name: "pedidos",
      },
      {
        path: "clientes",
        component: Clientes,
        beforeEnter: [guardsRoute, checkRole],
        meta: { admin: true, usuario: true, tienda: false },
        name: "clientes",
      },
      {
        path: "Productos",
        component: Productos,
        beforeEnter: [guardsRoute, checkRole],
        meta: { admin: true, usuario: false, tienda: false },
        name: "Productos",
      },
      {
        path: "Integraciones",
        component: Integraciones,
        beforeEnter: [guardsRoute, checkRole],
        meta: { admin: true, usuario: false, tienda: false },
        name: "Integraciones",
      },
      {
        path: "cupones",
        component: Cupones,
        beforeEnter: [guardsRoute, checkRole],
        meta: { admin: true, usuario: false, tienda: false },
        name: "Cupones",
      },
      {
        path: "pedidostienda",
        component: Pedidostienda,
        beforeEnter: [guardsRoute, checkRole],
        meta: { admin: true, usuario: false, tienda: true },
        name: "pedidostienda",
      },
      {
        path: "Configuraciones",
        component: Configuraciones,
        beforeEnter: [guardsRoute, checkRole],
        meta: { admin: true, usuario: false, tienda: false },
        name: "configuraciones",
      },
      {
        path: "banner-superior",
        component: BannerSuperior,
        beforeEnter: [guardsRoute, checkRole],
        meta: { admin: true, usuario: false, tienda: false },
        name: "BannerSuperior",
      },
      {
        path: "Secciones",
        component: Secciones,
        beforeEnter: [guardsRoute, checkRole],
        meta: { admin: true, usuario: false, tienda: false },
        name: "Secciones",
      },
      {
        path: "secciones-home",
        component: SeccionesHome,
        name: "seccion-banners-home",
        beforeEnter: [guardsRoute, checkRole],
        meta: { admin: true, usuario: false, tienda: false },
        children: [
          {
            path: "banner-principal",
            component: bannerPrincipal,
            name: "banner-principal",
          },

          {
            path: "secciones-banners",
            component: seccionesBanners,
            name: "secciones-banners",
          },
          {
            path: "Suscriptores",
            component: Suscriptores,
            beforeEnter: [guardsRoute, checkRole],
            meta: { admin: true, usuario: false, tienda: false },
            name: "Suscriptores",
          },
          {
            path: "/",
            redirect: { name: "banner-principal" },
          },
        ],
      },
      {
        path: "facturacion-transporte",
        component: Facturacion,
        beforeEnter: [guardsRoute, checkRole],
        meta: { admin: true, usuario: false, tienda: false },
        name: "facturacion-transporte",
      },
    ],
  },

  {
    path: "/cambiar_contrasena",
    component: CambiarContrasena,
    name: "cambiar_contrasena",
  },
  {
    path: "/recuperar_contrasena",
    component: RecuperarContrasena,
  },
  {
    path: "/:pathMatch(.*)*",
    component: Page404,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
