<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <Toast />
        <Toolbar class="mb-4">
          <template v-slot:start>
            <h5 class="m-4">Facturación transporte</h5>
          </template>
          <template v-slot:end>
            <SplitButton :model="opciones" class="bg-primary border-round">
              <span class="mx-2 flex align-items-center font-bold"
                ><i class="pi pi-check" style="margin-right: 1rem"></i
                >Menu</span
              >
            </SplitButton>
          </template>
        </Toolbar>
        <div class="seccion-fechas">
          <div class="grupo-input">
            <label for="fechaInicial">Desde:</label>
            <InputText
              id="fechaInicial"
              v-model="fechaInicial"
              type="date"
              placeholder="Normal"
            />
          </div>
          <div class="grupo-input">
            <label for="fechaFinal">Hasta:</label>
            <InputText
              id="fechaFinal"
              v-model="fechaFinal"
              type="date"
              placeholder="Normal"
            />
          </div>
          <div class="grupo-button" v-if="opcionesAccion == 'ejecutar'">
            <Button
              icon="pi pi-play"
              label="Ejecutar"
              severity="success"
              :disabled="fechaInicial == null || fechaFinal == null"
              @click="ejecutarFacturacion"
              :loading="estaCargando"
            />
          </div>
          <div class="grupo-button" v-else>
            <Button
              icon="pi pi-book"
              label="Buscar"
              :disabled="fechaInicial == null || fechaFinal == null"
              @click="consultarDatosfacturados"
              :loading="estaCargando"
            />

            <Button
              label="Descargar"
              icon="pi pi-download"
              @click="DescargarExcel"
              :disabled="fechaInicial == null || fechaFinal == null"
              :loading="estaCargando"
              severity="success"
            />
          </div>
        </div>
        <div class="contenedor-tabla">
          <DataTable
            v-model:filters="filters"
            :value="datosFacturacion"
            tableStyle="min-width: 50rem"
          >
            <template #header>
              <div class="flex justify-content-end">
                <span class="p-input-icon-left">
                  <i class="pi pi-search" />
                  <InputText
                    v-model="filters['global'].value"
                    placeholder="Buscar"
                  />
                </span>
              </div>
            </template>

            <Column
              v-for="col of columnas"
              :key="col.field"
              :field="col.field"
              :header="col.header"
            ></Column>
          </DataTable>
        </div>
      </div>
    </div>
    <Dialog
      v-model:visible="verModal"
      modal
      header="Cargar archivo"
      :style="{ width: '50rem' }"
      :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
    >
      <form class="cargar-archivo">
        <input
          type="file"
          accept=".xlsx"
          id="file"
          ref="file"
          @change="precargaArchivo($event)"
        />

        <Button
          label="Cargar"
          @click="cargarDatosExcel"
          :disabled="botonEstaHabilitado"
          :loading="estaCargando"
        />
      </form>
    </Dialog>
  </div>
</template>

<script setup>
import facturacion from "../../../composables/facturacion-transporte/useFacturacionTransporte.js";

const {
  verModal,
  precargaArchivo,
  cargarDatosExcel,
  estaCargando,
  botonEstaHabilitado,
  opciones,
  fechaInicial,
  fechaFinal,
  opcionesAccion,
  columnas,
  datosFacturacion,
  filters,
  DescargarExcel,
  consultarDatosfacturados,
  ejecutarFacturacion,
} = facturacion();
</script>

<style scoped>
@import "../../../assets//styles/facturacion-transporte/facturacion.css";
</style>
