import { ref } from "vue";
import instance from "../../api/services";
import { useToast } from "primevue/usetoast";
import { FilterMatchMode } from "primevue/api";

const useFacturacionTransporte = () => {
  const verModal = ref(false);

  const archivoExcel = ref("");
  const toast = useToast();
  const estaCargando = ref(false);
  const botonEstaHabilitado = ref(true);
  const opcionesAccion = ref("ejecutar");

  const columnas = ref([
    { field: "numero_identificacion", header: "Identificacion" },
    { field: "nombre", header: "Nombre" },
    { field: "apellido", header: "Apellido" },
    { field: "referencia_pedido", header: "#Pedido" },
    { field: "id_remesa_tcc", header: "#Guia" },
    { field: "valor_facturado", header: "Valor" },
    { field: "tipo_documento", header: "Tipo documento" },
    { field: "id_solutions", header: "Id Solutions" },
    { field: "numero_comprobante_solutions", header: "Comprobante Solutions" },
    { field: "fecha_facturacion", header: "Fecha facturacion" },
  ]);

  const filters = ref({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const datosFacturacion = ref([]);

  const fechaInicial = ref(null);
  const fechaFinal = ref(null);

  const opciones = [
    {
      label: "Cargar datos TCC",
      icon: "pi pi-file-excel",
      command: () => {
        verModal.value = true;
      },
    },
    {
      label: "Ejecutar facturación",
      icon: "pi pi-play",
      command: () => {
        opcionesAccion.value = "ejecutar";
      },
    },

    {
      label: "Historial datos cargados",
      icon: "pi pi-table",
      command: () => {
        opcionesAccion.value = "buscar";
      },
    },
  ];

  const precargaArchivo = (e) => {
    archivoExcel.value = e.target.files[0];
    botonEstaHabilitado.value = false;
  };

  const cargaArchivo = () => {
    let data = new FormData();
    data.append("file", archivoExcel.value);
  };

  const cargarDatosExcel = async () => {
    estaCargando.value = true;
    let data = new FormData();
    data.append("file", archivoExcel.value);

    try {
      const resp = await instance.post("facturaciontrans/cargar-datos", data, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      estaCargando.value = false;
      mostrarMensajeConfirmacion("success", "Correcto", resp.data.message);
      verModal.value = false;
    } catch (error) {
      estaCargando.value = false;
      verModal.value = false;
      mostrarMensajeConfirmacion(
        "error",
        "Error en la carga",
        error.response.data.message
      );
    }
  };

  const ejecutarFacturacion = async () => {
    if (Date.parse(fechaInicial.value) < Date.parse(fechaFinal.value)) {
      estaCargando.value = true;
      let data = new FormData();
      data.append("fechaInicial", fechaInicial.value);
      data.append("fechaFinal", fechaFinal.value);
      try {
        const resp = await instance.post(
          "facturaciontrans/ejecutar-facturacion",
          data,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );
        estaCargando.value = false;

        console.log(resp);
        // mostrarMensajeConfirmacion("success", "Correcto", resp.data.message);
      } catch (error) {
        estaCargando.value = false;
        mostrarMensajeConfirmacion(
          "error",
          "Error en la carga",
          error.response.data.message
        );
      }
    } else {
      mostrarMensajeConfirmacion(
        "error",
        "Error en la carga",
        'La fecha "Desde" no puede ser mayor a la fecha "Hasta"'
      );
    }
  };

  const consultarDatosfacturados = async () => {
    if (Date.parse(fechaInicial.value) < Date.parse(fechaFinal.value)) {
      estaCargando.value = true;
      let data = new FormData();
      data.append("fechaInicial", fechaInicial.value);
      data.append("fechaFinal", fechaFinal.value);
      try {
        const resp = await instance.post(
          "facturaciontrans/consultar-facturacion",
          data,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );
        estaCargando.value = false;
        datosFacturacion.value = resp.data.data;
        //  mostrarMensajeConfirmacion("success", "Correcto", resp.data.message);
      } catch (error) {
        estaCargando.value = false;
        mostrarMensajeConfirmacion(
          "error",
          "Error en la carga",
          error.response.data.message
        );
      }
    } else {
      mostrarMensajeConfirmacion(
        "error",
        "Error en la carga",
        'La fecha "Desde" no puede ser mayor a la fecha "Hasta"'
      );
    }
  };

  const DescargarExcel = async () => {
    location.href = `${process.env.VUE_APP_BASE_URL_BACKEND}descargar-excel-facturacion?fechaInicial=${fechaInicial.value}&fechaFinal=${fechaFinal.value}`;

    // if (Date.parse(fechaInicial.value) < Date.parse(fechaFinal.value)) {
    //   estaCargando.value = true;
    //   let data = new FormData();
    //   data.append("fechaInicial", fechaInicial.value);
    //   data.append("fechaFinal", fechaFinal.value);
    //   try {
    //     const resp = await instance.post(
    //       "facturaciontrans/descargar-excel",
    //       data,
    //       {
    //         headers: {
    //           Authorization: "Bearer " + localStorage.getItem("token"),
    //         },
    //         responseType: "blob",
    //       }
    //     );
    //     location.href =
    //     "download_report
    //     estaCargando.value = false;

    //    // mostrarMensajeConfirmacion("success", "Correcto", resp.data.data);
    //   } catch (error) {
    //     estaCargando.value = false;
    //     mostrarMensajeConfirmacion(
    //       "error",
    //       "Error en la carga",
    //       "No fue posible descargar el archivo"
    //     );
    //   }
    // } else {
    //   mostrarMensajeConfirmacion(
    //     "error",
    //     "Error en la carga",
    //     'La fecha "Desde" no puede ser mayor a la fecha "Hasta"'
    //   );
    // }
  };

  const mostrarMensajeConfirmacion = (tipo, titulo, mensajeRespuesta) => {
    toast.add({
      severity: tipo,
      summary: titulo,
      detail: mensajeRespuesta,
      life: 4000,
    });
  };

  return {
    verModal,
    archivoExcel,
    estaCargando,
    botonEstaHabilitado,
    opciones,
    fechaInicial,
    fechaFinal,
    opcionesAccion,
    columnas,
    datosFacturacion,
    filters,
    DescargarExcel,
    consultarDatosfacturados,
    cargaArchivo,
    precargaArchivo,
    cargarDatosExcel,
    ejecutarFacturacion,
  };
};

export default useFacturacionTransporte;
