<template>
    <div class="stepper">
        <div class="top">
            <div v-for="(item, index) in options.headers" :key="item.title" :class="{
                'step-header': true, 'active': index <= currentPosition, 'error': item.error,
                'start': index === 0, 'end': index === options.headers.length
            }">
                <div class="header-indicator">
                    <div class="step-header-line" v-if="index > 0"></div>
                    <div class="step-header-content" @click="slideTo(index)">
                        {{ index+ 1}}
                    </div>
                </div>
                <div class="title" :class="{ 'title': true, }">
                    {{ item.title }}
                </div>
            </div>
        </div>

        <div style="width:100%">
            <transition-group :name="transitionType" class="body" mode="out-in">
                <div v-for="(item, index) in options.headers" :key="index" v-show="currentPosition === index"
                    :class="{ 'steps-item': true }">
                    <slot :name="item.stepName"></slot>
                </div>
            </transition-group>
        </div>
        <div class="foot">
            <button v-if="currentPosition > 0" class="prev-button" type="button" @click="prev()">{{
                options.prevText ?
                    options.prevText : 'Prev'
            }}</button>
            <button v-if="currentPosition < options.headers.length - 1" class="next-button" type="button"
                @click="next()">{{ options.nextText ? options.nextText : 'Next' }}</button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Wizard',
    props: ['options'],
    data() {
        return {
            currentPosition: 0,
            transitionType: 'slide'
        }
    },
    methods: {
        next() {
            if (this.currentPosition < this.options.headers.length - 1) {
                this.transitionType = 'stepper-slide-1'
                this.currentPosition++
                this.$emit('next',1)
            }
        },
        prev() {
            if (this.currentPosition > 0) {
                this.transitionType = 'stepper-slide-2'
                this.currentPosition--
                this.$emit('prev',-1)
            }
        },
        slideTo(index) {
            if (this.currentPosition === index) return
            if (this.currentPosition > index) {
                this.transitionType = 'stepper-slide-2'
            } else {
                this.transitionType = 'stepper-slide-1'
            }
            this.currentPosition = index;
        }
    }
}
</script>
<style scoped>
.top {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    padding: 10px;
    width: 100%;
    justify-content: center;
}

.step-header {
    display: flex;
    flex-direction: column;
}

.header-indicator {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.step-header.start>.header-indicator {
    align-self: flex-end;
}

.step-header-line {
    display: inline-flex;
    width: 100px;
    height: 1px;
    background-color: #eee;
}

.step-header-content {
    display: inline-flex;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #ddd;
    color: #999;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.step-header.active .step-header-content,
.step-header.active .step-header-line {
    background-color: #d0e1fd;
    color: #1D4ED8;
}

.step-header.error .step-header-content {
    background-color: #dc3545;
}

.title {
    display: inline-flex;
    align-self: flex-end;
    text-transform: capitalize;
    width: 100px;
    position: relative;
    right: -10%;
    justify-content: center;
}

.step-header.start>.title {
    right: -35%;
}

.steps-item {
    min-width: 100%;
}

.body {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    min-width: 100%;
    flex-shrink: 0;
}

.foot {
    display: flex;
    width: 100%;
    flex-direction: row;
}

.next-button {
    margin-left: auto;
}

button {
    color: #ffffff;
    background: #6366F1;
    border: 1px solid #6366F1;
    padding: 0.75rem 1.25rem;
    font-size: 1rem;
    font-weight: 700;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 6px;
}

button:enabled:hover {
    background: #4F46E5;
    color: #ffffff;
    border-color: #4F46E5;
}

button:enabled:active {
    background: #4338CA;
    color: #ffffff;
    border-color: #4338CA;
}

.stepper {
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.stepper-slide-1-enter-active {
    animation: slide-in .3s;
}

.stepper-slide-1-leave-active {
    animation: slide-out .3s;
}

@keyframes slide-in {
    0% {
        transform: translateX(100%);
    }
    
    100% {
        transform: translateX(0);
    }
}

@keyframes slide-out {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-100%);
    }
}

.stepper-slide-2-enter-active {
    animation: slide2-in .3s;
}

.stepper-slide-2-leave-active {
    animation: slide2-out .3s;
}

@keyframes slide2-in {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes slide2-out {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(100%);
    }
}
</style>