<template>
  <div class="grid">
    <div class="col-12">
      <TabView>
        <TabPanel header="Cupones">
          <Toolbar class="mb-4">
            <template v-slot:start>
              <h5 class="m-4">Cupones</h5>
            </template>
            <template v-slot:end>
              <Button id="crearCupon" label="Crear cupon" icon="pi pi-plus" class="p-button-success mr-2"
                @click="nuevoCupon()" />
              <Button icon="pi pi-external-link" label="Exportar" />
            </template>
          </Toolbar>
          <DataTable ref="dt" :value="obtenerCupones ? obtenerCupones : []" stripedRows dataKey="id" :loading="loading"
            responsiveLayout="scroll">
            <!-- <Column selectionMode="multiple"></Column> -->
            <Column field="nombre" header="Cupon" :sortable="true" class="cursor-pointer">
              <template #body="slotProps">
                {{ slotProps.data.codigo }}
              </template>
            </Column>
            <Column field="prioridad" header="Descripción" :sortable="true" class="cursor-pointer">
              <template #body="slotProps">
                {{ slotProps.data.descripcion }}
              </template>
            </Column>
            <Column field="codigo" header="Valida desde" :sortable="true" class="cursor-pointer">
              <template #body="slotProps">
                {{ slotProps.data.vigencia_desde }}
              </template>
            </Column>
            <Column field="cantidad" header="Valida hasta" :sortable="true" class="cursor-pointer">
              <template #body="slotProps">
                {{ slotProps.data.vigencia_hasta }}
              </template>
            </Column>
            <Column field="habilitado" header="Habilitada" :sortable="true" class="cursor-pointer">
              <template #body="slotProps">
                <em class="pi" :class="{
                  'text-teal-500 pi-check-circle': slotProps.data.habilitado,
                  'text-red-500 pi-times-circle': !slotProps.data.habilitado,
                }"></em>
              </template>
            </Column>
            <Column header="Editar">
              <template #body="slotProps">
                <em class="pi pi-pencil mr-2 text-black-500 text-xl cursor-pointer"
                  v-tooltip.left="'Detalle de la campaña'" @click="editarCupon(slotProps.data)"></em>
              </template>
            </Column>
          </DataTable>

        </TabPanel>
        <TabPanel header="Campañas">
          <Toast />
          <Toolbar class="mb-4">
            <template v-slot:start>
              <h5 class="m-4">Campañas</h5>
            </template>
            <template v-slot:end>
              <Button id="crearCampania" label="Crear campaña" icon="pi pi-plus" class="p-button-success mr-2"
                @click="abrirDialog()" />
              <!-- <Button icon="pi pi-external-link" label="Exportar" /> -->
            </template>
          </Toolbar>
          <DataTable ref="dt" :value="campanias ? campanias : []" stripedRows dataKey="id" :loading="loading"
            responsiveLayout="scroll">
            <!-- <Column selectionMode="multiple"></Column> -->
            <Column field="nombre" header="Campaña" :sortable="true" class="cursor-pointer">
              <template #body="slotProps">
                {{ slotProps.data.nombre }}
              </template>
            </Column>
            <Column field="prioridad" header="Descripción" :sortable="true" class="cursor-pointer">
              <template #body="slotProps">
                {{ slotProps.data.descripcion }}
              </template>
            </Column>
            <Column field="codigo" header="Valida desde" :sortable="true" class="cursor-pointer">
              <template #body="slotProps">
                {{ slotProps.data.vigencia_desde }}
              </template>
            </Column>
            <Column field="cantidad" header="Valida hasta" :sortable="true" class="cursor-pointer">
              <template #body="slotProps">
                {{ slotProps.data.vigencia_hasta }}
              </template>
            </Column>
            <Column field="habilitado" header="Habilitada" :sortable="true" class="cursor-pointer">
              <template #body="slotProps">
                <em class="pi" :class="{
                  'text-teal-500 pi-check-circle': slotProps.data.habilitado,
                  'text-red-500 pi-times-circle': !slotProps.data.habilitado,
                }"></em>
              </template>
            </Column>
            <Column header="Editar">
              <template #body="slotProps">
                <em class="pi pi-pencil mr-2 text-black-500 text-xl cursor-pointer"
                  v-tooltip.left="'Detalle de la campaña'" @click="editarCampania(slotProps.data)"></em>
              </template>
            </Column>
          </DataTable>
        </TabPanel>
        <TabPanel header="Tipos de Descuentos">
          <Toast />
          <Toolbar class="mb-4">
            <template v-slot:start>
              <h5 class="m-4">Tipos de Descuentos</h5>
            </template>
            <template v-slot:end>
              <Button id="crearCampania" label="Crear tipo descuento" icon="pi pi-plus" class="p-button-success mr-2"
                @click="nuevoTiposDesc()" />
              <!-- <Button icon="pi pi-external-link" label="Exportar" /> -->
            </template>
          </Toolbar>
          <DataTable ref="dt" :value="obtenerTiposDescuentos ? obtenerTiposDescuentos : []" stripedRows dataKey="id"
            :loading="loading" responsiveLayout="scroll">
            <!-- <Column selectionMode="multiple"></Column> -->
            <Column field="nombre" header="Tipo descuento" :sortable="true" class="cursor-pointer">
              <template #body="slotProps">
                {{ slotProps.data.nombre }}
              </template>
            </Column>
            <Column field="prioridad" header="Descripción" :sortable="true" class="cursor-pointer">
              <template #body="slotProps">
                {{ slotProps.data.descripcion }}
              </template>
            </Column>
            <Column field="habilitado" header="Habilitada" :sortable="true" class="cursor-pointer">
              <template #body="slotProps">
                <em class="pi" :class="{
                  'text-teal-500 pi-check-circle': slotProps.data.habilitado,
                  'text-red-500 pi-times-circle': !slotProps.data.habilitado,
                }"></em>
              </template>
            </Column>
            <Column header="Editar">
              <template #body="slotProps">
                <em class="pi pi-pencil mr-2 text-black-500 text-xl cursor-pointer"
                  v-tooltip.left="'Detalle de la campaña'" @click="editarTiposDes(slotProps.data)"></em>
              </template>
            </Column>
          </DataTable>
        </TabPanel>
      </TabView>
    </div>

    <Dialog v-model:visible="nuevaCampania" :breakpoints="{ '960px': '75vw', '200px': '100vw' }"
      :style="{ width: '50vw' }" header="Crear Campaña" :modal="true" class="p-fluid" @hide="ocultarDialogCampania()">
      <div class="grid p-fluid">
        <div class="col-12 md:col-6">
          <div class="field">
            <label for="nombre">Nombre campaña*</label>
            <InputText id="nombre" name="nombre" autofocus v-model.trim="v$.campania.nombre.$model"
              :class="{ 'p-invalid': v$.campania.nombre.$error }" />
            <small class="p-error" v-if="v$.campania.nombre.$error">Escribe un nombre campaña.</small>
          </div>
        </div>
        <div class="col-12 md:col-6">
          <div class="field">
            <label for="descripcion">Descripción campaña*</label>
            <InputText id="descripcion" name="descripcion" autofocus v-model.trim="v$.campania.descripcion.$model"
              :class="{ 'p-invalid': v$.campania.descripcion.$error }" />
            <small class="p-error" v-if="v$.campania.descripcion.$error">Escribe una descripción.</small>
          </div>
        </div>
        <div class="col-12 md:col-6">
          <div class="field">
            <label for="vigencia_desde">Vigencia desde*</label>
            <Calendar id="vigencia_desde" name="vigencia_desde" v-model="v$.campania.vigencia_desde.$model"
              :class="{ 'p-invalid': v$.campania.vigencia_desde.$error }" />
            <small class="p-error" v-if="v$.campania.vigencia_desde.$error">Selecciona una vigencia desde.</small>
          </div>
        </div>
        <div class="col-12 md:col-6">
          <div class="field">
            <label for="vigencia_hasta">vigencia hasta*</label>
            <Calendar id="vigencia_hasta" name="vigencia_hasta" v-model="v$.campania.vigencia_hasta.$model"
              :minDate="minDate" :class="{ 'p-invalid': v$.campania.vigencia_hasta.$error }" />
            <small class="p-error" v-if="v$.campania.vigencia_hasta.$error"> Selecciona una vigencia hasta.</small>
          </div>
        </div>
        <div class="col-12 md:col-6">
          <div class="field">
            <label for="habilitado">¿Habilitado?*</label>
            <ToggleButton style="text-align: justify;" v-model="v$.campania.habilitado.$model" onLabel="Si" offLabel="No"
              :class="{ 'p-invalid': v$.campania.habilitado.$error }" />
            <small class="p-error" v-if="v$.campania.habilitado.$error">Selecciona si esta habilitado.</small>
          </div>
        </div>

        <div v-if="editarDatos == false" class="flex col-12">
          <a>¿Agregar condiciones de campaña? </a>
              <Checkbox v-model="checked" :binary="true"  />
        </div>
      </div>
      <div v-if="checked == true && editarDatos == false" class="grid p-fluid">
        <div class="col-12 md:col-6">
          <div class="field">
            <label for="importe_minimo">Importe minimo</label>
            <InputNumber id="importe_minimo" name="importe_minimo" v-model="v$.campania.importe_minimo.$model"
              :class="{ 'p-invalid': v$.campania.importe_minimo.$error }" />
            <small class="p-error" v-if="v$.campania.importe_minimo.$error">Escribe un Importe minimo.</small>
          </div>
        </div>
        <div class="col-12 md:col-6">
          <div class="field">
            <label for="productos_requeridos">Productos requeridos</label>
            <InputNumber id="productos_requeridos" name="productos_requeridos"
              v-model="v$.campania.productos_requeridos.$model"
              :class="{ 'p-invalid': v$.campania.productos_requeridos.$error }" />
            <small class="p-error" v-if="v$.campania.productos_requeridos.$error">Escribe productos requeridos.</small>
          </div>
        </div>
        <div class="col-12 md:col-6">
          <div class="field">
            <label for="escalonado">¿Escalonado?</label>
            <ToggleButton style="text-align: justify;" v-model="v$.campania.escalonado.$model" onLabel="Si" offLabel="No"
              :class="{ 'p-invalid': v$.campania.escalonado.$error }" />
            <small class="p-error" v-if="v$.campania.escalonado.$error">Selecciona si es escalonado.</small>
          </div>
        </div>
        <div class="col-12 md:col-6">
          <div class="field">
            <label for="impuestos_excluidos">¿Impuestos excluidos?</label>
            <ToggleButton style="text-align: justify;" v-model="v$.campania.impuestos_excluidos.$model" onLabel="Si"
              offLabel="No" :class="{ 'p-invalid': v$.campania.impuestos_excluidos.$error }" />
            <small class="p-error" v-if="v$.campania.impuestos_excluidos.$error">Selecciona si es impuesto
              excluidos.</small>
          </div>
        </div>
        <div class="col-12 md:col-6">
          <div class="field">
            <label for="envio_excluido">¿Envio excluido?</label>
            <ToggleButton style="text-align: justify;" v-model="v$.campania.envio_excluido.$model" onLabel="Si"
              offLabel="No" :class="{ 'p-invalid': v$.campania.envio_excluido.$error }" />
            <small class="p-error" v-if="v$.campania.envio_excluido.$error">Selecciona si es envio excluido.</small>
          </div>
        </div>
        <div class="col-12 md:col-6">
          <div class="field">
            <label for="total_disponible">Total disponible</label>
            <InputNumber id="total_disponible" name="total_disponible" v-model="v$.campania.total_disponible.$model"
              :class="{ 'p-invalid': v$.campania.total_disponible.$error }" />
            <small class="p-error" v-if="v$.campania.total_disponible.$error">Esescriba un total disponible.</small>
          </div>
        </div>
        <div class="col-12 md:col-6">
          <div class="field">
            <label for="total_disponible_usuario">Total disponible usuario</label>
            <InputNumber id="total_disponible_usuario" name="total_disponible_usuario"
              v-model="v$.campania.total_disponible_usuario.$model"
              :class="{ 'p-invalid': v$.campania.total_disponible_usuario.$error }" />
            <small class="p-error" v-if="v$.campania.total_disponible_usuario.$error">Escribe un total disponible
              usuario.</small>
          </div>
        </div>
        <div class="flex col-12">
          <a>Detalles condiciones campaña</a>
        </div>
        <div class="col-12 md:col-6">
          <div class="field">
            <label for="id_tienda">Id tienda</label>
            <InputNumber id="id_tienda" name="id_tienda" v-model="v$.campania.id_tienda.$model"
              :class="{ 'p-invalid': v$.campania.id_tienda.$error }" />
            <small class="p-error" v-if="v$.campania.id_tienda.$error">Escribe un id tienda.</small>
          </div>
        </div>
        <div class="col-12 md:col-6">
          <div class="field">
            <label for="id_producto">Id producto</label>
            <InputNumber id="id_producto" name="id_producto" v-model="v$.campania.id_producto.$model"
              :class="{ 'p-invalid': v$.campania.id_producto.$error }" />
            <small class="p-error" v-if="v$.campania.id_producto.$error">Escribe un id producto.</small>
          </div>
        </div>
        <div class="col-12 md:col-6">
          <div class="field">
            <label for="id_categoria">Id categoria</label>
            <InputNumber id="id_categoria" name="id_categoria" v-model="v$.campania.id_categoria.$model"
              :class="{ 'p-invalid': v$.campania.id_categoria.$error }" />
            <small class="p-error" v-if="v$.campania.id_categoria.$error">Escribe un id categoria.</small>
          </div>
        </div>
        <div class="col-12 md:col-6">
          <div class="field">
            <label for="id_grupo">Id grupo</label>
            <InputNumber id="id_grupo" name="id_grupo" v-model="v$.campania.id_grupo.$model"
              :class="{ 'p-invalid': v$.campania.id_grupo.$error }" />
            <small class="p-error" v-if="v$.campania.id_grupo.$error">Escribe un id grupo.</small>
          </div>
        </div>
        <div class="col-12 md:col-6">
          <div class="field">
            <label for="ciudad">Ciudad</label>
            <InputText id="ciudad" name="ciudad" v-model.trim="v$.campania.ciudad.$model"
              :class="{ 'p-invalid': v$.campania.ciudad.$error }" />
            <small class="p-error" v-if="v$.campania.ciudad.$error">Escribe una ciudad.</small>
          </div>
        </div>
      </div>
      <template #footer>
        <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="ocultarDialogCampania()" />
        <Button label="Guardar" icon="pi pi-check" class="p-button-text" @click="guardarCampania(!v$.campania.$invalid)"
          :disabled="v$.campania.$invalid || v$.campania.$error" />
      </template>
    </Dialog>
    <Dialog v-model:visible="abrirNuevoCupon" :breakpoints="{ '960px': '75vw', '200px': '100vw' }"
      :style="{ width: '50vw' }" header="Crear Cupon" :modal="true" class="p-fluid" @hide="ocultarDialogCupon()">
      <div class="grid p-fluid">
        <div class="col-12 md:col-6">
          <div class="field">
            <label for="codigo">Codigo cupon*</label>
            <InputText id="codigo" name="codigo" autofocus v-model.trim="v$.cupones.codigo.$model"
              :class="{ 'p-invalid': v$.cupones.codigo.$error }" />
            <small class="p-error" v-if="v$.cupones.codigo.$error">Escribe un nombre campaña.</small>
          </div>
        </div>
        <div class="col-12 md:col-6">
          <div class="field">
            <label for="descripcion">Descripción cupon*</label>
            <InputText id="descripcion" name="descripcion" autofocus v-model.trim="v$.cupones.descripcion.$model"
              :class="{ 'p-invalid': v$.cupones.descripcion.$error }" />
            <small class="p-error" v-if="v$.cupones.descripcion.$error">Escribe una descripción.</small>
          </div>
        </div>
        <div class="col-12 md:col-6">
          <div class="field">
            <label for="tipo_descuento">tipo descuento*</label>
            <!-- <InputText id="tipo_descuento" name="tipo_descuento" autofocus v-model.trim="v$.cupones.tipo_descuento.$model"
                        :class="{ 'p-invalid': v$.cupones.tipo_descuento.$error }" /> -->
            <Dropdown v-model="v$.cupones.tipo_descuento.$model" :options="los_tipos_descuento" optionLabel="nombre"
              placeholder="Select tipo descuento" class="w-full "
              :class="{ 'p-invalid': v$.cupones.tipo_descuento.$error }" />
            <small class="p-error" v-if="v$.cupones.tipo_descuento.$error">Escribe una descripción.</small>
          </div>
        </div>
        <div class="col-12 md:col-6">
          <div class="field">
            <label for="valor">valor*</label>
            <InputNumber id="valor" name="valor" v-model="v$.cupones.valor.$model"
              :class="{ 'p-invalid': v$.cupones.valor.$error }" />
            <small class="p-error" v-if="v$.cupones.valor.$error">Escribe una cantidad.</small>
          </div>
        </div>
        <div class="col-12 md:col-6">
          <div class="field">
            <label for="cantidad">cantidad*</label>
            <InputNumber id="cantidad" name="cantidad" v-model="v$.cupones.cantidad.$model"
              :class="{ 'p-invalid': v$.cupones.cantidad.$error }" />
            <small class="p-error" v-if="v$.cupones.cantidad.$error">Escribe una cantidad.</small>
          </div>
        </div>
        <div class="col-12 md:col-6">
          <div class="field">
            <label for="vigencia_desde">Vigencia desde*</label>
            <Calendar id="vigencia_desde" name="vigencia_desde" v-model="v$.cupones.vigencia_desde.$model"
              :class="{ 'p-invalid': v$.cupones.vigencia_desde.$error }" />
            <small class="p-error" v-if="v$.cupones.vigencia_desde.$error">Selecciona una vigencia desde.</small>
          </div>
        </div>
        <div class="col-12 md:col-6">
          <div class="field">
            <label for="vigencia_hasta">vigencia hasta*</label>
            <Calendar id="vigencia_hasta" name="vigencia_hasta" v-model="v$.cupones.vigencia_hasta.$model"
              :minDate="minDate" :manualInput="false" :class="{ 'p-invalid': v$.cupones.vigencia_hasta.$error }" />
            <small class="p-error" v-if="v$.cupones.vigencia_hasta.$error"> Selecciona una vigencia hasta.</small>
          </div>
        </div>
        <div class="col-12 md:col-6 ">
          <div class="field">
            <label for="habilitado">¿Habilitado?*</label>
            <ToggleButton style="text-align: justify;" v-model="v$.cupones.habilitado.$model" onLabel="Si" offLabel="No"
              :class="{ 'p-invalid': v$.cupones.habilitado.$error }" />
            <small class="p-error" v-if="v$.cupones.habilitado.$error">Selecciona si esta habilitado.</small>
          </div>
        </div>
        <div class="col-12 md:col-6 ">
          <div class="field">
            <label for="habilitado">Selecciona Campaña</label>
            <MultiSelect v-model="selectedCampanias" :options="campaniasCupones" optionLabel="nombre"
              placeholder="Selecciona campaña" class="w-full " />
          </div>
        </div>
      </div>
      <template #footer>
        <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="ocultarDialogCupon()" />
        <Button label="Guardar" icon="pi pi-check" class="p-button-text" @click="guardarCupon(!v$.cupones.$invalid)"
          :disabled="v$.cupones.$invalid || v$.cupones.$error" />
      </template>
    </Dialog>
    <Dialog v-model:visible="abrirNuevoTiposDes" :breakpoints="{ '960px': '75vw', '200px': '100vw' }"
      :style="{ width: '30vw' }" header="Crear tipo descuento" :modal="true" class="p-fluid"
      @hide="ocultarDialogTipoDesc()">

      <div class="col-12 ">
        <div class="field">
          <label for="nombre">Nombre descuento*</label>
          <InputText id="nombre" name="nombre" autofocus v-model.trim="v$.tiposDes.nombre.$model"
            :class="{ 'p-invalid': v$.tiposDes.nombre.$error }" />
          <small class="p-error" v-if="v$.tiposDes.nombre.$error">Escribe un nombre tipo descuento.</small>
        </div>
      </div>
      <div class="col-12 ">
        <div class="field">
          <label for="descripcion">Descripción cupon*</label>
          <InputText id="descripcion" name="descripcion" autofocus v-model.trim="v$.tiposDes.descripcion.$model"
            :class="{ 'p-invalid': v$.tiposDes.descripcion.$error }" />
          <small class="p-error" v-if="v$.tiposDes.descripcion.$error">Escribe una descripción.</small>
        </div>
      </div>
      <div class="col-12 ">
        <div class="field">
          <label for="habilitado">¿Habilitado?*</label>
          <ToggleButton style="text-align: justify;" v-model="v$.tiposDes.habilitado.$model" onLabel="Si" offLabel="No"
            :class="{ 'p-invalid': v$.tiposDes.habilitado.$error }" />
          <small class="p-error" v-if="v$.tiposDes.habilitado.$error">Selecciona si esta habilitado.</small>
        </div>
      </div>
      <template #footer>
        <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="ocultarDialogTipoDesc()" />
        <Button label="Guardar" icon="pi pi-check" class="p-button-text"
          @click="guardarTipoDescuento(!v$.tiposDes.$invalid)" :disabled="v$.tiposDes.$invalid || v$.tiposDes.$error" />
      </template>
    </Dialog>
  </div>
</template>

<script>
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { FilterMatchMode } from "primevue/api";
import { obtenerTodo, crear, actualizar } from "@/api/clienteHttpService"; //crear
// import { eliminarCuponService } from "@/api/cuponesServices";

export default {
  // name: "Cupones",
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      minDate: null,
      maxDate: null,
      campanias: {},
      campaniasAsignar: {},
      dialog_detalle_campania: false,
      selectedCampanias: null,
      nuevaCampania: false,
      abrirNuevoCupon: false,
      abrirNuevoTiposDes: false,
      campania: {
        escalonado: false,
      },
      cupones: {},
      tiposDes: {},
      loading: true,
      checked: false,
      editarDatos: false,
      editarDatosCampania: false,
      CCDatos: false,
      stepperOptions: {
        headers: [
          { title: 'Campaña', stepName: 'step-1' },
          { title: 'Condicion', stepName: 'step-2' },
          { title: 'Condiciones', stepName: 'step-3' },
          { title: 'Acciones', stepName: 'step-4' }
        ],
        prevText: 'Atras',
        nextText: 'Siguiente'
      },
      loadingTipos: true,
      cargando: false,
      eliminarCuponDialog: false,
      hoy: new Date(),
      cuponDialog: false,
      cuponSeleccionada: null,
      filters: {},
      noEnviar: false,
      logo: null,
      editCuponActivo: false,
      cuponSeleccionadaClick: {},
      editarTiposDescuento: false,
      selectedTipoDescuento: null,
      obtenerCupones: [
      ],
      obtenerTiposDescuentos: [],
      los_tipos_descuento: [],
      clickedItems: [],
      campaniasCupones: [],
      cuponesCampanias: [],
    };
  },
  validations() {
    return {
      campania: {
        nombre: { required },
        descripcion: { required },
        vigencia_desde: { required },
        vigencia_hasta: { required },
        habilitado: {},
        escalonado: {},
        importe_minimo: {},
        productos_requeridos: {},
        impuestos_excluidos: {},
        envio_excluido: {},
        total_disponible: {},
        total_disponible_usuario: {},
        id_tienda: {},
        id_producto: {},
        id_categoria: {},
        id_grupo: {},
        ciudad: {}
      },
      cupones: {
        codigo: { required },
        descripcion: { required },
        tipo_descuento: { required },
        valor: { required },
        cantidad: { required },
        vigencia_desde: { required },
        vigencia_hasta: { required },
        habilitado: {},
        campaniaSelec: {},
        // id_user_log:{required},
      },
      tiposDes: {
        nombre: { required },
        descripcion: { required },
        habilitado: {},
      },
      // minDate: null,

    };
  },
  async created() {
    this.initFilters();
    this.obtenerCampanias();
    this.obtenerTodosLosCupones();
    this.obtenerTipoDescuentos();
    this.obtenerTiposDescuento();
  },
  methods: {

    async obtenerCampanias() {
      this.loading = true;
      const resp = await obtenerTodo("/campanias");
      this.campanias = resp.campanias;
      this.campaniasAsignar = this.campanias;
      this.loading = false;
    },

    async obtenerTiposDescuento() {
      const resp = await obtenerTodo("/cupones/tipos/descuentos");
      this.obtenerTiposDescuentos = resp;
    },
    verDetalleCampania(campania) {
      this.dialog_detalle_campania = true;
      this.campania = campania;
    },
    async obtenerTipoDescuentos() {
      this.loadingTipos = true;
      const resp = await obtenerTodo("/cupones/tipos");
      this.los_tipos_descuento = resp;
      const resps = await obtenerTodo("/cupones/campanias/datos");
      this.campaniasCupones = resps.campaniasCupones;

    },
    async obtenerTodosLosCupones() {
      this.loadingTipos = true;
      const resp = await obtenerTodo("/cupones");
      this.obtenerCupones = resp.cupones;
      this.loadingTipos = false;
    },

    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
    abrirDialog() {

      this.nuevaCampania = true;
      this.editarDatosCampania = false;

    },
    nuevoCupon() {
      this.abrirNuevoCupon = true;
      this.calcularFechas();
    },
    nuevoTiposDesc() {
      this.editarTiposDescuento = false;
      this.abrirNuevoTiposDes = true;

    },
    calcularFechas() {
      let today = new Date();
        let month = today.getMonth();
        let year = today.getFullYear();
        let prevMonth = month === 0 ? 11 : month;
        let prevYear = prevMonth === 11 ? year - 1 : year;
        let nextMonth = month === 11 ? 0 : month + 1;
        let nextYear = nextMonth === 0 ? year + 1 : year;

        this.minDate = new Date();
        this.minDate.setMonth(prevMonth);
        this.minDate.setFullYear(prevYear);
        this.maxDate = new Date();
        this.maxDate.setMonth(nextMonth);
        this.maxDate.setFullYear(nextYear);
    },

    ocultarDialogCampania() {
      this.campania = {};
      this.cuponDialog = false;

    },
    ocultarDialogCupon() {
      this.cupones = {};
      this.abrirNuevoCupon = false;

    },
    ocultarDialogTipoDesc() {
      this.tiposDes = {}
      this.abrirNuevoTiposDes = false;

    },
    async guardarCampania(isFormValid) {
      this.submitted = true;
      if (isFormValid) {
        if (this.editarDatosCampania == true) {
          if (this.checked == true) {
            const resp = await actualizar("/campanias/ccompleta", this.campania);
            if (resp == "200") {
              this.obtenerCampanias();
              this.nuevaCampania = false;
              this.$toast.add({ severity: "success", summary: "Campaña: ", detail: "Actualizada correctamente", life: 3000 })
              this.obtenerCampanias();
              this.obtenerTodosLosCupones();
              this.obtenerTipoDescuentos();
              this.obtenerTiposDescuento()
            } else {
              this.obtenerCampanias();
              this.nuevaCampania = false;
              this.$toast.add({ severity: "error", summary: "Campaña: " + this.tienda.nombre_amigable, detail: "No fue actualizada correctamente", life: 3000 })
            }

          } else {
            const resp = await actualizar("/campanias", this.campania);
            if (resp == "200") {
              this.obtenerCampanias();
              this.nuevaCampania = false;
              this.$toast.add({ severity: "success", summary: "Campaña: ", detail: "Actualizada correctamente", life: 3000 })
              this.obtenerCampanias();
              this.obtenerTodosLosCupones();
              this.obtenerTipoDescuentos();
              this.obtenerTiposDescuento()
            } else {
              this.obtenerCampanias();
              this.nuevaCampania = false;
              this.$toast.add({ severity: "error", summary: "Campaña: " + this.tienda.nombre_amigable, detail: "No fue actualizada correctamente", life: 3000 })
            }
          }

        } else {
          if (this.checked == true) {
            const resp = await crear("/campanias/ccompleta", this.campania);
            if (resp == "200") {
              this.obtenerCampanias();
              this.nuevaCampania = false;
              this.$toast.add({ severity: "success", summary: "Campaña: ", detail: "Creada correctamente", life: 3000 })
              this.obtenerCampanias();
              this.obtenerTodosLosCupones();
              this.obtenerTipoDescuentos();
              this.obtenerTiposDescuento()
            } else {
              this.obtenerCampanias();
              this.nuevaCampania = false;
              this.$toast.add({ severity: "error", summary: "Campaña: " + this.tienda.nombre_amigable, detail: "No fue creada correctamente", life: 3000 })
            }

          } else {
            const resp = await crear("/campanias", this.campania);
            if (resp == "200") {
              this.obtenerCampanias();
              this.nuevaCampania = false;
              this.$toast.add({ severity: "success", summary: "Campaña: ", detail: "Creada correctamente", life: 3000 })
              this.obtenerCampanias();
              this.obtenerTodosLosCupones();
              this.obtenerTipoDescuentos();
              this.obtenerTiposDescuento()
            } else {
              this.obtenerCampanias();
              this.nuevaCampania = false;
              this.$toast.add({ severity: "error", summary: "Campaña: " + this.tienda.nombre_amigable, detail: "No fue creada correctamente", life: 3000 })
            }

          }

        }
      }
    },
    async guardarCupon() {
      this.cupones.campaniaSelec = this.selectedCampanias;
      if (this.editCuponActivo == false) {
        const resp = await crear("/cupones", this.cupones);
        if (resp == "200") {
          this.abrirNuevoCupon = false;
          this.$toast.add({ severity: "success", summary: "Cupon: ", detail: "Creado correctamente", life: 3000 })
          this.obtenerCampanias();
          this.obtenerTodosLosCupones();
          this.obtenerTipoDescuentos();
          this.obtenerTiposDescuento()
        } else {
          this.obtenerCampanias();
          this.$toast.add({ severity: "error", summary: "Cupon: ", detail: "No fue creado correctamente", life: 3000 })
        }
      } else {
        const resp = await actualizar("/cupones", this.cupones);
        console.log(resp);
        if (resp == "200") {
          this.abrirNuevoCupon = false;
          this.editCuponActivo = false;
          this.$toast.add({ severity: "success", summary: "Cupon: ", detail: "Actualizado correctamente", life: 3000 })
          this.obtenerCampanias();
          this.obtenerTodosLosCupones();
          this.obtenerTipoDescuentos();
          this.obtenerTiposDescuento()
        } else {
          this.obtenerCampanias();
          this.$toast.add({ severity: "error", summary: "Cupon: ", detail: "No fue Actualizado correctamente", life: 3000 })
        }

      }

    },
    async guardarTipoDescuento() {
      if (this.editarTiposDescuento == false) {
        const resp = await crear("/cupones/tipos/descuentos", this.tiposDes);
        if (resp == "200") {
          this.editarTiposDescuento = false;
          this.abrirNuevoTiposDes = false;
          this.$toast.add({ severity: "success", summary: "Cupon: ", detail: "Actualizado correctamente", life: 3000 })
          this.obtenerCampanias();
          this.obtenerTodosLosCupones();
          this.obtenerTipoDescuentos();
          this.obtenerTiposDescuento()
        } else {
          this.obtenerCampanias();
          this.$toast.add({ severity: "error", summary: "Cupon: ", detail: "No fue Actualizado correctamente", life: 3000 })
        }

      } else {
        const resp = await actualizar("/cupones/tipos/descuentos", this.tiposDes);
        if (resp == "200") {
          this.editarTiposDescuento = false;
          this.abrirNuevoTiposDes = false;
          this.$toast.add({ severity: "success", summary: "Cupon: ", detail: "Actualizado correctamente", life: 3000 })
          this.obtenerCampanias();
          this.obtenerTodosLosCupones();
          this.obtenerTipoDescuentos();
          this.obtenerTiposDescuento()
        } else {
          this.obtenerCampanias();
          this.$toast.add({ severity: "error", summary: "Cupon: ", detail: "No fue Actualizado correctamente", life: 3000 })
        }
      }


    },
    editarCampania(datos) {
      this.editarDatos = false;
      this.campania = datos;
      this.editarDatosCampania = true;
      if (this.campania.id_cc == null) {
        this.checked = false;
        this.editarDatos = true;
      }
      this.nuevaCampania = true;

    },
    editarTiposDes(datos) {
      this.tiposDes = datos;
      this.abrirNuevoTiposDes = true;
      this.editarTiposDescuento = true;

    },
    async editarCupon(datos) {
      this.editCuponActivo = true;
      this.cupones = datos;
      this.abrirNuevoCupon = true;
      const respCC = await obtenerTodo("/cupones/campanias/cuponesCampanias/" + datos.id);
      this.cuponesCampanias = respCC.cuponesCampanias;
      let campaniasporcupon = [];
      for (let i = 0; i < this.los_tipos_descuento.length; i++) {
        if (this.los_tipos_descuento[i].id == datos.tipo_descuento) {
          this.cupones.tipo_descuento = this.los_tipos_descuento[i];
        }
      }
      for (let i = 0; i < this.cuponesCampanias.length; i++) {
        for (let j = 0; j < this.campaniasCupones.length; j++) {
          if (this.cuponesCampanias[i].id_campania == this.campaniasCupones[j].id) {
            campaniasporcupon.push(this.campaniasCupones[j]);
          }
        }
      }
      this.selectedCampanias = campaniasporcupon;


    },
    confirmarEliminarCupon(codigo_cupon_eliminar) {
      this.codigo_cupon_eliminar = codigo_cupon_eliminar;
      this.eliminarCuponDialog = true;
    },
  },
  formatDate(dateString) {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('es-CO').format(date);
  },
};
</script>

<style scoped>
.p-dialog-content {
  padding: 0 0 0 0 important;
}

.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
  
