<template>
  <section class="contenedor-principal">
    <h1 class="titulo">Banner principal</h1>
  </section>
  <section class="contenedor-listas-banners">
    <!-- lista banners web -->
    <div class="contenedor-lista">
      <OrderList
        class="lista"
        v-model="producto"
        listStyle="height:auto"
        dataKey="id"
        @update:modelValue="imptimir($event)"
      >
        <template #header> Lista banners web </template>
        <template #item="slotProps">
          <div class="imagen">
            <img class="imagen-lista" :src="slotProps.item.imagen" />
            <pre>{{ slotProps.item.id }}</pre>
            <pre>{{ slotProps.index }}</pre>
          </div>
        </template>
      </OrderList>
    </div>

    <!-- lista banners mobile -->
    <div class="contenedor-lista">
      <OrderList
        class="lista"
        v-model="producto"
        listStyle="height:auto"
        dataKey="id"
        @update:modelValue="imptimir($event)"
      >
        <template #header> Lista banners mobile </template>
        <template #item="slotProps">
          <div class="imagen">
            <img class="imagen-lista" :src="slotProps.item.imagen" />
            <pre>{{ slotProps.item.id }}</pre>
            <pre>{{ slotProps.index }}</pre>
          </div>
        </template>
      </OrderList>
    </div>
  </section>
</template>

<script setup>
import { ref } from "vue";

const producto = ref([
  {
    id: 1,
    imagen:
      "https://fastly.picsum.photos/id/653/200/300.jpg?hmac=-wtNmzX9vRed_gkrBqMwx0wVWWD6uczwVxllG2txJXs",
  },
  {
    id: 2,
    imagen:
      "https://fastly.picsum.photos/id/677/200/300.jpg?hmac=SY5ivEgZyzceLCwED8Yf6wbKGkh7BZ9JrsxywDxM1m0",
  },
  {
    id: 3,
    imagen:
      "https://fastly.picsum.photos/id/910/200/300.jpg?hmac=7qhIWU6_Tq8mQzJNTsBvtWdzNIz7uvspoAuLTJ3542M",
  },
  {
    id: 4,
    imagen:
      "https://fastly.picsum.photos/id/631/200/300.jpg?hmac=fgDzRjKee8EpUbckbz7kp1S1ssIqedrw2oOk5mBYQvk",
  },
  {
    id: 5,
    imagen:
      "https://fastly.picsum.photos/id/1021/200/300.jpg?hmac=hTRmKnWJoXfl5p7_LZK9lWOLXJZiN6_EUJmWpmZIXYI",
  },
]);
</script>

<style scope>
@import "../../assets/styles/bannerPrincipal.css";
</style>
