<template>
    <div class="col-12 xl:col-12">
        <!-- <div class="grid justify-content-between flex-wrap card-container purple-container mb-3">
            <div class="col-12 lg:col-6 xl:col-3">
                <div class="card mb-0">
                    <div class="flex justify-content-between mb-3">
                        <div>
                            <span class="block text-500 font-medium mb-3">Orders</span>
                            <div class="text-900 font-medium text-xl">152</div>
                        </div>
                        <div class="flex align-items-center justify-content-center bg-blue-100 border-round"
                            style="width:2.5rem;height:2.5rem">
                            <i class="pi pi-shopping-cart text-blue-500 text-xl"></i>
                        </div>
                    </div>
                    <span class="text-green-500 font-medium">24 new </span>
                    <span class="text-500">since last visit</span>
                </div>
            </div>
            <div class="col-12 lg:col-6 xl:col-3">
                <div class="card mb-0">
                    <div class="flex justify-content-between mb-3">
                        <div>
                            <span class="block text-500 font-medium mb-3">Orders</span>
                            <div class="text-900 font-medium text-xl">152</div>
                        </div>
                        <div class="flex align-items-center justify-content-center bg-blue-100 border-round"
                            style="width:2.5rem;height:2.5rem">
                            <i class="pi pi-shopping-cart text-blue-500 text-xl"></i>
                        </div>
                    </div>
                    <span class="text-green-500 font-medium">24 new </span>
                    <span class="text-500">since last visit</span>
                </div>
            </div>
            <div class="col-12 lg:col-6 xl:col-3">
                <div class="card mb-0">
                    <div class="flex justify-content-between mb-3">
                        <div>
                            <span class="block text-500 font-medium mb-3">Orders</span>
                            <div class="text-900 font-medium text-xl">152</div>
                        </div>
                        <div class="flex align-items-center justify-content-center bg-blue-100 border-round"
                            style="width:2.5rem;height:2.5rem">
                            <i class="pi pi-shopping-cart text-blue-500 text-xl"></i>
                        </div>
                    </div>
                    <span class="text-green-500 font-medium">24 new </span>
                    <span class="text-500">since last visit</span>
                </div>
            </div>
            <div class="col-12 lg:col-6 xl:col-3">
                <div class="card mb-0">
                    <div class="flex justify-content-between mb-3">
                        <div>
                            <span class="block text-500 font-medium mb-3">Orders</span>
                            <div class="text-900 font-medium text-xl">152</div>
                        </div>
                        <div class="flex align-items-center justify-content-center bg-blue-100 border-round"
                            style="width:2.5rem;height:2.5rem">
                            <i class="pi pi-shopping-cart text-blue-500 text-xl"></i>
                        </div>
                    </div>
                    <span class="text-green-500 font-medium">24 new </span>
                    <span class="text-500">since last visit</span>
                </div>
            </div>
        </div> -->
        <IntegracionesTable></IntegracionesTable>
        <ValidacionesTable></ValidacionesTable>
        <!-- <div class="flex">
            <div class="col-6">
                <TiempoItegracionTable></TiempoItegracionTable>
            </div>
            <div class="col-6">
                <ValidacionesTable></ValidacionesTable>
            </div>
        </div> -->
    </div>
</template>
<script>
import { obtenerTodo } from '@/api/clienteHttpService.js';
import IntegracionesTable from "../components/IntegracionesTable.vue";
import ValidacionesTable from "../components/ValidacionesTable.vue";


export default {
    name: 'Integraciones',
    data: () => ({
        parametros: null,
        loading: false,
    }),
    created() {
        // this.obtenerIntegraciones();
    },
    methods: {
        async obtenerIntegraciones() {
            this.loading = true;
            const respuesta = await obtenerTodo("/productos/integraciones/obtenerIntegracionesLog");
            this.parametros = respuesta;
            this.loading = false;
        },
        calculateClass(estado) {
            if (estado === 34 || estado === 35 || estado === 37) {
                return 'text-teal-500 pi-check-circle';
            } else if (estado === 33 || estado === 36 || estado == 38 || estado == 39 || estado == 40 || estado == 41 || estado == 42 || estado == 43) {
                return 'text-red-500 pi-times-circle';
            } else if (estado === 44 || estado === 45) {
                return 'text-yellow-500 pi-exclamation-circle';
            } else {
                return 'text-red-500 pi-times-circle'; // Clase por defecto o ninguna clase si no se cumple ninguna condición
            }
        }
    },
    components: {
        IntegracionesTable,
        ValidacionesTable,
        // TiempoItegracionTable
    },
}
</script>
<style scoped></style>