<template>
  <div class="grid flex justify-content-center">
    <div class="col-12">
      <div class="card">
        <Toast />
        <Toolbar class="mb-4">
          <template v-slot:start>
            <h5 class="m-4">Categoria</h5>
          </template>

          <template v-slot:end>
            <Button label="Crear Categoria" icon="pi pi-plus" class="p-button-success mr-2" @click="nuevaCategoria" />
                <!-- <Button icon="pi pi-external-link" label="Exportar" @click="exportCSV($event)" /> -->
              </template>
            </Toolbar>


            <DataTable ref="dt" :value="mostrarCategorias" dataKey="id" :paginator="true" :rows="10" :filters="filters"
              :loading="loading"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              :rowsPerPageOptions="[5, 10, 25]"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} categorias" responsiveLayout="scroll">
              <template #header>
                <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                  <span class="block mt-2 md:mt-0 p-input-icon-left">
                    <i class="pi pi-search" />
                    <InputText v-model="filters['global'].value" placeholder="Buscar..." />
                  </span>
                </div>
              </template>

              <!-- <Column selectionMode="multiple" headerStyle="width: 3rem"></Column> -->


              <Column field="nombre" header="Nombre" :sortable="true">
                <template #body="slotProps">
                  {{ slotProps.data.nombre }}
                </template>
              </Column>
              <Column field="descripcion" header="Descripcion" :sortable="true">
                <template #body="slotProps">
                  <span class="p-column-title">Descripcion</span>
                  {{ slotProps.data.descripcion }}
                </template>
              </Column>
              <Column field="nombre_amigable" header="Nombre amigable" :sortable="true"
                headerStyle="width:14%; min-width:10rem;">
                <template #body="slotProps">
                  <span class="p-column-title">Nombre amigable</span>
                  {{ slotProps.data.nombre_amigable }}
                </template>
              </Column>

              <Column field="categoria_padre" header="Categoria padre" :sortable="true">
                <template #body="slotProps">
                  <span class="p-column-title">Categoria Padre</span>
                  {{ slotProps.data.categoria_padre }}
                </template>
              </Column>

              <Column header="Image" headerStyle="width:14%; min-width:10rem;">
                <template #body="slotProps">
                  <span class="p-column-title">Imagen</span>
                  <img :src="slotProps.data.imagen" :alt="slotProps.data.imagen" class="shadow-2" width="100" />
                </template>
              </Column>
              <Column field="habilitado" header="Habilitada" :sortable="true">
                <template #body="slotProps">
                  <em class="pi" :class="{
                    'text-teal-500 pi-check-circle': slotProps.data.habilitado,
                    'text-red-500 pi-times-circle': !slotProps.data.habilitado,
                  }"></em>
                </template>
              </Column>

              <Column headerStyle="min-width:10rem;">
                <template #body="slotProps">
                  <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2"
                    @click="editarCategoria(slotProps.data)" />
                </template>
              </Column>
            </DataTable>

            <Dialog v-model:visible="userDialog" :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
              :style="{ width: '50vw' }" header=" Crear categoria" :modal="true" class="p-fluid">
              <div class="grid p-fluid">
                <div class="col-12 md:col-6">
                  <div class="field">
                    <label for="nombre">Nombre*</label>
                    <InputText id="nombre" name="nombre" autofocus v-model.trim="v$.formData.nombre.$model"
                      :class="{ 'p-invalid': v$.formData.nombre.$error }" />
                    <small class="p-error" v-if="v$.formData.nombre.$error">Nombre es requerido.</small>
                  </div>
                </div>
                <div class="col-12 md:col-6">
                  <div class="field">
                    <label for="descripcion">Descripción*</label>
                    <InputText type="text" id="descripcion" placeholder="descripcion" name="descripcion"
                      v-model.trim="v$.formData.descripcion.$model" required="true" autocomplete="off" autofocus
                      :class="{ 'p-invalid': submitted && !v$.formData.descripcion }" />
                    <small class="p-invalid" v-if="v$.formData.descripcion.$error">Descripción es requerido.</small>
                  </div>
                </div>
                <div class="col-12 md:col-6">
                  <div class="field">
                    <label for="nombre_amigable">Nombre amigable*</label>
                    <InputText type="text" id="nombre_amigable" placeholder="nombre_amigable" name="nombre_amigable"
                      v-model.trim="v$.formData.nombre_amigable.$model" required="true" autocomplete="off" autofocus
                      :class="{ 'p-invalid': submitted && !v$.formData.nombre_amigable }" />
                    <small class="p-invalid" v-if="v$.formData.nombre_amigable.$error">Nombre es requerido.</small>
                  </div>
                </div>
                <div class="col-12 md:col-6">
                  <div class="field">
                    <label for="nombre_url">Nombre URL*</label>
                    <InputText type="text" id="nombre_url" placeholder="nombre_url" name="nombre_url"
                      v-model.trim="v$.formData.nombre_url.$model" required="true" autocomplete="off" autofocus
                      :class="{ 'p-invalid': submitted && !v$.formData.nombre_url }" />
                    <small class="p-invalid" v-if="v$.formData.nombre_url.$error">Nombre URL es requerido.</small>
                  </div>
                </div>
                <div class="col-12 md:col-6">
                  <div class="field">
                    <label for="unidad_medida_peso">Padre</label>
                    <span class="p-float-label">
                      <TreeSelect v-model="selectedValue" :options="categorias" placeholder="Select Item" class=" w-full" />
                    </span>
                  </div>
                </div>
                <div class="col-12 md:col-6">
                  <div class="field">
                    <label for="id_unidad_medida_peso">Unidad de medida*</label>
                    <Dropdown v-model="v$.formData.id_unidad_medida_peso.$model" :options="unidadM" optionLabel="nombre"
                      placeholder="Select tipo descuento" class="w-full "
                      :class="{ 'p-invalid': v$.formData.id_unidad_medida_peso.$error }" />
                    <small class="p-error" v-if="v$.formData.id_unidad_medida_peso.$error">Selecciona una unidad de
                      medida.</small>
                  </div>
                </div>
                <div class="col-12 md:col-6">
                  <div class="field">
                    <label for="nombre">Peso*</label>
                    <InputNumber type="text" id="peso" placeholder="peso" name="peso" v-model="v$.formData.peso.$model"
                      required="true" autocomplete="off" autofocus :class="{ 'p-invalid': submitted && !v$.formData.peso }" />
                    <small class="p-invalid" v-if="v$.formData.peso.error">Nombre es requerido.</small>
                  </div>
                </div>
                <div class="col-12 md:col-6">
                  <div class="field">
                    <label for="habilitado">¿Habilitado?*</label>
                    <ToggleButton style="text-align: justify;" v-model="v$.formData.habilitado.$model" onLabel="Si"
                      offLabel="No" :class="{ 'p-invalid': v$.formData.habilitado.$error }" />
                    <small class="p-error" v-if="v$.formData.habilitado.$error">Selecciona si esta habilitado.</small>
                  </div>
                </div>
                <div class="col-6 md:col-3">
                  <FileUpload mode="basic" name="demo[]" :customUpload="true" chooseLabel="Subir imagen" :auto="true"
                    @uploader="subirimagen($event)" accept="image/*" disabled />
                </div>
                <div class="col-6 md:col-3">
                  <Image v-if="this.imagen" :src="this.imagen" alt="imagen categoria" imageClass="h-6rem w-10rem" preview />
                  <Image v-else-if="v$.formData.imagen" :src="v$.formData.imagen" :alt="v$.formData.nombre"
                    imageClass="h-6rem w-10rem" preview />
                </div>
                <div v-if="this.subirLaimagenOpcional == 1" class="col-6 md:col-3">
                  <FileUpload mode="basic" name="demo[]" :customUpload="true" chooseLabel="Subir imagen" :auto="true"
                    @uploader="subirimagenOpcional()" accept="image/*" disabled />
                </div>
                <div v-if="this.subirLaimagenOpcional == 1" class="col-6 md:col-3">
                  <Image v-if="this.imagen_opcional" :src="this.imagen_opcional" alt="imagen categoria"
                    imageClass="h-6rem w-10rem" preview />
                  <Image v-else-if="v$.formData.imagen_opcional" :src="v$.formData.imagen_opcional" :alt="v$.formData.nombre"
                    imageClass="h-6rem w-10rem" preview />
                </div>
              </div>
              <template #footer>
                <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="hideDialog" />
                <Button label="Guardar" icon="pi pi-check" class="p-button-text"
                  @click="guardarCategoria(!v$.formData.$invalid)" :disabled="v$.$invalid || v$.$error" />
                <!-- :disabled="v$.$invalid || v$.$error" -->
              </template>
            </Dialog>
          </div>
        </div>
      </div>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { FilterMatchMode } from "primevue/api";
import { obtenerTodo } from "@/api/clienteHttpService";
import { actualizar } from "@/api/clienteHttpService";
import { crear } from "@/api/clienteHttpService";

export default {
  setup: () => ({ v$: useVuelidate() }),
  watch: {
    selectedValue() {
      if (this.selectedValue != null) {
        this.obtenerCategoriaporID();
      }
    }
  },
  data() {
    return {
      unidad_medida: null,
      selectedValue: null,
      subirLaimagenOpcional: null,
      unidad_medida_seleccionada: null,
      Imagen: null,
      idlocalstorage: {
        id_user_log: "",
      },
      editarLaCategoria: false,
      mostrarCategorias: null,
      formData: {},
      messages: {
        severity: "",
        content: "",
      },
      loading: true,
      categorias: null,
      // logo: null,
      unidadM: null,
      um: null,
      unidadSelecionada: null,
      userDialog: false,
      selectedProducts: null,
      filters: {},
      imagen: null,
      imagen_opcional: null,
      submitted: false,
      // statuses: [
      //   { label: "HABILITADO", value: "habilitado" },
      //   { label: "INHABILITADO", value: "inhabilitado" },
      // ],
    };
  },
  validations() {
    return {
      formData: {
        nombre: { required },
        descripcion: { required },
        nombre_amigable: { required },
        nombre_url: { required },
        padre: {},
        imagen: {  },
        imagen_opcional: {},
        id_unidad_medida_peso: { required },
        peso: { required },
        habilitado: { required },
      },
    };
  },
  created() {
    this.obtenerCategorias();
    this.obtenerUnidadMedida();
    this.initFilters();
  },
  methods: {
    async obtenerUnidadMedida() {
      const resp = await obtenerTodo('/unidadmedida');
      this.unidadM = resp;
      // console.log(this.unidadM);
    },

    subirimagen(event) {

      this.formData.imagen = event.files[0];
      this.imagen = URL.createObjectURL(event.files[0]);
    },
    subirimagenOpcional(event) {
      this.formData.imagen_opcional = event.files[0];
      this.imagen_opcional = URL.createObjectURL(event.files[0]);
    },
    onUpload() {
      this.$toast.add({ severity: 'info', summary: 'Success', detail: 'File Uploaded', life: 3000 });
    },

    async obtenerCategorias() {
      this.loading = true;
      const resp = await obtenerTodo('/categorias/categorias/ver');
      this.mostrarCategorias = resp;
      // console.log(this.mostrarCategorias);

      const ressp = await obtenerTodo('/categorias/categorias/datos');
      this.categorias = ressp;
      // console.log(this.categorias);
      this.loading = false;
    },
    handleChange(event) {

      this.unidad_medida_seleccionada = event.value;
      // this.formData.id_unidad_medida_peso = this.unidad_medida_seleccionada[0].id;
    },
    nuevaCategoria() {
      this.editarLaCategoria = false;
      this.userDialog = true;
      this.formData = {};
      this.Imagen = false;
      // this.formData.id_user_log = localStorage.getItem("id");
      this.unidad_medida_seleccionada = null;
      // this.formData.habilitado = false;
      this.UR = false;
      // this.formData.id = this.formData.padre;
    },

    editarCategoria(formData) {
      this.editarLaCategoria = true;
      this.formData = { ...formData };
      let datosMedida = this.unidadM.filter(d => d.id === this.formData.id_unidad_medida_peso);
      let padre = {};
      padre[this.formData.padre] = true;
      this.selectedValue = padre;
      this.formData.id_unidad_medida_peso = datosMedida[0];
      this.imagen = this.formData.imagen;
      if (this.formData.imagen_opcional != '') {
        this.imagen_opcional = this.formData.imagen_opcional;
      }
      this.userDialog = true;
    },
    hideDialog() {
      console.log(this.selectedValue);
      // this.userDialog = false;
      // this.submitted = false;
      // this.formData = {};
    },
    async obtenerCategoriaporID() {
      this.idPadreSelect = Object.keys(this.selectedValue);
      console.log(this.selectedValue);
      // const resp = await obtenerTodo('/categorias/categorias/datos/' + this.idPadreSelect);
      // console.log(resp[0].nivel);
      this.subirLaimagenOpcional = this.idPadreSelect[0];

    },
    async guardarCategoria(isFormValid) {
      console.log(isFormValid);
      this.formData.padre = this.idPadreSelect[0];
      console.log('r');
      if (this.editarLaCategoria == true) {
        const resp = await actualizar('/categorias', this.formData);
        if (resp == 'Created') {
          this.$toast.add({ severity: "success", summary: "Categorria: " + "Actualizada correctamente", life: 3000 });
          this.userDialog = false;
          this.formData = {};
          this.obtenerCategorias();
        } else {
          this.$toast.add({ severity: "error", summary: "Categorria: " + "No actualizada correctamente", life: 3000 });

        }

      } else {
        const resp = await crear('/categorias', this.formData);
        if (resp == 'Created') {
          this.$toast.add({ severity: "success", summary: "Categorria: " + "Creada correctamente", life: 3000 });
          this.userDialog = false;
          this.formData = {};
          this.obtenerCategorias();
        } else {
          this.$toast.add({ severity: "error", summary: "Categorria: " + "No fue creada correctamente", life: 3000 });
        }

      }



      // this.submitted = true;
      // if (isFormValid) {
      //   if (this.formData.id) {
      //     console.log("Actualizando");
      //     console.log(this.formData);
      //     this.formData = await actualizar('/categorias', this.formData);
      //     this.$toast.add({
      //       severity: "success",
      //       summary: "Correcto",
      //       detail: "La categoría se ha actualizado",
      //       life: 3000,
      //     });
      //   } else {
      //     console.log('Creando');
      //     await crear('/categorias', this.formData);
      //     // this.$toast.add({
      //     //        severity: "success",
      //     //        summary: "Correcto",
      //     //        detail: "La categoría se ha creado correctamente",
      //     //        life: 3000,
      //     // });
      //   }
      //   this.userDialog = false;
      //   this.formData = {};
      //   this.obtenerCategorias();
      // }
    },

    subirImagen(event) {
      this.Imagen = event.files[0].objectURL;
      this.formData.imagen = event.files[0];
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
  },
};
</script>
