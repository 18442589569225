export const departamentosCiudades = [
    {
        "id": 0,
        "label": "Amazonas",
        "ciudades": [
            { label: "Leticia", value: "Leticia, Amazonas" },
            { label: "Puerto Nari\u00f1o", value: "Puerto Nari\u00f1o, Amazonas" },
        ]
    },
    {
        "id": 1,
        "label": "Antioquia",
        "ciudades": [
            { label: "Abejorral", value: "Abejorral, Antioquia" },
            { label: "Abriaqu\u00ed", value: "Abriaqu\u00ed, Antioquia" },
            { label: "Alejandr\u00eda", value: "Alejandr\u00eda, Antioquia" },
            { label: "Amag\u00e1", value: "Amag\u00e1, Antioquia" },
            { label: "Amalfi", value: "Amalfi, Antioquia" },
            { label: "Andes", value: "Andes, Antioquia" },
            { label: "Angel\u00f3polis", value: "Angel\u00f3polis, Antioquia" },
            { label: "Angostura", value: "Angostura, Antioquia" },
            { label: "Anor\u00ed", value: "Anor\u00ed, Antioquia" },
            { label: "Anz\u00e1", value: "Anz\u00e1, Antioquia" },
            { label: "Apartad\u00f3", value: "Apartad\u00f3, Antioquia" },
            { label: "Arboletes", value: "Arboletes, Antioquia" },
            { label: "Argelia", value: "Argelia, Antioquia" },
            { label: "Armenia", value: "Armenia, Antioquia" },
            { label: "Barbosa", value: "Barbosa, Antioquia" },
            { label: "Bello", value: "Bello, Antioquia" },
            { label: "Belmira", value: "Belmira, Antioquia" },
            { label: "Betania", value: "Betania, Antioquia" },
            { label: "Betulia", value: "Betulia, Antioquia" },
            { label: "Brice\u00f1o", value: "Brice\u00f1o, Antioquia" },
            { label: "Buritic\u00e1", value: "Buritic\u00e1, Antioquia" },
            { label: "C\u00e1ceres", value: "C\u00e1ceres, Antioquia" },
            { label: "Caicedo", value: "Caicedo, Antioquia" },
            { label: "Caldas", value: "Caldas, Antioquia" },
            { label: "Campamento", value: "Campamento, Antioquia" },
            { label: "Ca\u00f1asgordas", value: "Ca\u00f1asgordas, Antioquia" },
            { label: "Caracol\u00ed", value: "Caracol\u00ed, Antioquia" },
            { label: "Caramanta", value: "Caramanta, Antioquia" },
            { label: "Carepa", value: "Carepa, Antioquia" },
            { label: "Carolina del Pr\u00edncipe", value: "Carolina del Pr\u00edncipe, Antioquia" },
            { label: "Caucasia", value: "Caucasia, Antioquia" },
            { label: "Chigorod\u00f3", value: "Chigorod\u00f3, Antioquia" },
            { label: "Cisneros", value: "Cisneros, Antioquia" },
            { label: "Ciudad Bol\u00edvar", value: "Ciudad Bol\u00edvar, Antioquia" },
            { label: "Cocorn\u00e1", value: "Cocorn\u00e1, Antioquia" },
            { label: "Concepci\u00f3n", value: "Concepci\u00f3n, Antioquia" },
            { label: "Concordia", value: "Concordia, Antioquia" },
            { label: "Copacabana", value: "Copacabana, Antioquia" },
            { label: "Dabeiba", value: "Dabeiba, Antioquia" },
            { label: "Donmat\u00edas", value: "Donmat\u00edas, Antioquia" },
            { label: "Eb\u00e9jico", value: "Eb\u00e9jico, Antioquia" },
            { label: "El Bagre", value: "El Bagre, Antioquia" },
            { label: "El Carmen de Viboral", value: "El Carmen de Viboral, Antioquia" },
            { label: "El Pe\u00f1ol", value: "El Pe\u00f1ol, Antioquia" },
            { label: "El Retiro", value: "El Retiro, Antioquia" },
            { label: "El Santuario", value: "El Santuario, Antioquia" },
            { label: "Entrerr\u00edos", value: "Entrerr\u00edos, Antioquia" },
            { label: "Envigado", value: "Envigado, Antioquia" },
            { label: "Fredonia", value: "Fredonia, Antioquia" },
            { label: "Frontino", value: "Frontino, Antioquia" },
            { label: "Giraldo", value: "Giraldo, Antioquia" },
            { label: "Girardota", value: "Girardota, Antioquia" },
            { label: "G\u00f3mez Plata", value: "G\u00f3mez Plata, Antioquia" },
            { label: "Granada", value: "Granada, Antioquia" },
            { label: "Guadalupe", value: "Guadalupe, Antioquia" },
            { label: "Guarne", value: "Guarne, Antioquia" },
            { label: "Guatap\u00e9", value: "Guatap\u00e9, Antioquia" },
            { label: "Heliconia", value: "Heliconia, Antioquia" },
            { label: "Hispania", value: "Hispania, Antioquia" },
            { label: "Itag\u00fc\u00ed", value: "Itag\u00fc\u00ed, Antioquia" },
            { label: "Ituango", value: "Ituango, Antioquia" },
            { label: "Jard\u00edn", value: "Jard\u00edn, Antioquia" },
            { label: "Jeric\u00f3", value: "Jeric\u00f3, Antioquia" },
            { label: "La Ceja", value: "La Ceja, Antioquia" },
            { label: "La Estrella", value: "La Estrella, Antioquia" },
            { label: "La Pintada", value: "La Pintada, Antioquia" },
            { label: "La Uni\u00f3n", value: "La Uni\u00f3n, Antioquia" },
            { label: "Liborina", value: "Liborina, Antioquia" },
            { label: "Maceo", value: "Maceo, Antioquia" },
            { label: "Marinilla", value: "Marinilla, Antioquia" },
            { label: "Medell\u00edn", value: "Medell\u00edn, Antioquia" },
            { label: "Montebello", value: "Montebello, Antioquia" },
            { label: "Murind\u00f3", value: "Murind\u00f3, Antioquia" },
            { label: "Mutat\u00e1", value: "Mutat\u00e1, Antioquia" },
            { label: "Nari\u00f1o", value: "Nari\u00f1o, Antioquia" },
            { label: "Nech\u00ed", value: "Nech\u00ed, Antioquia" },
            { label: "Necocl\u00ed", value: "Necocl\u00ed, Antioquia" },
            { label: "Olaya", value: "Olaya, Antioquia" },
            { label: "Peque", value: "Peque, Antioquia" },
            { label: "Pueblorrico", value: "Pueblorrico, Antioquia" },
            { label: "Puerto Berr\u00edo", value: "Puerto Berr\u00edo, Antioquia" },
            { label: "Puerto Nare", value: "Puerto Nare, Antioquia" },
            { label: "Puerto Triunfo", value: "Puerto Triunfo, Antioquia" },
            { label: "Remedios", value: "Remedios, Antioquia" },
            { label: "Rionegro", value: "Rionegro, Antioquia" },
            { label: "Sabanalarga", value: "Sabanalarga, Antioquia" },
            { label: "Sabaneta", value: "Sabaneta, Antioquia" },
            { label: "Salgar", value: "Salgar, Antioquia" },
            { label: "San Andr\u00e9s de Cuerquia", value: "San Andr\u00e9s de Cuerquia, Antioquia" },
            { label: "San Carlos", value: "San Carlos, Antioquia" },
            { label: "San Francisco", value: "San Francisco, Antioquia" },
            { label: "San Jer\u00f3nimo", value: "San Jer\u00f3nimo, Antioquia" },
            { label: "San Jos\u00e9 de la Monta\u00f1a", value: "San Jos\u00e9 de la Monta\u00f1a, Antioquia" },
            { label: "San Juan de Urab\u00e1", value: "San Juan de Urab\u00e1, Antioquia" },
            { label: "San Luis", value: "San Luis, Antioquia" },
            { label: "San Pedro de Urab\u00e1", value: "San Pedro de Urab\u00e1, Antioquia" },
            { label: "San Pedro de los Milagros", value: "San Pedro de los Milagros, Antioquia" },
            { label: "San Rafael", value: "San Rafael, Antioquia" },
            { label: "San Roque", value: "San Roque, Antioquia" },
            { label: "San Vicente", value: "San Vicente, Antioquia" },
            { label: "Santa B\u00e1rbara", value: "Santa B\u00e1rbara, Antioquia" },
            { label: "Santa Fe de Antioquia", value: "Santa Fe de Antioquia, Antioquia" },
            { label: "Santa Rosa de Osos", value: "Santa Rosa de Osos, Antioquia" },
            { label: "Santo Domingo", value: "Santo Domingo, Antioquia" },
            { label: "Segovia", value: "Segovia, Antioquia" },
            { label: "Sons\u00f3n", value: "Sons\u00f3n, Antioquia" },
            { label: "Sopetr\u00e1n", value: "Sopetr\u00e1n, Antioquia" },
            { label: "T\u00e1mesis", value: "T\u00e1mesis, Antioquia" },
            { label: "Taraz\u00e1", value: "Taraz\u00e1, Antioquia" },
            { label: "Tarso", value: "Tarso, Antioquia" },
            { label: "Titirib\u00ed", value: "Titirib\u00ed, Antioquia" },
            { label: "Toledo", value: "Toledo, Antioquia" },
            { label: "Turbo", value: "Turbo, Antioquia" },
            { label: "Uramita", value: "Uramita, Antioquia" },
            { label: "Urrao", value: "Urrao, Antioquia" },
            { label: "Valdivia", value: "Valdivia, Antioquia" },
            { label: "Valpara\u00edso", value: "Valpara\u00edso, Antioquia" },
            { label: "Vegach\u00ed", value: "Vegach\u00ed, Antioquia" },
            { label: "Venecia", value: "Venecia, Antioquia" },
            { label: "Vig\u00eda del Fuerte", value: "Vig\u00eda del Fuerte, Antioquia" },
            { label: "Yal\u00ed", value: "Yal\u00ed, Antioquia" },
            { label: "Yarumal", value: "Yarumal, Antioquia" },
            { label: "Yolomb\u00f3", value: "Yolomb\u00f3, Antioquia" },
            { label: "Yond\u00f3", value: "Yond\u00f3, Antioquia" },
            { label: "Zaragoza", value: "Zaragoza, Antioquia" },
        ]
    },
    {
        "id": 2,
        "label": "Arauca",
        "ciudades": [
            { label: "Arauca", value: "Arauca, Arauca" },
            { label: "Arauquita", value: "Arauquita, Arauca" },
            { label: "Cravo Norte", value: "Cravo Norte, Arauca" },
            { label: "Fortul", value: "Fortul, Arauca" },
            { label: "Puerto Rond\u00f3n", value: "Puerto Rond\u00f3n, Arauca" },
            { label: "Saravena", value: "Saravena, Arauca" },
            { label: "Tame", value: "Tame, Arauca" },
        ]
    },
    {
        "id": 3,
        "label": "Atl\u00e1ntico",
        "ciudades": [
            { label: "Baranoa", value: "Baranoa, Atl\u00e1ntico" },
            { label: "Barranquilla", value: "Barranquilla, Atl\u00e1ntico" },
            { label: "Campo de la Cruz", value: "Campo de la Cruz, Atl\u00e1ntico" },
            { label: "Candelaria", value: "Candelaria, Atl\u00e1ntico" },
            { label: "Galapa", value: "Galapa, Atl\u00e1ntico" },
            { label: "Juan de Acosta", value: "Juan de Acosta, Atl\u00e1ntico" },
            { label: "Luruaco", value: "Luruaco, Atl\u00e1ntico" },
            { label: "Malambo", value: "Malambo, Atl\u00e1ntico" },
            { label: "Manat\u00ed", value: "Manat\u00ed, Atl\u00e1ntico" },
            { label: "Palmar de Varela", value: "Palmar de Varela, Atl\u00e1ntico" },
            { label: "Pioj\u00f3", value: "Pioj\u00f3, Atl\u00e1ntico" },
            { label: "Polonuevo", value: "Polonuevo, Atl\u00e1ntico" },
            { label: "Ponedera", value: "Ponedera, Atl\u00e1ntico" },
            { label: "Puerto Colombia", value: "Puerto Colombia, Atl\u00e1ntico" },
            { label: "Repel\u00f3n", value: "Repel\u00f3n, Atl\u00e1ntico" },
            { label: "Sabanagrande", value: "Sabanagrande, Atl\u00e1ntico" },
            { label: "Sabanalarga", value: "Sabanalarga, Atl\u00e1ntico" },
            { label: "Santa Luc\u00eda", value: "Santa Luc\u00eda, Atl\u00e1ntico" },
            { label: "Santo Tom\u00e1s", value: "Santo Tom\u00e1s, Atl\u00e1ntico" },
            { label: "Soledad", value: "Soledad, Atl\u00e1ntico" },
            { label: "Su\u00e1n", value: "Su\u00e1n, Atl\u00e1ntico" },
            { label: "Tubar\u00e1", value: "Tubar\u00e1, Atl\u00e1ntico" },
            { label: "Usiacur\u00ed", value: "Usiacur\u00ed, Atl\u00e1ntico" },
        ]
    },
    {
        "id": 4,
        "label": "Bol\u00edvar",
        "ciudades": [
            { label: "Ach\u00ed", value: "Ach\u00ed, Bol\u00edvar" },
            { label: "Altos del Rosario", value: "Altos del Rosario, Bol\u00edvar" },
            { label: "Arenal", value: "Arenal, Bol\u00edvar" },
            { label: "Arjona", value: "Arjona, Bol\u00edvar" },
            { label: "Arroyohondo", value: "Arroyohondo, Bol\u00edvar" },
            { label: "Barranco de Loba", value: "Barranco de Loba, Bol\u00edvar" },
            { label: "Brazuelo de Papayal", value: "Brazuelo de Papayal, Bol\u00edvar" },
            { label: "Calamar", value: "Calamar, Bol\u00edvar" },
            { label: "Cantagallo", value: "Cantagallo, Bol\u00edvar" },
            { label: "Cartagena de Indias", value: "Cartagena de Indias, Bol\u00edvar" },
            { label: "Cicuco", value: "Cicuco, Bol\u00edvar" },
            { label: "Clemencia", value: "Clemencia, Bol\u00edvar" },
            { label: "C\u00f3rdoba", value: "C\u00f3rdoba, Bol\u00edvar" },
            { label: "El Carmen de Bol\u00edvar", value: "El Carmen de Bol\u00edvar, Bol\u00edvar" },
            { label: "El Guamo", value: "El Guamo, Bol\u00edvar" },
            { label: "El Pe\u00f1\u00f3n", value: "El Pe\u00f1\u00f3n, Bol\u00edvar" },
            { label: "Hatillo de Loba", value: "Hatillo de Loba, Bol\u00edvar" },
            { label: "Magangu\u00e9", value: "Magangu\u00e9, Bol\u00edvar" },
            { label: "Mahates", value: "Mahates, Bol\u00edvar" },
            { label: "Margarita", value: "Margarita, Bol\u00edvar" },
            { label: "Mar\u00eda la Baja", value: "Mar\u00eda la Baja, Bol\u00edvar" },
            { label: "Momp\u00f3s", value: "Momp\u00f3s, Bol\u00edvar" },
            { label: "Montecristo", value: "Montecristo, Bol\u00edvar" },
            { label: "Morales", value: "Morales, Bol\u00edvar" },
            { label: "Noros\u00ed", value: "Noros\u00ed, Bol\u00edvar" },
            { label: "Pinillos", value: "Pinillos, Bol\u00edvar" },
            { label: "Regidor", value: "Regidor, Bol\u00edvar" },
            { label: "R\u00edo Viejo", value: "R\u00edo Viejo, Bol\u00edvar" },
            { label: "San Crist\u00f3bal", value: "San Crist\u00f3bal, Bol\u00edvar" },
            { label: "San Estanislao", value: "San Estanislao, Bol\u00edvar" },
            { label: "San Fernando", value: "San Fernando, Bol\u00edvar" },
            { label: "San Jacinto del Cauca", value: "San Jacinto del Cauca, Bol\u00edvar" },
            { label: "San Jacinto", value: "San Jacinto, Bol\u00edvar" },
            { label: "San Juan Nepomuceno", value: "San Juan Nepomuceno, Bol\u00edvar" },
            { label: "San Mart\u00edn de Loba", value: "San Mart\u00edn de Loba, Bol\u00edvar" },
            { label: "San Pablo", value: "San Pablo, Bol\u00edvar" },
            { label: "Santa Catalina", value: "Santa Catalina, Bol\u00edvar" },
            { label: "Santa Rosa", value: "Santa Rosa, Bol\u00edvar" },
            { label: "Santa Rosa del Sur", value: "Santa Rosa del Sur, Bol\u00edvar" },
            { label: "Simit\u00ed", value: "Simit\u00ed, Bol\u00edvar" },
            { label: "Soplaviento", value: "Soplaviento, Bol\u00edvar" },
            { label: "Talaigua Nuevo", value: "Talaigua Nuevo, Bol\u00edvar" },
            { label: "Tiquisio", value: "Tiquisio, Bol\u00edvar" },
            { label: "Turbaco", value: "Turbaco, Bol\u00edvar" },
            { label: "Turban\u00e1", value: "Turban\u00e1, Bol\u00edvar" },
            { label: "Villanueva", value: "Villanueva, Bol\u00edvar" },
            { label: "Zambrano", value: "Zambrano, Bol\u00edvar" },
        ]
    },
    {
        "id": 5,
        "label": "Boyac\u00e1",
        "ciudades": [
            { label: "Almeida", value: "Almeida, Boyac\u00e1" },
            { label: "Aquitania", value: "Aquitania, Boyac\u00e1" },
            { label: "Arcabuco", value: "Arcabuco, Boyac\u00e1" },
            { label: "Bel\u00e9n", value: "Bel\u00e9n, Boyac\u00e1" },
            { label: "Berbeo", value: "Berbeo, Boyac\u00e1" },
            { label: "Bet\u00e9itiva", value: "Bet\u00e9itiva, Boyac\u00e1" },
            { label: "Boavita", value: "Boavita, Boyac\u00e1" },
            { label: "Boyac\u00e1", value: "Boyac\u00e1, Boyac\u00e1" },
            { label: "Brice\u00f1o", value: "Brice\u00f1o, Boyac\u00e1" },
            { label: "Buenavista", value: "Buenavista, Boyac\u00e1" },
            { label: "Busbanz\u00e1", value: "Busbanz\u00e1, Boyac\u00e1" },
            { label: "Caldas", value: "Caldas, Boyac\u00e1" },
            { label: "Campohermoso", value: "Campohermoso, Boyac\u00e1" },
            { label: "Cerinza", value: "Cerinza, Boyac\u00e1" },
            { label: "Chinavita", value: "Chinavita, Boyac\u00e1" },
            { label: "Chiquinquir\u00e1", value: "Chiquinquir\u00e1, Boyac\u00e1" },
            { label: "Ch\u00edquiza", value: "Ch\u00edquiza, Boyac\u00e1" },
            { label: "Chiscas", value: "Chiscas, Boyac\u00e1" },
            { label: "Chita", value: "Chita, Boyac\u00e1" },
            { label: "Chitaraque", value: "Chitaraque, Boyac\u00e1" },
            { label: "Chivat\u00e1", value: "Chivat\u00e1, Boyac\u00e1" },
            { label: "Chivor", value: "Chivor, Boyac\u00e1" },
            { label: "Ci\u00e9nega", value: "Ci\u00e9nega, Boyac\u00e1" },
            { label: "C\u00f3mbita", value: "C\u00f3mbita, Boyac\u00e1" },
            { label: "Coper", value: "Coper, Boyac\u00e1" },
            { label: "Corrales", value: "Corrales, Boyac\u00e1" },
            { label: "Covarach\u00eda", value: "Covarach\u00eda, Boyac\u00e1" },
            { label: "Cubar\u00e1", value: "Cubar\u00e1, Boyac\u00e1" },
            { label: "Cucaita", value: "Cucaita, Boyac\u00e1" },
            { label: "Cu\u00edtiva", value: "Cu\u00edtiva, Boyac\u00e1" },
            { label: "Duitama", value: "Duitama, Boyac\u00e1" },
            { label: "El Cocuy", value: "El Cocuy, Boyac\u00e1" },
            { label: "El Espino", value: "El Espino, Boyac\u00e1" },
            { label: "Firavitoba", value: "Firavitoba, Boyac\u00e1" },
            { label: "Floresta", value: "Floresta, Boyac\u00e1" },
            { label: "Gachantiv\u00e1", value: "Gachantiv\u00e1, Boyac\u00e1" },
            { label: "G\u00e1meza", value: "G\u00e1meza, Boyac\u00e1" },
            { label: "Garagoa", value: "Garagoa, Boyac\u00e1" },
            { label: "Guacamayas", value: "Guacamayas, Boyac\u00e1" },
            { label: "Guateque", value: "Guateque, Boyac\u00e1" },
            { label: "Guayat\u00e1", value: "Guayat\u00e1, Boyac\u00e1" },
            { label: "G\u00fcic\u00e1n", value: "G\u00fcic\u00e1n, Boyac\u00e1" },
            { label: "Iza", value: "Iza, Boyac\u00e1" },
            { label: "Jenesano", value: "Jenesano, Boyac\u00e1" },
            { label: "Jeric\u00f3", value: "Jeric\u00f3, Boyac\u00e1" },
            { label: "La Capilla", value: "La Capilla, Boyac\u00e1" },
            { label: "La Uvita", value: "La Uvita, Boyac\u00e1" },
            { label: "La Victoria", value: "La Victoria, Boyac\u00e1" },
            { label: "Labranzagrande", value: "Labranzagrande, Boyac\u00e1" },
            { label: "Macanal", value: "Macanal, Boyac\u00e1" },
            { label: "Marip\u00ed", value: "Marip\u00ed, Boyac\u00e1" },
            { label: "Miraflores", value: "Miraflores, Boyac\u00e1" },
            { label: "Mongua", value: "Mongua, Boyac\u00e1" },
            { label: "Mongu\u00ed", value: "Mongu\u00ed, Boyac\u00e1" },
            { label: "Moniquir\u00e1", value: "Moniquir\u00e1, Boyac\u00e1" },
            { label: "Motavita", value: "Motavita, Boyac\u00e1" },
            { label: "Muzo", value: "Muzo, Boyac\u00e1" },
            { label: "Nobsa", value: "Nobsa, Boyac\u00e1" },
            { label: "Nuevo Col\u00f3n", value: "Nuevo Col\u00f3n, Boyac\u00e1" },
            { label: "Oicat\u00e1", value: "Oicat\u00e1, Boyac\u00e1" },
            { label: "Otanche", value: "Otanche, Boyac\u00e1" },
            { label: "Pachavita", value: "Pachavita, Boyac\u00e1" },
            { label: "P\u00e1ez", value: "P\u00e1ez, Boyac\u00e1" },
            { label: "Paipa", value: "Paipa, Boyac\u00e1" },
            { label: "Pajarito", value: "Pajarito, Boyac\u00e1" },
            { label: "Panqueba", value: "Panqueba, Boyac\u00e1" },
            { label: "Pauna", value: "Pauna, Boyac\u00e1" },
            { label: "Paya", value: "Paya, Boyac\u00e1" },
            { label: "Paz del R\u00edo", value: "Paz del R\u00edo, Boyac\u00e1" },
            { label: "Pesca", value: "Pesca, Boyac\u00e1" },
            { label: "Pisba", value: "Pisba, Boyac\u00e1" },
            { label: "Puerto Boyac\u00e1", value: "Puerto Boyac\u00e1, Boyac\u00e1" },
            { label: "Qu\u00edpama", value: "Qu\u00edpama, Boyac\u00e1" },
            { label: "Ramiriqu\u00ed", value: "Ramiriqu\u00ed, Boyac\u00e1" },
            { label: "R\u00e1quira", value: "R\u00e1quira, Boyac\u00e1" },
            { label: "Rond\u00f3n", value: "Rond\u00f3n, Boyac\u00e1" },
            { label: "Saboy\u00e1", value: "Saboy\u00e1, Boyac\u00e1" },
            { label: "S\u00e1chica", value: "S\u00e1chica, Boyac\u00e1" },
            { label: "Samac\u00e1", value: "Samac\u00e1, Boyac\u00e1" },
            { label: "San Eduardo", value: "San Eduardo, Boyac\u00e1" },
            { label: "San Jos\u00e9 de Pare", value: "San Jos\u00e9 de Pare, Boyac\u00e1" },
            { label: "San Luis de Gaceno", value: "San Luis de Gaceno, Boyac\u00e1" },
            { label: "San Mateo", value: "San Mateo, Boyac\u00e1" },
            { label: "San Miguel de Sema", value: "San Miguel de Sema, Boyac\u00e1" },
            { label: "San Pablo de Borbur", value: "San Pablo de Borbur, Boyac\u00e1" },
            { label: "Santa Mar\u00eda", value: "Santa Mar\u00eda, Boyac\u00e1" },
            { label: "Santa Rosa de Viterbo", value: "Santa Rosa de Viterbo, Boyac\u00e1" },
            { label: "Santa Sof\u00eda", value: "Santa Sof\u00eda, Boyac\u00e1" },
            { label: "Santana", value: "Santana, Boyac\u00e1" },
            { label: "Sativanorte", value: "Sativanorte, Boyac\u00e1" },
            { label: "Sativasur", value: "Sativasur, Boyac\u00e1" },
            { label: "Siachoque", value: "Siachoque, Boyac\u00e1" },
            { label: "Soat\u00e1", value: "Soat\u00e1, Boyac\u00e1" },
            { label: "Socha", value: "Socha, Boyac\u00e1" },
            { label: "Socot\u00e1", value: "Socot\u00e1, Boyac\u00e1" },
            { label: "Sogamoso", value: "Sogamoso, Boyac\u00e1" },
            { label: "Somondoco", value: "Somondoco, Boyac\u00e1" },
            { label: "Sora", value: "Sora, Boyac\u00e1" },
            { label: "Sorac\u00e1", value: "Sorac\u00e1, Boyac\u00e1" },
            { label: "Sotaquir\u00e1", value: "Sotaquir\u00e1, Boyac\u00e1" },
            { label: "Susac\u00f3n", value: "Susac\u00f3n, Boyac\u00e1" },
            { label: "Sutamarch\u00e1n", value: "Sutamarch\u00e1n, Boyac\u00e1" },
            { label: "Sutatenza", value: "Sutatenza, Boyac\u00e1" },
            { label: "Tasco", value: "Tasco, Boyac\u00e1" },
            { label: "Tenza", value: "Tenza, Boyac\u00e1" },
            { label: "Tiban\u00e1", value: "Tiban\u00e1, Boyac\u00e1" },
            { label: "Tibasosa", value: "Tibasosa, Boyac\u00e1" },
            { label: "Tinjac\u00e1", value: "Tinjac\u00e1, Boyac\u00e1" },
            { label: "Tipacoque", value: "Tipacoque, Boyac\u00e1" },
            { label: "Toca", value: "Toca, Boyac\u00e1" },
            { label: "Tog\u00fc\u00ed", value: "Tog\u00fc\u00ed, Boyac\u00e1" },
            { label: "T\u00f3paga", value: "T\u00f3paga, Boyac\u00e1" },
            { label: "Tota", value: "Tota, Boyac\u00e1" },
            { label: "Tunja", value: "Tunja, Boyac\u00e1" },
            { label: "Tunungu\u00e1", value: "Tunungu\u00e1, Boyac\u00e1" },
            { label: "Turmequ\u00e9", value: "Turmequ\u00e9, Boyac\u00e1" },
            { label: "Tuta", value: "Tuta, Boyac\u00e1" },
            { label: "Tutaz\u00e1", value: "Tutaz\u00e1, Boyac\u00e1" },
            { label: "\u00dambita", value: "\u00dambita, Boyac\u00e1" },
            { label: "Ventaquemada", value: "Ventaquemada, Boyac\u00e1" },
            { label: "Villa de Leyva", value: "Villa de Leyva, Boyac\u00e1" },
            { label: "Viracach\u00e1", value: "Viracach\u00e1, Boyac\u00e1" },
            { label: "Zetaquira", value: "Zetaquira, Boyac\u00e1" },
        ]
    },
    {
        "id": 6,
        "label": "Caldas",
        "ciudades": [
            { label: "Aguadas", value: "Aguadas, Caldas" },
            { label: "Anserma", value: "Anserma, Caldas" },
            { label: "Aranzazu", value: "Aranzazu, Caldas" },
            { label: "Belalc\u00e1zar", value: "Belalc\u00e1zar, Caldas" },
            { label: "Chinchin\u00e1", value: "Chinchin\u00e1, Caldas" },
            { label: "Filadelfia", value: "Filadelfia, Caldas" },
            { label: "La Dorada", value: "La Dorada, Caldas" },
            { label: "La Merced", value: "La Merced, Caldas" },
            { label: "Manizales", value: "Manizales, Caldas" },
            { label: "Manzanares", value: "Manzanares, Caldas" },
            { label: "Marmato", value: "Marmato, Caldas" },
            { label: "Marquetalia", value: "Marquetalia, Caldas" },
            { label: "Marulanda", value: "Marulanda, Caldas" },
            { label: "Neira", value: "Neira, Caldas" },
            { label: "Norcasia", value: "Norcasia, Caldas" },
            { label: "P\u00e1cora", value: "P\u00e1cora, Caldas" },
            { label: "Palestina", value: "Palestina, Caldas" },
            { label: "Pensilvania", value: "Pensilvania, Caldas" },
            { label: "Riosucio", value: "Riosucio, Caldas" },
            { label: "Risaralda", value: "Risaralda, Caldas" },
            { label: "Salamina", value: "Salamina, Caldas" },
            { label: "Saman\u00e1", value: "Saman\u00e1, Caldas" },
            { label: "San Jos\u00e9", value: "San Jos\u00e9, Caldas" },
            { label: "Sup\u00eda", value: "Sup\u00eda, Caldas" },
            { label: "Victoria", value: "Victoria, Caldas" },
            { label: "Villamar\u00eda", value: "Villamar\u00eda, Caldas" },
            { label: "Viterbo", value: "Viterbo, Caldas" },
        ]
    },
    {
        "id": 7,
        "label": "Caquet\u00e1",
        "ciudades": [
            { label: "Albania", value: "Albania, Caquet\u00e1" },
            { label: "Bel\u00e9n de los Andaqu\u00edes", value: "Bel\u00e9n de los Andaqu\u00edes, Caquet\u00e1" },
            { label: "Cartagena del Chair\u00e1", value: "Cartagena del Chair\u00e1, Caquet\u00e1" },
            { label: "Curillo", value: "Curillo, Caquet\u00e1" },
            { label: "El Doncello", value: "El Doncello, Caquet\u00e1" },
            { label: "El Paujil", value: "El Paujil, Caquet\u00e1" },
            { label: "Florencia", value: "Florencia, Caquet\u00e1" },
            { label: "La Monta\u00f1ita", value: "La Monta\u00f1ita, Caquet\u00e1" },
            { label: "Mil\u00e1n", value: "Mil\u00e1n, Caquet\u00e1" },
            { label: "Morelia", value: "Morelia, Caquet\u00e1" },
            { label: "Puerto Rico", value: "Puerto Rico, Caquet\u00e1" },
            { label: "San Jos\u00e9 del Fragua", value: "San Jos\u00e9 del Fragua, Caquet\u00e1" },
            { label: "San Vicente del Cagu\u00e1n", value: "San Vicente del Cagu\u00e1n, Caquet\u00e1" },
            { label: "Solano", value: "Solano, Caquet\u00e1" },
            { label: "Solita", value: "Solita, Caquet\u00e1" },
            { label: "Valpara\u00edso", value: "Valpara\u00edso, Caquet\u00e1" },
        ]
    },
    {
        "id": 8,
        "label": "Casanare",
        "ciudades": [
            { label: "Aguazul", value: "Aguazul, Casanare" },
            { label: "Ch\u00e1meza", value: "Ch\u00e1meza, Casanare" },
            { label: "Hato Corozal", value: "Hato Corozal, Casanare" },
            { label: "La Salina", value: "La Salina, Casanare" },
            { label: "Man\u00ed", value: "Man\u00ed, Casanare" },
            { label: "Monterrey", value: "Monterrey, Casanare" },
            { label: "Nunch\u00eda", value: "Nunch\u00eda, Casanare" },
            { label: "Orocu\u00e9", value: "Orocu\u00e9, Casanare" },
            { label: "Paz de Ariporo", value: "Paz de Ariporo, Casanare" },
            { label: "Pore", value: "Pore, Casanare" },
            { label: "Recetor", value: "Recetor, Casanare" },
            { label: "Sabanalarga", value: "Sabanalarga, Casanare" },
            { label: "S\u00e1cama", value: "S\u00e1cama, Casanare" },
            { label: "San Luis de Palenque", value: "San Luis de Palenque, Casanare" },
            { label: "T\u00e1mara", value: "T\u00e1mara, Casanare" },
            { label: "Tauramena", value: "Tauramena, Casanare" },
            { label: "Trinidad", value: "Trinidad, Casanare" },
            { label: "Villanueva", value: "Villanueva, Casanare" },
            { label: "Yopal", value: "Yopal, Casanare" },
        ]
    },
    {
        "id": 9,
        "label": "Cauca",
        "ciudades": [
            { label: "Almaguer", value: "Almaguer, Cauca" },
            { label: "Argelia", value: "Argelia, Cauca" },
            { label: "Balboa", value: "Balboa, Cauca" },
            { label: "Bol\u00edvar", value: "Bol\u00edvar, Cauca" },
            { label: "Buenos Aires", value: "Buenos Aires, Cauca" },
            { label: "Cajib\u00edo", value: "Cajib\u00edo, Cauca" },
            { label: "Caldono", value: "Caldono, Cauca" },
            { label: "Caloto", value: "Caloto, Cauca" },
            { label: "Corinto", value: "Corinto, Cauca" },
            { label: "El Tambo", value: "El Tambo, Cauca" },
            { label: "Florencia", value: "Florencia, Cauca" },
            { label: "Guachen\u00e9", value: "Guachen\u00e9, Cauca" },
            { label: "Guap\u00ed", value: "Guap\u00ed, Cauca" },
            { label: "Inz\u00e1", value: "Inz\u00e1, Cauca" },
            { label: "Jambal\u00f3", value: "Jambal\u00f3, Cauca" },
            { label: "La Sierra", value: "La Sierra, Cauca" },
            { label: "La Vega", value: "La Vega, Cauca" },
            { label: "L\u00f3pez de Micay", value: "L\u00f3pez de Micay, Cauca" },
            { label: "Mercaderes", value: "Mercaderes, Cauca" },
            { label: "Miranda", value: "Miranda, Cauca" },
            { label: "Morales", value: "Morales, Cauca" },
            { label: "Padilla", value: "Padilla, Cauca" },
            { label: "P\u00e1ez", value: "P\u00e1ez, Cauca" },
            { label: "Pat\u00eda", value: "Pat\u00eda, Cauca" },
            { label: "Piamonte", value: "Piamonte, Cauca" },
            { label: "Piendam\u00f3", value: "Piendam\u00f3, Cauca" },
            { label: "Popay\u00e1n", value: "Popay\u00e1n, Cauca" },
            { label: "Puerto Tejada", value: "Puerto Tejada, Cauca" },
            { label: "Purac\u00e9", value: "Purac\u00e9, Cauca" },
            { label: "Rosas", value: "Rosas, Cauca" },
            { label: "San Sebasti\u00e1n", value: "San Sebasti\u00e1n, Cauca" },
            { label: "Santa Rosa", value: "Santa Rosa, Cauca" },
            { label: "Santander de Quilichao", value: "Santander de Quilichao, Cauca" },
            { label: "Silvia", value: "Silvia, Cauca" },
            { label: "Sotar\u00e1", value: "Sotar\u00e1, Cauca" },
            { label: "Su\u00e1rez", value: "Su\u00e1rez, Cauca" },
            { label: "Sucre", value: "Sucre, Cauca" },
            { label: "Timb\u00edo", value: "Timb\u00edo, Cauca" },
            { label: "Timbiqu\u00ed", value: "Timbiqu\u00ed, Cauca" },
            { label: "Torib\u00edo", value: "Torib\u00edo, Cauca" },
            { label: "Totor\u00f3", value: "Totor\u00f3, Cauca" },
            { label: "Villa Rica", value: "Villa Rica, Cauca" },
        ]
    },
    {
        "id": 10,
        "label": "Cesar",
        "ciudades": [
            { label: "Aguachica", value: "Aguachica, Cesar" },
            { label: "Agust\u00edn Codazzi", value: "Agust\u00edn Codazzi, Cesar" },
            { label: "Astrea", value: "Astrea, Cesar" },
            { label: "Becerril", value: "Becerril, Cesar" },
            { label: "Bosconia", value: "Bosconia, Cesar" },
            { label: "Chimichagua", value: "Chimichagua, Cesar" },
            { label: "Chiriguan\u00e1", value: "Chiriguan\u00e1, Cesar" },
            { label: "Curuman\u00ed", value: "Curuman\u00ed, Cesar" },
            { label: "El Copey", value: "El Copey, Cesar" },
            { label: "El Paso", value: "El Paso, Cesar" },
            { label: "Gamarra", value: "Gamarra, Cesar" },
            { label: "Gonz\u00e1lez", value: "Gonz\u00e1lez, Cesar" },
            { label: "La Gloria (Cesar)", value: "La Gloria (Cesar), Cesar" },
            { label: "La Jagua de Ibirico", value: "La Jagua de Ibirico, Cesar" },
            { label: "La Paz", value: "La Paz, Cesar" },
            { label: "Manaure Balc\u00f3n del Cesar", value: "Manaure Balc\u00f3n del Cesar, Cesar" },
            { label: "Pailitas", value: "Pailitas, Cesar" },
            { label: "Pelaya", value: "Pelaya, Cesar" },
            { label: "Pueblo Bello", value: "Pueblo Bello, Cesar" },
            { label: "R\u00edo de Oro", value: "R\u00edo de Oro, Cesar" },
            { label: "San Alberto", value: "San Alberto, Cesar" },
            { label: "San Diego", value: "San Diego, Cesar" },
            { label: "San Mart\u00edn", value: "San Mart\u00edn, Cesar" },
            { label: "Tamalameque", value: "Tamalameque, Cesar" },
            { label: "Valledupar", value: "Valledupar, Cesar" },
        ]
    },
    {
        "id": 11,
        "label": "Choc\u00f3",
        "ciudades": [
            { label: "Acand\u00ed", value: "Acand\u00ed, Choc\u00f3" },
            { label: "Alto Baud\u00f3", value: "Alto Baud\u00f3, Choc\u00f3" },
            { label: "Bagad\u00f3", value: "Bagad\u00f3, Choc\u00f3" },
            { label: "Bah\u00eda Solano", value: "Bah\u00eda Solano, Choc\u00f3" },
            { label: "Bajo Baud\u00f3", value: "Bajo Baud\u00f3, Choc\u00f3" },
            { label: "Bojay\u00e1", value: "Bojay\u00e1, Choc\u00f3" },
            { label: "Cant\u00f3n de San Pablo", value: "Cant\u00f3n de San Pablo, Choc\u00f3" },
            { label: "C\u00e9rtegui", value: "C\u00e9rtegui, Choc\u00f3" },
            { label: "Condoto", value: "Condoto, Choc\u00f3" },
            { label: "El Atrato", value: "El Atrato, Choc\u00f3" },
            { label: "El Carmen de Atrato", value: "El Carmen de Atrato, Choc\u00f3" },
            { label: "El Carmen del Dari\u00e9n", value: "El Carmen del Dari\u00e9n, Choc\u00f3" },
            { label: "Istmina", value: "Istmina, Choc\u00f3" },
            { label: "Jurad\u00f3", value: "Jurad\u00f3, Choc\u00f3" },
            { label: "Litoral de San Juan", value: "Litoral de San Juan, Choc\u00f3" },
            { label: "Llor\u00f3", value: "Llor\u00f3, Choc\u00f3" },
            { label: "Medio Atrato", value: "Medio Atrato, Choc\u00f3" },
            { label: "Medio Baud\u00f3", value: "Medio Baud\u00f3, Choc\u00f3" },
            { label: "Medio San Juan", value: "Medio San Juan, Choc\u00f3" },
            { label: "N\u00f3vita", value: "N\u00f3vita, Choc\u00f3" },
            { label: "Nuqu\u00ed", value: "Nuqu\u00ed, Choc\u00f3" },
            { label: "Quibd\u00f3", value: "Quibd\u00f3, Choc\u00f3" },
            { label: "R\u00edo Ir\u00f3", value: "R\u00edo Ir\u00f3, Choc\u00f3" },
            { label: "R\u00edo Quito", value: "R\u00edo Quito, Choc\u00f3" },
            { label: "Riosucio", value: "Riosucio, Choc\u00f3" },
            { label: "San Jos\u00e9 del Palmar", value: "San Jos\u00e9 del Palmar, Choc\u00f3" },
            { label: "Sip\u00ed", value: "Sip\u00ed, Choc\u00f3" },
            { label: "Tad\u00f3", value: "Tad\u00f3, Choc\u00f3" },
            { label: "Uni\u00f3n Panamericana", value: "Uni\u00f3n Panamericana, Choc\u00f3" },
            { label: "Ungu\u00eda", value: "Ungu\u00eda, Choc\u00f3" },
        ]
    },
    {
        "id": 12,
        "label": "Cundinamarca",
        "ciudades": [
            { label: "Agua de Dios", value: "Agua de Dios, Cundinamarca" },
            { label: "Alb\u00e1n", value: "Alb\u00e1n, Cundinamarca" },
            { label: "Anapoima", value: "Anapoima, Cundinamarca" },
            { label: "Anolaima", value: "Anolaima, Cundinamarca" },
            { label: "Apulo", value: "Apulo, Cundinamarca" },
            { label: "Arbel\u00e1ez", value: "Arbel\u00e1ez, Cundinamarca" },
            { label: "Beltr\u00e1n", value: "Beltr\u00e1n, Cundinamarca" },
            { label: "Bituima", value: "Bituima, Cundinamarca" },
            { label: "Bogot\u00e1", value: "Bogot\u00e1, Cundinamarca" },
            { label: "Bojac\u00e1", value: "Bojac\u00e1, Cundinamarca" },
            { label: "Cabrera", value: "Cabrera, Cundinamarca" },
            { label: "Cachipay", value: "Cachipay, Cundinamarca" },
            { label: "Cajic\u00e1", value: "Cajic\u00e1, Cundinamarca" },
            { label: "Caparrap\u00ed", value: "Caparrap\u00ed, Cundinamarca" },
            { label: "C\u00e1queza", value: "C\u00e1queza, Cundinamarca" },
            { label: "Carmen de Carupa", value: "Carmen de Carupa, Cundinamarca" },
            { label: "Chaguan\u00ed", value: "Chaguan\u00ed, Cundinamarca" },
            { label: "Ch\u00eda", value: "Ch\u00eda, Cundinamarca" },
            { label: "Chipaque", value: "Chipaque, Cundinamarca" },
            { label: "Choach\u00ed", value: "Choach\u00ed, Cundinamarca" },
            { label: "Chocont\u00e1", value: "Chocont\u00e1, Cundinamarca" },
            { label: "Cogua", value: "Cogua, Cundinamarca" },
            { label: "Cota", value: "Cota, Cundinamarca" },
            { label: "Cucunub\u00e1", value: "Cucunub\u00e1, Cundinamarca" },
            { label: "El Colegio", value: "El Colegio, Cundinamarca" },
            { label: "El Pe\u00f1\u00f3n", value: "El Pe\u00f1\u00f3n, Cundinamarca" },
            { label: "El Rosal", value: "El Rosal, Cundinamarca" },
            { label: "Facatativ\u00e1", value: "Facatativ\u00e1, Cundinamarca" },
            { label: "F\u00f3meque", value: "F\u00f3meque, Cundinamarca" },
            { label: "Fosca", value: "Fosca, Cundinamarca" },
            { label: "Funza", value: "Funza, Cundinamarca" },
            { label: "F\u00faquene", value: "F\u00faquene, Cundinamarca" },
            { label: "Fusagasug\u00e1", value: "Fusagasug\u00e1, Cundinamarca" },
            { label: "Gachal\u00e1", value: "Gachal\u00e1, Cundinamarca" },
            { label: "Gachancip\u00e1", value: "Gachancip\u00e1, Cundinamarca" },
            { label: "Gachet\u00e1", value: "Gachet\u00e1, Cundinamarca" },
            { label: "Gama", value: "Gama, Cundinamarca" },
            { label: "Girardot", value: "Girardot, Cundinamarca" },
            { label: "Granada", value: "Granada, Cundinamarca" },
            { label: "Guachet\u00e1", value: "Guachet\u00e1, Cundinamarca" },
            { label: "Guaduas", value: "Guaduas, Cundinamarca" },
            { label: "Guasca", value: "Guasca, Cundinamarca" },
            { label: "Guataqu\u00ed", value: "Guataqu\u00ed, Cundinamarca" },
            { label: "Guatavita", value: "Guatavita, Cundinamarca" },
            { label: "Guayabal de S\u00edquima", value: "Guayabal de S\u00edquima, Cundinamarca" },
            { label: "Guayabetal", value: "Guayabetal, Cundinamarca" },
            { label: "Guti\u00e9rrez", value: "Guti\u00e9rrez, Cundinamarca" },
            { label: "Jerusal\u00e9n", value: "Jerusal\u00e9n, Cundinamarca" },
            { label: "Jun\u00edn", value: "Jun\u00edn, Cundinamarca" },
            { label: "La Calera", value: "La Calera, Cundinamarca" },
            { label: "La Mesa", value: "La Mesa, Cundinamarca" },
            { label: "La Palma", value: "La Palma, Cundinamarca" },
            { label: "La Pe\u00f1a", value: "La Pe\u00f1a, Cundinamarca" },
            { label: "La Vega", value: "La Vega, Cundinamarca" },
            { label: "Lenguazaque", value: "Lenguazaque, Cundinamarca" },
            { label: "Machet\u00e1", value: "Machet\u00e1, Cundinamarca" },
            { label: "Madrid", value: "Madrid, Cundinamarca" },
            { label: "Manta", value: "Manta, Cundinamarca" },
            { label: "Medina", value: "Medina, Cundinamarca" },
            { label: "Mosquera", value: "Mosquera, Cundinamarca" },
            { label: "Nari\u00f1o", value: "Nari\u00f1o, Cundinamarca" },
            { label: "Nemoc\u00f3n", value: "Nemoc\u00f3n, Cundinamarca" },
            { label: "Nilo", value: "Nilo, Cundinamarca" },
            { label: "Nimaima", value: "Nimaima, Cundinamarca" },
            { label: "Nocaima", value: "Nocaima, Cundinamarca" },
            { label: "Pacho", value: "Pacho, Cundinamarca" },
            { label: "Paime", value: "Paime, Cundinamarca" },
            { label: "Pandi", value: "Pandi, Cundinamarca" },
            { label: "Paratebueno", value: "Paratebueno, Cundinamarca" },
            { label: "Pasca", value: "Pasca, Cundinamarca" },
            { label: "Puerto Salgar", value: "Puerto Salgar, Cundinamarca" },
            { label: "Pul\u00ed", value: "Pul\u00ed, Cundinamarca" },
            { label: "Quebradanegra", value: "Quebradanegra, Cundinamarca" },
            { label: "Quetame", value: "Quetame, Cundinamarca" },
            { label: "Quipile", value: "Quipile, Cundinamarca" },
            { label: "Ricaurte", value: "Ricaurte, Cundinamarca" },
            { label: "San Antonio del Tequendama", value: "San Antonio del Tequendama, Cundinamarca" },
            { label: "San Bernardo", value: "San Bernardo, Cundinamarca" },
            { label: "San Cayetano", value: "San Cayetano, Cundinamarca" },
            { label: "San Francisco", value: "San Francisco, Cundinamarca" },
            { label: "San Juan de Rioseco", value: "San Juan de Rioseco, Cundinamarca" },
            { label: "Sasaima", value: "Sasaima, Cundinamarca" },
            { label: "Sesquil\u00e9", value: "Sesquil\u00e9, Cundinamarca" },
            { label: "Sibat\u00e9", value: "Sibat\u00e9, Cundinamarca" },
            { label: "Silvania", value: "Silvania, Cundinamarca" },
            { label: "Simijaca", value: "Simijaca, Cundinamarca" },
            { label: "Soacha", value: "Soacha, Cundinamarca" },
            { label: "Sop\u00f3", value: "Sop\u00f3, Cundinamarca" },
            { label: "Subachoque", value: "Subachoque, Cundinamarca" },
            { label: "Suesca", value: "Suesca, Cundinamarca" },
            { label: "Supat\u00e1", value: "Supat\u00e1, Cundinamarca" },
            { label: "Susa", value: "Susa, Cundinamarca" },
            { label: "Sutatausa", value: "Sutatausa, Cundinamarca" },
            { label: "Tabio", value: "Tabio, Cundinamarca" },
            { label: "Tausa", value: "Tausa, Cundinamarca" },
            { label: "Tena", value: "Tena, Cundinamarca" },
            { label: "Tenjo", value: "Tenjo, Cundinamarca" },
            { label: "Tibacuy", value: "Tibacuy, Cundinamarca" },
            { label: "Tibirita", value: "Tibirita, Cundinamarca" },
            { label: "Tocaima", value: "Tocaima, Cundinamarca" },
            { label: "Tocancip\u00e1", value: "Tocancip\u00e1, Cundinamarca" },
            { label: "Topaip\u00ed", value: "Topaip\u00ed, Cundinamarca" },
            { label: "Ubal\u00e1", value: "Ubal\u00e1, Cundinamarca" },
            { label: "Ubaque", value: "Ubaque, Cundinamarca" },
            { label: "Ubat\u00e9", value: "Ubat\u00e9, Cundinamarca" },
            { label: "Une", value: "Une, Cundinamarca" },
            { label: "\u00datica", value: "\u00datica, Cundinamarca" },
            { label: "Venecia", value: "Venecia, Cundinamarca" },
            { label: "Vergara", value: "Vergara, Cundinamarca" },
            { label: "Vian\u00ed", value: "Vian\u00ed, Cundinamarca" },
            { label: "Villag\u00f3mez", value: "Villag\u00f3mez, Cundinamarca" },
            { label: "Villapinz\u00f3n", value: "Villapinz\u00f3n, Cundinamarca" },
            { label: "Villeta", value: "Villeta, Cundinamarca" },
            { label: "Viot\u00e1", value: "Viot\u00e1, Cundinamarca" },
            { label: "Yacop\u00ed", value: "Yacop\u00ed, Cundinamarca" },
            { label: "Zipac\u00f3n", value: "Zipac\u00f3n, Cundinamarca" },
            { label: "Zipaquir\u00e1", value: "Zipaquir\u00e1, Cundinamarca" },
        ]
    },
    {
        "id": 13,
        "label": "C\u00f3rdoba",
        "ciudades": [
            { label: "Ayapel", value: "Ayapel, C\u00f3rdoba" },
            { label: "Buenavista", value: "Buenavista, C\u00f3rdoba" },
            { label: "Canalete", value: "Canalete, C\u00f3rdoba" },
            { label: "Ceret\u00e9", value: "Ceret\u00e9, C\u00f3rdoba" },
            { label: "Chim\u00e1", value: "Chim\u00e1, C\u00f3rdoba" },
            { label: "Chin\u00fa", value: "Chin\u00fa, C\u00f3rdoba" },
            { label: "Ci\u00e9naga de Oro", value: "Ci\u00e9naga de Oro, C\u00f3rdoba" },
            { label: "Cotorra", value: "Cotorra, C\u00f3rdoba" },
            { label: "La Apartada", value: "La Apartada, C\u00f3rdoba" },
            { label: "Lorica", value: "Lorica, C\u00f3rdoba" },
            { label: "Los C\u00f3rdobas", value: "Los C\u00f3rdobas, C\u00f3rdoba" },
            { label: "Momil", value: "Momil, C\u00f3rdoba" },
            { label: "Montel\u00edbano", value: "Montel\u00edbano, C\u00f3rdoba" },
            { label: "Monter\u00eda", value: "Monter\u00eda, C\u00f3rdoba" },
            { label: "Mo\u00f1itos", value: "Mo\u00f1itos, C\u00f3rdoba" },
            { label: "Planeta Rica", value: "Planeta Rica, C\u00f3rdoba" },
            { label: "Pueblo Nuevo", value: "Pueblo Nuevo, C\u00f3rdoba" },
            { label: "Puerto Escondido", value: "Puerto Escondido, C\u00f3rdoba" },
            { label: "Puerto Libertador", value: "Puerto Libertador, C\u00f3rdoba" },
            { label: "Pur\u00edsima", value: "Pur\u00edsima, C\u00f3rdoba" },
            { label: "Sahag\u00fan", value: "Sahag\u00fan, C\u00f3rdoba" },
            { label: "San Andr\u00e9s de Sotavento", value: "San Andr\u00e9s de Sotavento, C\u00f3rdoba" },
            { label: "San Antero", value: "San Antero, C\u00f3rdoba" },
            { label: "San Bernardo del Viento", value: "San Bernardo del Viento, C\u00f3rdoba" },
            { label: "San Carlos", value: "San Carlos, C\u00f3rdoba" },
            { label: "San Jos\u00e9 de Ur\u00e9", value: "San Jos\u00e9 de Ur\u00e9, C\u00f3rdoba" },
            { label: "San Pelayo", value: "San Pelayo, C\u00f3rdoba" },
            { label: "Tierralta", value: "Tierralta, C\u00f3rdoba" },
            { label: "Tuch\u00edn", value: "Tuch\u00edn, C\u00f3rdoba" },
            { label: "Valencia", value: "Valencia, C\u00f3rdoba" },
        ]
    },
    {
        "id": 14,
        "label": "Guain\u00eda",
        "ciudades": [
            { label: "In\u00edrida", value: "In\u00edrida, Guain\u00eda" },
        ]
    },
    {
        "id": 15,
        "label": "Guaviare",
        "ciudades": [
            { label: "Calamar", value: "Calamar, Guaviare" },
            { label: "El Retorno", value: "El Retorno, Guaviare" },
            { label: "Miraflores", value: "Miraflores, Guaviare" },
            { label: "San Jos\u00e9 del Guaviare", value: "San Jos\u00e9 del Guaviare, Guaviare" },
        ]
    },
    {
        "id": 16,
        "label": "Huila",
        "ciudades": [
            { label: "Acevedo", value: "Acevedo, Huila" },
            { label: "Agrado", value: "Agrado, Huila" },
            { label: "Aipe", value: "Aipe, Huila" },
            { label: "Algeciras", value: "Algeciras, Huila" },
            { label: "Altamira", value: "Altamira, Huila" },
            { label: "Baraya", value: "Baraya, Huila" },
            { label: "Campoalegre", value: "Campoalegre, Huila" },
            { label: "Colombia", value: "Colombia, Huila" },
            { label: "El Pital", value: "El Pital, Huila" },
            { label: "El\u00edas", value: "El\u00edas, Huila" },
            { label: "Garz\u00f3n", value: "Garz\u00f3n, Huila" },
            { label: "Gigante", value: "Gigante, Huila" },
            { label: "Guadalupe", value: "Guadalupe, Huila" },
            { label: "Hobo", value: "Hobo, Huila" },
            { label: "\u00cdquira", value: "\u00cdquira, Huila" },
            { label: "Isnos", value: "Isnos, Huila" },
            { label: "La Argentina", value: "La Argentina, Huila" },
            { label: "La Plata", value: "La Plata, Huila" },
            { label: "N\u00e1taga", value: "N\u00e1taga, Huila" },
            { label: "Neiva", value: "Neiva, Huila" },
            { label: "Oporapa", value: "Oporapa, Huila" },
            { label: "Paicol", value: "Paicol, Huila" },
            { label: "Palermo", value: "Palermo, Huila" },
            { label: "Palestina", value: "Palestina, Huila" },
            { label: "Pitalito", value: "Pitalito, Huila" },
            { label: "Rivera", value: "Rivera, Huila" },
            { label: "Saladoblanco", value: "Saladoblanco, Huila" },
            { label: "San Agust\u00edn", value: "San Agust\u00edn, Huila" },
            { label: "Santa Mar\u00eda", value: "Santa Mar\u00eda, Huila" },
            { label: "Suaza", value: "Suaza, Huila" },
            { label: "Tarqui", value: "Tarqui, Huila" },
            { label: "Tello", value: "Tello, Huila" },
            { label: "Teruel", value: "Teruel, Huila" },
            { label: "Tesalia", value: "Tesalia, Huila" },
            { label: "Timan\u00e1", value: "Timan\u00e1, Huila" },
            { label: "Villavieja", value: "Villavieja, Huila" },
            { label: "Yaguar\u00e1", value: "Yaguar\u00e1, Huila" },
        ]
    },
    {
        "id": 17,
        "label": "La Guajira",
        "ciudades": [
            { label: "Albania", value: "Albania, La Guajira" },
            { label: "Barrancas", value: "Barrancas, La Guajira" },
            { label: "Dibulla", value: "Dibulla, La Guajira" },
            { label: "Distracci\u00f3n", value: "Distracci\u00f3n, La Guajira" },
            { label: "El Molino", value: "El Molino, La Guajira" },
            { label: "Fonseca", value: "Fonseca, La Guajira" },
            { label: "Hatonuevo", value: "Hatonuevo, La Guajira" },
            { label: "La Jagua del Pilar", value: "La Jagua del Pilar, La Guajira" },
            { label: "Maicao", value: "Maicao, La Guajira" },
            { label: "Manaure", value: "Manaure, La Guajira" },
            { label: "Riohacha", value: "Riohacha, La Guajira" },
            { label: "San Juan del Cesar", value: "San Juan del Cesar, La Guajira" },
            { label: "Uribia", value: "Uribia, La Guajira" },
            { label: "Urumita", value: "Urumita, La Guajira" },
            { label: "Villanueva", value: "Villanueva, La Guajira" },
        ]
    },
    {
        "id": 18,
        "label": "Magdalena",
        "ciudades": [
            { label: "Algarrobo", value: "Algarrobo, Magdalena" },
            { label: "Aracataca", value: "Aracataca, Magdalena" },
            { label: "Ariguan\u00ed", value: "Ariguan\u00ed, Magdalena" },
            { label: "Cerro de San Antonio", value: "Cerro de San Antonio, Magdalena" },
            { label: "Chibolo", value: "Chibolo, Magdalena" },
            { label: "Chibolo", value: "Chibolo, Magdalena" },
            { label: "Ci\u00e9naga", value: "Ci\u00e9naga, Magdalena" },
            { label: "Concordia", value: "Concordia, Magdalena" },
            { label: "El Banco", value: "El Banco, Magdalena" },
            { label: "El Pi\u00f1\u00f3n", value: "El Pi\u00f1\u00f3n, Magdalena" },
            { label: "El Ret\u00e9n", value: "El Ret\u00e9n, Magdalena" },
            { label: "Fundaci\u00f3n", value: "Fundaci\u00f3n, Magdalena" },
            { label: "Guamal", value: "Guamal, Magdalena" },
            { label: "Nueva Granada", value: "Nueva Granada, Magdalena" },
            { label: "Pedraza", value: "Pedraza, Magdalena" },
            { label: "Piji\u00f1o del Carmen", value: "Piji\u00f1o del Carmen, Magdalena" },
            { label: "Pivijay", value: "Pivijay, Magdalena" },
            { label: "Plato", value: "Plato, Magdalena" },
            { label: "Pueblo Viejo", value: "Pueblo Viejo, Magdalena" },
            { label: "Remolino", value: "Remolino, Magdalena" },
            { label: "Sabanas de San \u00c1ngel", value: "Sabanas de San \u00c1ngel, Magdalena" },
            { label: "Salamina", value: "Salamina, Magdalena" },
            { label: "San Sebasti\u00e1n de Buenavista", value: "San Sebasti\u00e1n de Buenavista, Magdalena" },
            { label: "San Zen\u00f3n", value: "San Zen\u00f3n, Magdalena" },
            { label: "Santa Ana", value: "Santa Ana, Magdalena" },
            { label: "Santa B\u00e1rbara de Pinto", value: "Santa B\u00e1rbara de Pinto, Magdalena" },
            { label: "Santa Marta", value: "Santa Marta, Magdalena" },
            { label: "Sitionuevo", value: "Sitionuevo, Magdalena" },
            { label: "Tenerife", value: "Tenerife, Magdalena" },
            { label: "Zapay\u00e1n", value: "Zapay\u00e1n, Magdalena" },
            { label: "Zona Bananera", value: "Zona Bananera, Magdalena" },
        ]
    },
    {
        "id": 19,
        "label": "Meta",
        "ciudades": [
            { label: "Acac\u00edas", value: "Acac\u00edas, Meta" },
            { label: "Barranca de Up\u00eda", value: "Barranca de Up\u00eda, Meta" },
            { label: "Cabuyaro", value: "Cabuyaro, Meta" },
            { label: "Castilla la Nueva", value: "Castilla la Nueva, Meta" },
            { label: "Cubarral", value: "Cubarral, Meta" },
            { label: "Cumaral", value: "Cumaral, Meta" },
            { label: "El Calvario", value: "El Calvario, Meta" },
            { label: "El Castillo", value: "El Castillo, Meta" },
            { label: "El Dorado", value: "El Dorado, Meta" },
            { label: "Fuente de Oro", value: "Fuente de Oro, Meta" },
            { label: "Granada", value: "Granada, Meta" },
            { label: "Guamal", value: "Guamal, Meta" },
            { label: "La Macarena", value: "La Macarena, Meta" },
            { label: "La Uribe", value: "La Uribe, Meta" },
            { label: "Lejan\u00edas", value: "Lejan\u00edas, Meta" },
            { label: "Mapirip\u00e1n", value: "Mapirip\u00e1n, Meta" },
            { label: "Mesetas", value: "Mesetas, Meta" },
            { label: "Puerto Concordia", value: "Puerto Concordia, Meta" },
            { label: "Puerto Gait\u00e1n", value: "Puerto Gait\u00e1n, Meta" },
            { label: "Puerto Lleras", value: "Puerto Lleras, Meta" },
            { label: "Puerto L\u00f3pez", value: "Puerto L\u00f3pez, Meta" },
            { label: "Puerto Rico", value: "Puerto Rico, Meta" },
            { label: "Restrepo", value: "Restrepo, Meta" },
            { label: "San Carlos de Guaroa", value: "San Carlos de Guaroa, Meta" },
            { label: "San Juan de Arama", value: "San Juan de Arama, Meta" },
            { label: "San Juanito", value: "San Juanito, Meta" },
            { label: "San Mart\u00edn", value: "San Mart\u00edn, Meta" },
            { label: "Villavicencio", value: "Villavicencio, Meta" },
            { label: "Vista Hermosa", value: "Vista Hermosa, Meta" },
        ]
    },
    {
        "id": 20,
        "label": "Nari\u00f1o",
        "ciudades": [
            { label: "Aldana", value: "Aldana, Nari\u00f1o" },
            { label: "Ancuy\u00e1", value: "Ancuy\u00e1, Nari\u00f1o" },
            { label: "Arboleda", value: "Arboleda, Nari\u00f1o" },
            { label: "Barbacoas", value: "Barbacoas, Nari\u00f1o" },
            { label: "Bel\u00e9n", value: "Bel\u00e9n, Nari\u00f1o" },
            { label: "Buesaco", value: "Buesaco, Nari\u00f1o" },
            { label: "Chachag\u00fc\u00ed", value: "Chachag\u00fc\u00ed, Nari\u00f1o" },
            { label: "Col\u00f3n", value: "Col\u00f3n, Nari\u00f1o" },
            { label: "Consac\u00e1", value: "Consac\u00e1, Nari\u00f1o" },
            { label: "Contadero", value: "Contadero, Nari\u00f1o" },
            { label: "C\u00f3rdoba", value: "C\u00f3rdoba, Nari\u00f1o" },
            { label: "Cuaspud", value: "Cuaspud, Nari\u00f1o" },
            { label: "Cumbal", value: "Cumbal, Nari\u00f1o" },
            { label: "Cumbitara", value: "Cumbitara, Nari\u00f1o" },
            { label: "El Charco", value: "El Charco, Nari\u00f1o" },
            { label: "El Pe\u00f1ol", value: "El Pe\u00f1ol, Nari\u00f1o" },
            { label: "El Rosario", value: "El Rosario, Nari\u00f1o" },
            { label: "El Tabl\u00f3n", value: "El Tabl\u00f3n, Nari\u00f1o" },
            { label: "El Tambo", value: "El Tambo, Nari\u00f1o" },
            { label: "Francisco Pizarro", value: "Francisco Pizarro, Nari\u00f1o" },
            { label: "Funes", value: "Funes, Nari\u00f1o" },
            { label: "Guachucal", value: "Guachucal, Nari\u00f1o" },
            { label: "Guaitarilla", value: "Guaitarilla, Nari\u00f1o" },
            { label: "Gualmat\u00e1n", value: "Gualmat\u00e1n, Nari\u00f1o" },
            { label: "Iles", value: "Iles, Nari\u00f1o" },
            { label: "Imu\u00e9s", value: "Imu\u00e9s, Nari\u00f1o" },
            { label: "Ipiales", value: "Ipiales, Nari\u00f1o" },
            { label: "La Cruz", value: "La Cruz, Nari\u00f1o" },
            { label: "La Florida", value: "La Florida, Nari\u00f1o" },
            { label: "La Llanada", value: "La Llanada, Nari\u00f1o" },
            { label: "La Tola", value: "La Tola, Nari\u00f1o" },
            { label: "La Uni\u00f3n", value: "La Uni\u00f3n, Nari\u00f1o" },
            { label: "Leiva", value: "Leiva, Nari\u00f1o" },
            { label: "Linares", value: "Linares, Nari\u00f1o" },
            { label: "Los Andes", value: "Los Andes, Nari\u00f1o" },
            { label: "Mag\u00fc\u00ed Pay\u00e1n", value: "Mag\u00fc\u00ed Pay\u00e1n, Nari\u00f1o" },
            { label: "Mallama", value: "Mallama, Nari\u00f1o" },
            { label: "Mosquera", value: "Mosquera, Nari\u00f1o" },
            { label: "Nari\u00f1o", value: "Nari\u00f1o, Nari\u00f1o" },
            { label: "Olaya Herrera", value: "Olaya Herrera, Nari\u00f1o" },
            { label: "Ospina", value: "Ospina, Nari\u00f1o" },
            { label: "Pasto", value: "Pasto, Nari\u00f1o" },
            { label: "Policarpa", value: "Policarpa, Nari\u00f1o" },
            { label: "Potos\u00ed", value: "Potos\u00ed, Nari\u00f1o" },
            { label: "Providencia", value: "Providencia, Nari\u00f1o" },
            { label: "Puerres", value: "Puerres, Nari\u00f1o" },
            { label: "Pupiales", value: "Pupiales, Nari\u00f1o" },
            { label: "Ricaurte", value: "Ricaurte, Nari\u00f1o" },
            { label: "Roberto Pay\u00e1n", value: "Roberto Pay\u00e1n, Nari\u00f1o" },
            { label: "Samaniego", value: "Samaniego, Nari\u00f1o" },
            { label: "San Bernardo", value: "San Bernardo, Nari\u00f1o" },
            { label: "San Jos\u00e9 de Alb\u00e1n", value: "San Jos\u00e9 de Alb\u00e1n, Nari\u00f1o" },
            { label: "San Lorenzo", value: "San Lorenzo, Nari\u00f1o" },
            { label: "San Pablo", value: "San Pablo, Nari\u00f1o" },
            { label: "San Pedro de Cartago", value: "San Pedro de Cartago, Nari\u00f1o" },
            { label: "Sandon\u00e1", value: "Sandon\u00e1, Nari\u00f1o" },
            { label: "Santa B\u00e1rbara", value: "Santa B\u00e1rbara, Nari\u00f1o" },
            { label: "Santacruz", value: "Santacruz, Nari\u00f1o" },
            { label: "Sapuyes", value: "Sapuyes, Nari\u00f1o" },
            { label: "Taminango", value: "Taminango, Nari\u00f1o" },
            { label: "Tangua", value: "Tangua, Nari\u00f1o" },
            { label: "Tumaco", value: "Tumaco, Nari\u00f1o" },
            { label: "T\u00faquerres", value: "T\u00faquerres, Nari\u00f1o" },
            { label: "Yacuanquer", value: "Yacuanquer, Nari\u00f1o" },
        ]
    },
    {
        "id": 21,
        "label": "Norte de Santander",
        "ciudades": [
            { label: "\u00c1brego", value: "\u00c1brego, Norte de Santander" },
            { label: "Arboledas", value: "Arboledas, Norte de Santander" },
            { label: "Bochalema", value: "Bochalema, Norte de Santander" },
            { label: "Bucarasica", value: "Bucarasica, Norte de Santander" },
            { label: "C\u00e1chira", value: "C\u00e1chira, Norte de Santander" },
            { label: "C\u00e1cota", value: "C\u00e1cota, Norte de Santander" },
            { label: "Chin\u00e1cota", value: "Chin\u00e1cota, Norte de Santander" },
            { label: "Chitag\u00e1", value: "Chitag\u00e1, Norte de Santander" },
            { label: "Convenci\u00f3n", value: "Convenci\u00f3n, Norte de Santander" },
            { label: "C\u00facuta", value: "C\u00facuta, Norte de Santander" },
            { label: "Cucutilla", value: "Cucutilla, Norte de Santander" },
            { label: "Duran\u00eda", value: "Duran\u00eda, Norte de Santander" },
            { label: "El Carmen", value: "El Carmen, Norte de Santander" },
            { label: "El Tarra", value: "El Tarra, Norte de Santander" },
            { label: "El Zulia", value: "El Zulia, Norte de Santander" },
            { label: "Gramalote", value: "Gramalote, Norte de Santander" },
            { label: "Hacar\u00ed", value: "Hacar\u00ed, Norte de Santander" },
            { label: "Herr\u00e1n", value: "Herr\u00e1n, Norte de Santander" },
            { label: "La Esperanza", value: "La Esperanza, Norte de Santander" },
            { label: "La Playa de Bel\u00e9n", value: "La Playa de Bel\u00e9n, Norte de Santander" },
            { label: "Labateca", value: "Labateca, Norte de Santander" },
            { label: "Los Patios", value: "Los Patios, Norte de Santander" },
            { label: "Lourdes", value: "Lourdes, Norte de Santander" },
            { label: "Mutiscua", value: "Mutiscua, Norte de Santander" },
            { label: "Oca\u00f1a", value: "Oca\u00f1a, Norte de Santander" },
            { label: "Pamplona", value: "Pamplona, Norte de Santander" },
            { label: "Pamplonita", value: "Pamplonita, Norte de Santander" },
            { label: "Puerto Santander", value: "Puerto Santander, Norte de Santander" },
            { label: "Ragonvalia", value: "Ragonvalia, Norte de Santander" },
            { label: "Salazar de Las Palmas", value: "Salazar de Las Palmas, Norte de Santander" },
            { label: "San Calixto", value: "San Calixto, Norte de Santander" },
            { label: "San Cayetano", value: "San Cayetano, Norte de Santander" },
            { label: "Santiago", value: "Santiago, Norte de Santander" },
            { label: "Santo Domingo de Silos", value: "Santo Domingo de Silos, Norte de Santander" },
            { label: "Sardinata", value: "Sardinata, Norte de Santander" },
            { label: "Teorama", value: "Teorama, Norte de Santander" },
            { label: "Tib\u00fa", value: "Tib\u00fa, Norte de Santander" },
            { label: "Toledo", value: "Toledo, Norte de Santander" },
            { label: "Villa Caro", value: "Villa Caro, Norte de Santander" },
            { label: "Villa del Rosario", value: "Villa del Rosario, Norte de Santander" },
        ]
    },
    {
        "id": 22,
        "label": "Putumayo",
        "ciudades": [
            { label: "Col\u00f3n", value: "Col\u00f3n, Putumayo" },
            { label: "Mocoa", value: "Mocoa, Putumayo" },
            { label: "Orito", value: "Orito, Putumayo" },
            { label: "Puerto As\u00eds", value: "Puerto As\u00eds, Putumayo" },
            { label: "Puerto Caicedo", value: "Puerto Caicedo, Putumayo" },
            { label: "Puerto Guzm\u00e1n", value: "Puerto Guzm\u00e1n, Putumayo" },
            { label: "Puerto Legu\u00edzamo", value: "Puerto Legu\u00edzamo, Putumayo" },
            { label: "San Francisco", value: "San Francisco, Putumayo" },
            { label: "San Miguel", value: "San Miguel, Putumayo" },
            { label: "Santiago", value: "Santiago, Putumayo" },
            { label: "Sibundoy", value: "Sibundoy, Putumayo" },
            { label: "Valle del Guamuez", value: "Valle del Guamuez, Putumayo" },
            { label: "Villagarz\u00f3n", value: "Villagarz\u00f3n, Putumayo" },
        ]
    },
    {
        "id": 23,
        "label": "Quind\u00edo",
        "ciudades": [
            { label: "Armenia", value: "Armenia, Quind\u00edo" },
            { label: "Buenavista", value: "Buenavista, Quind\u00edo" },
            { label: "Calarc\u00e1", value: "Calarc\u00e1, Quind\u00edo" },
            { label: "Circasia", value: "Circasia, Quind\u00edo" },
            { label: "C\u00f3rdoba", value: "C\u00f3rdoba, Quind\u00edo" },
            { label: "Filandia", value: "Filandia, Quind\u00edo" },
            { label: "G\u00e9nova", value: "G\u00e9nova, Quind\u00edo" },
            { label: "La Tebaida", value: "La Tebaida, Quind\u00edo" },
            { label: "Montenegro", value: "Montenegro, Quind\u00edo" },
            { label: "Pijao", value: "Pijao, Quind\u00edo" },
            { label: "Quimbaya", value: "Quimbaya, Quind\u00edo" },
            { label: "Salento", value: "Salento, Quind\u00edo" },
        ]
    },
    {
        "id": 24,
        "label": "Risaralda",
        "ciudades": [
            { label: "Ap\u00eda", value: "Ap\u00eda, Risaralda" },
            { label: "Balboa", value: "Balboa, Risaralda" },
            { label: "Bel\u00e9n de Umbr\u00eda", value: "Bel\u00e9n de Umbr\u00eda, Risaralda" },
            { label: "Dosquebradas", value: "Dosquebradas, Risaralda" },
            { label: "Gu\u00e1tica", value: "Gu\u00e1tica, Risaralda" },
            { label: "La Celia", value: "La Celia, Risaralda" },
            { label: "La Virginia", value: "La Virginia, Risaralda" },
            { label: "Marsella", value: "Marsella, Risaralda" },
            { label: "Mistrat\u00f3", value: "Mistrat\u00f3, Risaralda" },
            { label: "Pereira", value: "Pereira, Risaralda" },
            { label: "Pueblo Rico", value: "Pueblo Rico, Risaralda" },
            { label: "Quinch\u00eda", value: "Quinch\u00eda, Risaralda" },
            { label: "Santa Rosa de Cabal", value: "Santa Rosa de Cabal, Risaralda" },
            { label: "Santuario", value: "Santuario, Risaralda" },
        ]
    },
    {
        "id": 25,
        "label": "San Andr\u00e9s y Providencia",
        "ciudades": [
            { label: "Providencia y Santa Catalina Islas", value: "Providencia y Santa Catalina Islas, San Andr\u00e9s y Providencia" },
            { label: "San Andr\u00e9s", value: "San Andr\u00e9s, San Andr\u00e9s y Providencia" },
        ]
    },
    {
        "id": 26,
        "label": "Santander",
        "ciudades": [
            { label: "Aguada", value: "Aguada, Santander" },
            { label: "Albania", value: "Albania, Santander" },
            { label: "Aratoca", value: "Aratoca, Santander" },
            { label: "Barbosa", value: "Barbosa, Santander" },
            { label: "Barichara", value: "Barichara, Santander" },
            { label: "Barrancabermeja", value: "Barrancabermeja, Santander" },
            { label: "Betulia", value: "Betulia, Santander" },
            { label: "Bol\u00edvar", value: "Bol\u00edvar, Santander" },
            { label: "Bucaramanga", value: "Bucaramanga, Santander" },
            { label: "Cabrera", value: "Cabrera, Santander" },
            { label: "California", value: "California, Santander" },
            { label: "Capitanejo", value: "Capitanejo, Santander" },
            { label: "Carcas\u00ed", value: "Carcas\u00ed, Santander" },
            { label: "Cepit\u00e1", value: "Cepit\u00e1, Santander" },
            { label: "Cerrito", value: "Cerrito, Santander" },
            { label: "Charal\u00e1", value: "Charal\u00e1, Santander" },
            { label: "Charta", value: "Charta, Santander" },
            { label: "Chima", value: "Chima, Santander" },
            { label: "Chipat\u00e1", value: "Chipat\u00e1, Santander" },
            { label: "Cimitarra", value: "Cimitarra, Santander" },
            { label: "Concepci\u00f3n", value: "Concepci\u00f3n, Santander" },
            { label: "Confines", value: "Confines, Santander" },
            { label: "Contrataci\u00f3n", value: "Contrataci\u00f3n, Santander" },
            { label: "Coromoro", value: "Coromoro, Santander" },
            { label: "Curit\u00ed", value: "Curit\u00ed, Santander" },
            { label: "El Carmen de Chucur\u00ed", value: "El Carmen de Chucur\u00ed, Santander" },
            { label: "El Guacamayo", value: "El Guacamayo, Santander" },
            { label: "El Pe\u00f1\u00f3n", value: "El Pe\u00f1\u00f3n, Santander" },
            { label: "El Play\u00f3n", value: "El Play\u00f3n, Santander" },
            { label: "El Socorro", value: "El Socorro, Santander" },
            { label: "Encino", value: "Encino, Santander" },
            { label: "Enciso", value: "Enciso, Santander" },
            { label: "Flori\u00e1n", value: "Flori\u00e1n, Santander" },
            { label: "Floridablanca", value: "Floridablanca, Santander" },
            { label: "Gal\u00e1n", value: "Gal\u00e1n, Santander" },
            { label: "G\u00e1mbita", value: "G\u00e1mbita, Santander" },
            { label: "Gir\u00f3n", value: "Gir\u00f3n, Santander" },
            { label: "Guaca", value: "Guaca, Santander" },
            { label: "Guadalupe", value: "Guadalupe, Santander" },
            { label: "Guapot\u00e1", value: "Guapot\u00e1, Santander" },
            { label: "Guavat\u00e1", value: "Guavat\u00e1, Santander" },
            { label: "G\u00fcepsa", value: "G\u00fcepsa, Santander" },
            { label: "Hato", value: "Hato, Santander" },
            { label: "Jes\u00fas Mar\u00eda", value: "Jes\u00fas Mar\u00eda, Santander" },
            { label: "Jord\u00e1n", value: "Jord\u00e1n, Santander" },
            { label: "La Belleza", value: "La Belleza, Santander" },
            { label: "La Paz", value: "La Paz, Santander" },
            { label: "Land\u00e1zuri", value: "Land\u00e1zuri, Santander" },
            { label: "Lebrija", value: "Lebrija, Santander" },
            { label: "Los Santos", value: "Los Santos, Santander" },
            { label: "Macaravita", value: "Macaravita, Santander" },
            { label: "M\u00e1laga", value: "M\u00e1laga, Santander" },
            { label: "Matanza", value: "Matanza, Santander" },
            { label: "Mogotes", value: "Mogotes, Santander" },
            { label: "Molagavita", value: "Molagavita, Santander" },
            { label: "Ocamonte", value: "Ocamonte, Santander" },
            { label: "Oiba", value: "Oiba, Santander" },
            { label: "Onzaga", value: "Onzaga, Santander" },
            { label: "Palmar", value: "Palmar, Santander" },
            { label: "Palmas del Socorro", value: "Palmas del Socorro, Santander" },
            { label: "P\u00e1ramo", value: "P\u00e1ramo, Santander" },
            { label: "Piedecuesta", value: "Piedecuesta, Santander" },
            { label: "Pinchote", value: "Pinchote, Santander" },
            { label: "Puente Nacional", value: "Puente Nacional, Santander" },
            { label: "Puerto Parra", value: "Puerto Parra, Santander" },
            { label: "Puerto Wilches", value: "Puerto Wilches, Santander" },
            { label: "Rionegro", value: "Rionegro, Santander" },
            { label: "Sabana de Torres", value: "Sabana de Torres, Santander" },
            { label: "San Andr\u00e9s", value: "San Andr\u00e9s, Santander" },
            { label: "San Benito", value: "San Benito, Santander" },
            { label: "San Gil", value: "San Gil, Santander" },
            { label: "San Joaqu\u00edn", value: "San Joaqu\u00edn, Santander" },
            { label: "San Jos\u00e9 de Miranda", value: "San Jos\u00e9 de Miranda, Santander" },
            { label: "San Miguel", value: "San Miguel, Santander" },
            { label: "San Vicente de Chucur\u00ed", value: "San Vicente de Chucur\u00ed, Santander" },
            { label: "Santa B\u00e1rbara", value: "Santa B\u00e1rbara, Santander" },
            { label: "Santa Helena del Op\u00f3n", value: "Santa Helena del Op\u00f3n, Santander" },
            { label: "Simacota", value: "Simacota, Santander" },
            { label: "Suaita", value: "Suaita, Santander" },
            { label: "Sucre", value: "Sucre, Santander" },
            { label: "Surat\u00e1", value: "Surat\u00e1, Santander" },
            { label: "Tona", value: "Tona, Santander" },
            { label: "Valle de San Jos\u00e9", value: "Valle de San Jos\u00e9, Santander" },
            { label: "V\u00e9lez", value: "V\u00e9lez, Santander" },
            { label: "Vetas", value: "Vetas, Santander" },
            { label: "Villanueva", value: "Villanueva, Santander" },
            { label: "Zapatoca", value: "Zapatoca, Santander" },
        ]
    },
    {
        "id": 27,
        "label": "Sucre",
        "ciudades": [
            { label: "Buenavista", value: "Buenavista, Sucre" },
            { label: "Caimito", value: "Caimito, Sucre" },
            { label: "Chal\u00e1n", value: "Chal\u00e1n, Sucre" },
            { label: "Colos\u00f3", value: "Colos\u00f3, Sucre" },
            { label: "Corozal", value: "Corozal, Sucre" },
            { label: "Cove\u00f1as", value: "Cove\u00f1as, Sucre" },
            { label: "El Roble", value: "El Roble, Sucre" },
            { label: "Galeras", value: "Galeras, Sucre" },
            { label: "Guaranda", value: "Guaranda, Sucre" },
            { label: "La Uni\u00f3n", value: "La Uni\u00f3n, Sucre" },
            { label: "Los Palmitos", value: "Los Palmitos, Sucre" },
            { label: "Majagual", value: "Majagual, Sucre" },
            { label: "Morroa", value: "Morroa, Sucre" },
            { label: "Ovejas", value: "Ovejas, Sucre" },
            { label: "Sampu\u00e9s", value: "Sampu\u00e9s, Sucre" },
            { label: "San Antonio de Palmito", value: "San Antonio de Palmito, Sucre" },
            { label: "San Benito Abad", value: "San Benito Abad, Sucre" },
            { label: "San Juan de Betulia", value: "San Juan de Betulia, Sucre" },
            { label: "San Marcos", value: "San Marcos, Sucre" },
            { label: "San Onofre", value: "San Onofre, Sucre" },
            { label: "San Pedro", value: "San Pedro, Sucre" },
            { label: "Sinc\u00e9", value: "Sinc\u00e9, Sucre" },
            { label: "Sincelejo", value: "Sincelejo, Sucre" },
            { label: "Sucre", value: "Sucre, Sucre" },
            { label: "Tol\u00fa", value: "Tol\u00fa, Sucre" },
            { label: "Tol\u00fa Viejo", value: "Tol\u00fa Viejo, Sucre" },
        ]
    },
    {
        "id": 28,
        "label": "Tolima",
        "ciudades": [
            { label: "Alpujarra", value: "Alpujarra, Tolima" },
            { label: "Alvarado", value: "Alvarado, Tolima" },
            { label: "Ambalema", value: "Ambalema, Tolima" },
            { label: "Anzo\u00e1tegui", value: "Anzo\u00e1tegui, Tolima" },
            { label: "Armero", value: "Armero, Tolima" },
            { label: "Ataco", value: "Ataco, Tolima" },
            { label: "Cajamarca", value: "Cajamarca, Tolima" },
            { label: "Carmen de Apical\u00e1", value: "Carmen de Apical\u00e1, Tolima" },
            { label: "Casabianca", value: "Casabianca, Tolima" },
            { label: "Chaparral", value: "Chaparral, Tolima" },
            { label: "Coello", value: "Coello, Tolima" },
            { label: "Coyaima", value: "Coyaima, Tolima" },
            { label: "Cunday", value: "Cunday, Tolima" },
            { label: "Dolores", value: "Dolores, Tolima" },
            { label: "El Espinal", value: "El Espinal, Tolima" },
            { label: "Fal\u00e1n", value: "Fal\u00e1n, Tolima" },
            { label: "Flandes", value: "Flandes, Tolima" },
            { label: "Fresno", value: "Fresno, Tolima" },
            { label: "Guamo", value: "Guamo, Tolima" },
            { label: "Herveo", value: "Herveo, Tolima" },
            { label: "Honda", value: "Honda, Tolima" },
            { label: "Ibagu\u00e9", value: "Ibagu\u00e9, Tolima" },
            { label: "Icononzo", value: "Icononzo, Tolima" },
            { label: "L\u00e9rida", value: "L\u00e9rida, Tolima" },
            { label: "L\u00edbano", value: "L\u00edbano, Tolima" },
            { label: "Mariquita", value: "Mariquita, Tolima" },
            { label: "Melgar", value: "Melgar, Tolima" },
            { label: "Murillo", value: "Murillo, Tolima" },
            { label: "Natagaima", value: "Natagaima, Tolima" },
            { label: "Ortega", value: "Ortega, Tolima" },
            { label: "Palocabildo", value: "Palocabildo, Tolima" },
            { label: "Piedras", value: "Piedras, Tolima" },
            { label: "Planadas", value: "Planadas, Tolima" },
            { label: "Prado", value: "Prado, Tolima" },
            { label: "Purificaci\u00f3n", value: "Purificaci\u00f3n, Tolima" },
            { label: "Rioblanco", value: "Rioblanco, Tolima" },
            { label: "Roncesvalles", value: "Roncesvalles, Tolima" },
            { label: "Rovira", value: "Rovira, Tolima" },
            { label: "Salda\u00f1a", value: "Salda\u00f1a, Tolima" },
            { label: "San Antonio", value: "San Antonio, Tolima" },
            { label: "San Luis", value: "San Luis, Tolima" },
            { label: "Santa Isabel", value: "Santa Isabel, Tolima" },
            { label: "Su\u00e1rez", value: "Su\u00e1rez, Tolima" },
            { label: "Valle de San Juan", value: "Valle de San Juan, Tolima" },
            { label: "Venadillo", value: "Venadillo, Tolima" },
            { label: "Villahermosa", value: "Villahermosa, Tolima" },
            { label: "Villarrica", value: "Villarrica, Tolima" },
        ]
    },
    {
        "id": 29,
        "label": "Valle del Cauca",
        "ciudades": [
            { label: "Alcal\u00e1", value: "Alcal\u00e1, Valle del Cauca" },
            { label: "Andaluc\u00eda,", value: "Andaluc\u00eda, Valle del Cauca" },
            { label: "Ansermanuevo", value: "Ansermanuevo, Valle del Cauca" },
            { label: "Argelia", value: "Argelia, Valle del Cauca" },
            { label: "Bol\u00edvar", value: "Bol\u00edvar, Valle del Cauca" },
            { label: "Buenaventura", value: "Buenaventura, Valle del Cauca" },
            { label: "Buga", value: "Buga, Valle del Cauca" },
            { label: "Bugalagrande", value: "Bugalagrande, Valle del Cauca" },
            { label: "Caicedonia", value: "Caicedonia, Valle del Cauca" },
            { label: "Cali", value: "Cali, Valle del Cauca" },
            { label: "Calima", value: "Calima, Valle del Cauca" },
            { label: "Candelaria", value: "Candelaria, Valle del Cauca" },
            { label: "Cartago", value: "Cartago, Valle del Cauca" },
            { label: "Dagua", value: "Dagua, Valle del Cauca" },
            { label: "El \u00c1guila", value: "El \u00c1guila, Valle del Cauca" },
            { label: "El Cairo", value: "El Cairo, Valle del Cauca" },
            { label: "El Cerrito", value: "El Cerrito, Valle del Cauca" },
            { label: "El Dovio", value: "El Dovio, Valle del Cauca" },
            { label: "Florida", value: "Florida, Valle del Cauca" },
            { label: "Ginebra", value: "Ginebra, Valle del Cauca" },
            { label: "Guacar\u00ed", value: "Guacar\u00ed, Valle del Cauca" },
            { label: "Jamund\u00ed", value: "Jamund\u00ed, Valle del Cauca" },
            { label: "La Cumbre", value: "La Cumbre, Valle del Cauca" },
            { label: "La Uni\u00f3n", value: "La Uni\u00f3n, Valle del Cauca" },
            { label: "La Victoria", value: "La Victoria, Valle del Cauca" },
            { label: "Obando", value: "Obando, Valle del Cauca" },
            { label: "Palmira", value: "Palmira, Valle del Cauca" },
            { label: "Pradera", value: "Pradera, Valle del Cauca" },
            { label: "Restrepo", value: "Restrepo, Valle del Cauca" },
            { label: "Riofr\u00edo", value: "Riofr\u00edo, Valle del Cauca" },
            { label: "Roldanillo", value: "Roldanillo, Valle del Cauca" },
            { label: "San Pedro", value: "San Pedro, Valle del Cauca" },
            { label: "Sevilla", value: "Sevilla, Valle del Cauca" },
            { label: "Toro", value: "Toro, Valle del Cauca" },
            { label: "Trujillo", value: "Trujillo, Valle del Cauca" },
            { label: "Tulu\u00e1", value: "Tulu\u00e1, Valle del Cauca" },
            { label: "Ulloa", value: "Ulloa, Valle del Cauca" },
            { label: "Versalles", value: "Versalles, Valle del Cauca" },
            { label: "Vijes", value: "Vijes, Valle del Cauca" },
            { label: "Yotoco", value: "Yotoco, Valle del Cauca" },
            { label: "Yumbo", value: "Yumbo, Valle del Cauca" },
            { label: "Zarzal", value: "Zarzal, Valle del Cauca" },
        ]
    },
    {
        "id": 30,
        "label": "Vaup\u00e9s",
        "ciudades": [
            { label: "Carur\u00fa", value: "Carur\u00fa, Vaup\u00e9s" },
            { label: "Mit\u00fa", value: "Mit\u00fa, Vaup\u00e9s" },
            { label: "Taraira", value: "Taraira, Vaup\u00e9s" },
        ]
    },
    {
        "id": 31,
        "label": "Vichada",
        "ciudades": [
            { label: "Cumaribo", value: "Cumaribo, Vichada" },
            { label: "La Primavera", value: "La Primavera, Vichada" },
            { label: "Puerto Carre\u00f1o", value: "Puerto Carre\u00f1o, Vichada" },
            { label: "Santa Rosal\u00eda", value: "Santa Rosal\u00eda, Vichada" },
        ]
    }
]