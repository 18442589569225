<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <Toast />
        <Toolbar class="mb-4">
          <template v-slot:start>
            <h5 class="m-4">Transportadoras</h5>
          </template>
          <template v-slot:end>
            <Button id="crearTrasnportadora" label="Crear transportadora" icon="pi pi-plus"
              class="p-button-success mr-2" @click="nuevaTransportadora()" />
            <Button icon="pi pi-external-link" label="Exportar" @click="exportCSV($event)" />
          </template>
        </Toolbar>
        <!-- Inicio tabla listado de transportadora -->
        <DataTable ref="dt" :value="transportadoras" stripedRows v-model:selection="transportadoraSeleccionada"
          dataKey="id" :paginator="true" :rows="10" :filters="filters" :loading="loading" rowHover="true"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 25]"
          currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords} transportadoras"
          responsiveLayout="scroll" breakpoint="1200px" @row-click="seleccionarTransportadora">
          <template #header>
            <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
              <span class="block mt-2 md:mt-0 p-input-icon-left">
                <i class="pi pi-search" />
                <InputText v-model="filters['global'].value" placeholder="Buscar..." />
              </span>
            </div>
          </template>
          <Column selectionMode="multiple"></Column>
          <!-- Nombre de la columna transportadora -->
          <Column field="nombre" header="Transportadora" :sortable="true" class="cursor-pointer">
            <template #body="slotProps">
              {{ slotProps.data.nombre }}
            </template>
          </Column>
          <Column field="descripcion" header="Descripción transportadora" :sortable="true" class="cursor-pointer">
            <template #body="slotProps">
              {{ slotProps.data.descripcion }}
            </template>
          </Column>
          <Column field="Url_seguimiento" header="Url de seguimiento" :sortable="true" class="cursor-pointer">
            <template #body="slotProps">
              {{ slotProps.data.url_seguimiento }}
            </template>
          </Column>
          <Column header="logo" headerStyle="width:14%; min-width:10rem;" class="cursor-pointer">
            <template #body="slotProps">
              <span class="p-column-title">Logo</span>
              <img :src="slotProps.data.logo" :alt="slotProps.data.logo" class="shadow-2" width="100" />
            </template>
          </Column>
          <Column field="habilitado" header="Habilitada" :sortable="true" class="cursor-pointer">
            <template #body="slotProps">
              <em class="pi" :class="{
                'text-teal-500 pi-check-circle': slotProps.data.habilitado,
                'text-red-500 pi-times-circle': !slotProps.data.habilitado,
              }"></em>
            </template>
          </Column>
          <Column>
            <template #body="slotProps">
              <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2"
                @click="editarTransportadora(slotProps.data)" />
            </template>
          </Column>
        </DataTable>
        <!-- Fin tabla listado de transportadoras -->

        <!-- Inicio modal formulario crear o editar transportadoras -->
        <Dialog v-model:visible="transpotadoraDialog" :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
          :style="{ width: '60vw' }" :header="transportadora.nombre ? transportadora.nombre : 'Crear transportadora'"
          :modal="true" class="p-fluid">

          <div class="grid  align-items-center">
            <div class="xl:col-6 lg:col-7 col-12">
              <div class="field">
                <label for="nombre">Nombre*</label>
                <InputText id="nombre" name="nombre" v-model.trim="transportadora.nombre" required="true" autofocus
                  :class="{ 'p-invalid': noEnviar && v$.transportadora.nombre.$invalid }" />
                <small class="p-error" v-if="noEnviar && v$.transportadora.nombre.$invalid">
                  El campo nombre es requerido.
                </small>
              </div>
            </div>

            <div class="col-6 md:col-3">
              <FileUpload mode="basic" name="demo[]" :customUpload="true" chooseLabel="Subir Logo" :auto="true"
                @uploader="subirLogo" accept="image/*" />
            </div>
            <div class="col-6 md:col-3">
              <Image v-if="this.logo" :src="this.logo" alt="Logo Trasnportadora" imageClass="h-6rem w-10rem" preview />
              <Image v-else-if="transportadora.logo" :src="transportadora.logo" :alt="transportadora.nombre"
                imageClass="h-6rem w-10rem" preview />
            </div>

          </div>

          <div class="grid">
            <div class="col-12 md:col-12">
              <div class="field">
                <label for="descripcion">Descripción</label>
                <InputText id="descripcion" name="descripcion" v-model.trim="transportadora.descripcion" />
              </div>
            </div>
            <!-- Espacio para los selects  -->
            <!-- <div class="col-6 md:col-6" v-if="!transportadora.esgeneral">
              <div class="field">
                <Dropdown id="aplica_a" v-model="transportadora.aplica_a" :options="casosAplicar" optionValue="nombre"
                  optionLabel="nombre" placeholder="¿Para que aplica?">
                </Dropdown>
              </div>
              <div class="field" v-if="transportadora.aplica_a === 'Tienda'">
                <label for="tienda">Tienda</label>
                <Dropdown id="tienda" v-model="transportadora.caso_transportadora"></Dropdown>
              </div>
               <div class="field" v-else-if="transportadora.aplica_a === 'Categoria'">
                <label for="tienda">Categoria</label>
                <Dropdown id="categoria" v-model="transportadora.caso_transportadora"></Dropdown>
              </div>
               <div class="field" v-else-if="transportadora.aplica_a === 'Producto'">
                <label for="Producto">Producto</label>
                <input-productos @seleccionoProductos="selecciono" />
              </div> 
            </div>
            -->
          </div>

          <div class="grid">
            <div class="col-12 md:col-6">
              <div class="field">
                <label for="url_seguimiento">Url seguimiento</label>
                <InputText id="url_seguimiento" name="url_seguimiento" placeholder="https://www.example.com"
                  :class="{ 'p-invalid': noEnviar && v$.transportadora.url_seguimiento.$invalid }"
                  v-model.trim="transportadora.url_seguimiento" />
                <small class="p-error" v-if="noEnviar && v$.transportadora.url_seguimiento.$invalid">
                  La Url es incorrecta. debe tener el siguiente formato: https://www.dominio.com/
                </small>
              </div>
            </div>

            <div class="col-6 md:col-3">
              <div class="field">
                <label for="habilitado">¿Habilitado?</label>
                <ToggleButton v-model="transportadora.habilitado" onLabel="Si" offLabel="NO" onIcon="pi pi-check"
                  offIcon="pi pi-times" />
              </div>
            </div>

            <div class="col-6 md:col-3">
              <div class="field">
                <label for="envio_gratis">¿Envío gratis?</label>
                <ToggleButton v-model="transportadora.envio_gratis" onLabel="SI" offLabel="NO" onIcon="pi pi-check"
                  offIcon="pi pi-times" />
              </div>
            </div>
            <!-- <div class="col-6 md:col-2">
              <div class="field">
                <label for="envio_gratis">¿General?</label>
                <ToggleButton v-model="transportadora.esgeneral" onLabel="SI" offLabel="NO" onIcon="pi pi-check"
                  offIcon="pi pi-times" @change="transportadora.aplica_a = 'seleccion'" />
                <small class="p-error" v-if="noEnviar && v$.transportadora.esgeneral.$invalid">
                  Este campo es requerido.
                </small>
              </div>
            </div> -->
          </div>

          <template #footer>
            <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="ocultarDialog()" />
            <Button label="Guardar" icon="pi pi-check" class="p-button-text"
              @click="guardarTransportadora(!v$.$invalid)" />
          </template>

        </Dialog>
        <!-- Fin formulario crear o editar transportadoras -->

        <Dialog :header="transportadoraSeleccionadaClick.nombre" v-model:visible="modaltransportadoraCostos"
          :breakpoints="{ '960px': '75vw', '640px': '100vw' }" :style="{ width: '60vw' }" class="p-fluid">
          {{ transportadoraSeleccionadaClick }}
        </Dialog>
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, url } from '@vuelidate/validators'
import { FilterMatchMode } from "primevue/api";
import { obtenerTodo,crear, actualizar } from "@/api/clienteHttpService";

/* import inputProductos from '@/modulos/productos/components/InputProductos.vue'; */

export default {
  name: 'Trasnportadoras',
  setup() {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      transportadoras: null,
      loading: true,
      cargando: false,
      modaltransportadoraCostos: false,
      transportadora: {
        nombre: '',
        descripcion: '',
        logo: null,
        habilitado: true,
        url_seguimiento: '',
        envio_gratis: false,
        esgeneral: true,
        aplica_a: "",
        caso_transportadora: null,
        id_user_log: null,
      },
      transpotadoraDialog: false,
      transportadoraSeleccionada: null,
      filters: {},
      noEnviar: false,
      logo: null,
      casosAplicar: [
        { nombre: 'Tienda' },
        /* { nombre: 'Categoria' },
        { nombre: 'Producto' }, */
      ],
      transportadoraSeleccionadaClick: {},
      productos: [],
    }
  },
  validations() {
    return {
      transportadora: {
        nombre: {
          required,
        },
        url_seguimiento: { url },
      },
    }
  },
  async created() {
    this.initFilters();
    this.obtenerTransportadoras();
  },
  methods: {
    subirLogo(event) {
      this.transportadora.logo = event.files[0];
      this.logo = URL.createObjectURL(event.files[0]);
    },
    nuevaTransportadora() {
      this.logo = null;
      this.transpotadoraDialog = true;
      this.transportadora.esgeneral = true;
      this.transportadora.id_user_log = localStorage.getItem('id');
    },
    ocultarDialog() {
      this.transpotadoraDialog = false;
      this.logo = null;
    },

    async obtenerTransportadoras() {
      this.loading = true;
      const resp = await obtenerTodo("/transportadoras");
      this.transportadoras = resp;
      this.loading = false;
    },
    async guardarTransportadora(isFormValid) {
      if (isFormValid) {
        this.noEnviar = false;
        if (this.transportadora.id) {
          //Actualiza
          const actualiza = await actualizar("/transportadoras", this.transportadora);
          if (actualiza === 'Accepted') {
            this.transportadora = {};
            this.transpotadoraDialog = false;
            this.obtenerTransportadoras();
            this.$toast.add({ severity: 'success', summary: 'Éxito', detail: 'Los datos de la transportadora fueron actualizados', life: 3000 });
          }
          } else {
          //Verifica que la transportadora no exista.
          if (this.transportadoras.find(transportadora => transportadora.nombre === this.transportadora.nombre)) {
            this.$toast.add({
              severity: 'error',
              summary: 'Error',
              detail: 'El nombre de la transportadora ya existe',
              life: 5000
            });
            return;
          }
          //Crea
          // this.loading = true;
          const crea= await crear("/transportadoras",this.transportadora);
          if (crea === 'Created') {
            this.transportadora = {};
            this.transpotadoraDialog = false;
            this.$toast.add({ severity: 'success', summary: 'Exito!', detail: 'Transportadora Creada', life: 3000 });
            this.obtenerTransportadoras();
          } else {
            this.$toast.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Ha ocurrido un error, no se pudo crear la transportadora 😵',
              life: 3000
            });
          }
        }
      } else {
        this.noEnviar = true;
        this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Porfavor Verifique los campos', life: 3000 });
      }
    },
    async selecciono(productos) {
      this.transportadora.caso_transportadora = productos.map(producto => producto.id);
    },
    editarTransportadora(transportadora) {
      this.logo = null
      this.transportadora = { ...transportadora };
      this.transpotadoraDialog = true;
      this.transportadora.id_user_log = localStorage.getItem("id");
    },
    // Pushing the data to the next page.
    seleccionarTransportadora(event) {
      const id = event.data.id;
      const datos = JSON.stringify(event.data);
      this.$router.push({ name: 'CostoTransportadora', params: { id: id, data: datos } });
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
  },
  /* components: {
    inputProductos,
  }, */
};
</script>