<template>
  <div class="grid flex justify-content-center">
    <div class="col-12">
      <div class="card">
        <Toast />
        <div class="grid p-fluid">
          <div class="flex">
            <h4 class="m-4 ml-1">Productos</h4>
            <div class="field flex align-items-center">
              <label for="recientes" class="field">Nuevos productos</label>
              <InputSwitch
                class="ml-2"
                v-model="clicked"
                v-tooltip="
                  'Haz click para obtener los productos integrados recientemente (1 Día)'
                "
              />
            </div>
          </div>
        </div>
        <DataTable
          ref="dt"
          :value="productos"
          v-model:selection="selectedProducts"
          dataKey="id"
          :paginator="false"
          :rows="20"
          :filters="filters"
          :loading="loading"
          pagingType="numbers"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          responsiveLayout="scroll"
          @sort="handleSort"
        >
          <template #header>
            <div class="flex justify-content-between">
              <div
                class="flex flex-column md:flex-row md:justify-content-between md:align-items-center"
              >
                <Button
                  label="Añadir filtro"
                  icon="pi pi-plus-circle"
                  class="mr-2 p-button-secondary"
                  @click="dialogFiltros = true"
                />
                <div class="flex align-items-center">
                  <p
                    class="mb-0 mr-3"
                    v-if="
                      filtro.referencia ||
                      filtro.nombre ||
                      filtro.id_categoria ||
                      filtro.id_tienda ||
                      filtro.habilitado !== undefined ||
                      filtro.publicado !== undefined
                    "
                  >
                    Filtros actuales:
                  </p>
                  <Tag
                    v-if="filtro.referencia"
                    value="Por referencia"
                    class="mr-2"
                    severity="info"
                  />
                  <Tag
                    v-if="filtro.nombre"
                    value="Por nombre"
                    class="mr-2"
                    severity="info"
                  />
                  <Tag
                    v-if="filtro.id_categoria"
                    value="Por categoría"
                    class="mr-2"
                    severity="info"
                  />
                  <Tag
                    v-if="filtro.id_tienda"
                    value="Por tienda"
                    class="mr-2"
                    severity="info"
                  />
                  <Tag
                    v-if="
                      filtro.habilitado !== undefined &&
                      filtro.habilitado !== null
                    "
                    value="Habilitado"
                    class="mr-2"
                    severity="info"
                  />
                  <Tag
                    v-if="
                      filtro.publicado !== undefined &&
                      filtro.publicado !== null
                    "
                    value="Publicado"
                    class="mr-2"
                    severity="info"
                  />
                </div>
              </div>
              <div v-if="selectedProducts && selectedProducts.length > 0">
                <div class="flex flex-wrap justify-content-center gap-3">
                  <Button
                    label="Editar"
                    @click="dialog = true"
                    icon="pi pi-pencil"
                    class="p-button-warning mr-2"
                    :badge="selectedProducts.length.toString()"
                    badgeClass="p-badge-warning"
                    outlined
                  />
                </div>
              </div>
            </div>
          </template>
          <Column
            selectionMode="multiple"
            headerStyle="width: 3rem"
            v-if="checkbox == true"
          ></Column>
          <Column field="referencia" header="Referencia" :sortable="false">
            <template #body="slotProps">
              <span class="font-semibold">{{ slotProps.data.referencia }}</span>
            </template>
          </Column>
          <Column field="nombre" header="Nombre" :sortable="false" :style="{ width: '250px' }">
            <template #body="slotProps">
              {{ capitalize(slotProps.data.nombre) }}
            </template>
          </Column>
          <Column field="url" header="Imagen principal" :sortable="false">
            <template #body="slotProps">
              <Image
                :src="slotProps.data.media[0]"
                alt="Image"
                width="80"
                preview
                class="rounded-image"
                @error="handleImageError(slotProps)"
              />
            </template>
          </Column>
          <!-- <Column
            field="descripcion"
            header="Descripción"
            :sortable="false"
            style="width: 10%"
          >
            <template #body="slotProps">
              {{ acortarDescripcion(slotProps.data.descripcion) }}
            </template>
          </Column> -->

          <Column field="nombre_categoria" header="Categoría" :sortable="true">
            <template #body="slotProps">
              <Tag
                class="nombre-column"
                severity="danger"
                :value="slotProps.data.categoria"
                v-if="slotProps.data.categoria == 'Error integración'"
              ></Tag>
              <Tag
                severity="warning"
                :value="slotProps.data.categoria"
                v-if="slotProps.data.categoria == 'Sin Categoría'"
              ></Tag>
              <span
                v-if="
                  slotProps.data.categoria !== 'Sin Categoría' &&
                  slotProps.data.categoria !== 'Error integración'
                "
                >{{ slotProps.data.categoria }}</span
              >
            </template>
          </Column>
          <Column field="nombre_amigable" header="Tienda" :sortable="false">
            <template #body="slotProps">
              {{ capitalize(slotProps.data.tienda) }}
            </template>
          </Column>
          <Column
            field="stock_general_producto"
            header="Stock general"
            :sortable="true"
          >
            <template #body="slotProps">
              {{ slotProps.data.stock_general }}
            </template>
          </Column>
          <Column
            field="precio_original"
            header="Precio Original"
            :sortable="true"
          >
            <template #body="slotProps">
              <span
                :class="{
                  'text-red-500 font-semibold':
                    slotProps.data.precio_original == 0,
                }"
              >
                {{ formatoMonedaCOP(slotProps.data.precio_original) }}
              </span>
            </template>
          </Column>
          <Column
            field="precio_descuento"
            header="Precio Descuento"
            :sortable="true"
          >
            <template #body="slotProps">
              <span
                :class="{
                  'text-red-500 font-semibold':
                    slotProps.data.precio_descuento == 0,
                }"
              >
                {{ formatoMonedaCOP(slotProps.data.precio_descuento) }}
              </span>
            </template>
          </Column>

          <Column
            field="porcentaje_descuento"
            header="Porcentaje Descuento"
            :sortable="false"
          >
            <template #body="slotProps">
              {{ slotProps.data.porcentaje_descuento }}%
            </template>
          </Column>
          <Column field="habilitado" header="Habilitado" :sortable="false">
            <template #body="slotProps">
              <em
                class="pi"
                :class="{
                  'text-teal-500 pi-check-circle': slotProps.data.habilitado,
                  'text-red-500 pi-times-circle': !slotProps.data.habilitado,
                }"
              ></em>
            </template>
          </Column>
          <Column field="publicado" header="Publicado" :sortable="false">
            <template #body="slotProps">
              <em
                class="pi"
                :class="{
                  'text-teal-500 pi-check-circle': slotProps.data.publicado,
                  'text-red-500 pi-times-circle': !slotProps.data.publicado,
                }"
              ></em>
            </template>
          </Column>
          <Column field="created_at" header="Fecha creación" :sortable="false">
            <template #body="slotProps">
              {{ formatDate(slotProps.data.created_at) }}
            </template>
          </Column>
          <Column
            field="updated_at"
            header="Fecha actualización"
            :sortable="false"
          >
            <template #body="slotProps">
              {{ formatDate(slotProps.data.updated_at) }}
            </template>
          </Column>
          <Column headerStyle="min-width:10rem;">
            <template #body="slotProps">
              <Button
                icon="pi pi-pencil"
                class="p-button-rounded p-button-success mr-2"
                @click="editarProducto(slotProps.data)"
              />
            </template>
          </Column>
        </DataTable>
        <Paginator
          :rows="1"
          :totalRecords="totalRecord"
          @page="onPage($event)"
        ></Paginator>
        <Dialog
          v-model:visible="userDialog"
          :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
          :style="{ width: '30vw' }"
          :header="formData.nombre ? formData.nombre : 'Crear producto'"
          :modal="true"
          class="p-fluid"
        >
          <div class="grid align-items-center">
            <div class="col-12 md:col-12">
              <div class="field">
                <label for="nombre">Nombre*</label>
                <InputText
                  id="nombre"
                  name="nombre"
                  v-model="formData.nombre"
                  required="true"
                  autofocus
                  :class="{
                    'p-invalid': noEnviar && v$.formData.nombre.$invalid,
                  }"
                />
                <small
                  class="p-error"
                  v-if="noEnviar && v$.formData.nombre.$invalid"
                >
                  El campo nombre es requerido.
                </small>
              </div>
            </div>
            <div class="field col-12 md:col-12">
              <label for="descripcionActividad"
                >Descripción del producto*</label
              >
              <Textarea
                :autoResize="true"
                rows="3"
                cols="30"
                v-model="formData.descripcion"
                :class="{
                  'p-invalid': noEnviar && v$.formData.descripcion.$invalid,
                }"
              />
              <small
                class="p-error"
                v-if="noEnviar && v$.formData.descripcion.$invalid"
              >
                El campo nombre es requerido.
              </small>
            </div>
          </div>
          <div class="grid align-items-center">
            <div class="col-12 md:col-12">
              <div class="field">
                <label for="categoria">Categoría*</label>
                <Dropdown
                  v-model="formData.id_categoria"
                  filter
                  :options="categorias"
                  optionLabel="nombre_amigable"
                  optionValue="id"
                  :placeholder="formData.categoria"
                  checkmark
                  :highlightOnSelect="false"
                />
              </div>
            </div>
          </div>
          <div class="grid align-items-center">
            <div class="col-12 md:col-6">
              <div class="field">
                <label for="Publicado">Publicado</label>
                <ToggleButton
                  style="text-align: justify"
                  v-model="formData.publicado"
                  onLabel="Si"
                  offLabel="No"
                />
              </div>
            </div>
            <div class="col-12 md:col-6">
              <div class="field">
                <label for="Habilitado">Habilitado</label>
                <ToggleButton
                  style="text-align: justify"
                  v-model="formData.habilitado"
                  onLabel="Si"
                  offLabel="No"
                />
              </div>
            </div>
          </div>
          <template #footer>
            <Button
              label="Cancelar"
              icon="pi pi-times"
              class="p-button-text"
              @click="hideDialog"
            />
            <Button
              label="Guardar"
              icon="pi pi-check"
              class="p-button-text"
              @click="guardarProducto(!v$.$invalid)"
            />
          </template>
        </Dialog>
      </div>
      <Dialog
        v-model:visible="dialog"
        modal
        header="Edición Masiva"
        :style="{ width: '50rem' }"
      >
        <!-- <span class="p-text-secondary block mb-5">Update your information.</span> -->
        <div class="flex align-items-center mb-3 gap-3">
          <i
            class="pi text-yellow-500 pi-exclamation-circle"
            style="color: red; font-size: 1.5rem"
            v-tooltip.left="
              'Solo son visibles los productos que han sido habilitados y publicados'
            "
          ></i>
          <!-- <InputSwitch class="ml-2" v-model="clickedHabilitado" /> -->
          <label for="habilitar" class="ml-2"> Habilitar </label>
          <Dropdown
            v-model="actualizacionEstadosHabilitadosSeleccionado"
            :options="actualizacionEstadosHabilitados"
            optionLabel="name"
            placeholder="Seleccionar..."
            class="w-full md:w-14rem"
          />
          <label for="publicar" class="ml-2"> Publicar </label>
          <Dropdown
            v-model="actualizacionEstadosPublicadosSeleccionado"
            :options="actualizacionEstadosPublicados"
            optionLabel="name"
            placeholder="Seleccionar..."
            class="w-full md:w-14rem"
          />
        </div>
        <div class="flex align-items-center mb-3 gap-3">
          <!-- <InputSwitch class="ml-2" v-model="clickedPublicado" /> -->
        </div>
        <div class="flex align-items-center mb-3 gap-3">
          <!-- <InputSwitch class="ml-2" v-model="clickedPublicado" /> -->
          <label for="Categoria" class="ml-6"> Categoría </label>
          <Dropdown
            v-model="formEditProducts.id_categoria"
            filter
            :options="categorias"
            optionLabel="nombre"
            optionValue="id"
            placeholder="Seleccionar..."
            class="w-full md:w-14rem"
          />
        </div>
        <div class="flex justify-content-end gap-2">
          <Button
            type="button"
            label="Cancelar"
            severity="secondary"
            @click="dialog = false"
          />
          <Button
            type="button"
            label="Guardar"
            icon="pi pi-check"
            class="p-button-success mr-2"
            :badge="selectedProducts.length.toString()"
            badgeClass="p-badge-success"
            outlined
            @click="
              obtenerElementosSeleccionados(
                actualizacionEstadosHabilitadosSeleccionado.code,
                actualizacionEstadosPublicadosSeleccionado.code,
                formEditProducts.id_categoria
              )
            "
          />
        </div>
      </Dialog>
      <Dialog
        v-model:visible="dialogFiltros"
        :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
        :style="{ width: '40vw' }"
        modal
        header="Filtros de búsqueda"
        class="p-fluid"
      >
        <div class="grid p-fluid">
          <div class="col-12 md:col-6">
            <div class="field">
              <label for="referencia">Referencia</label>
              <InputText
                placeholder="Escribe para filtrar por referencia"
                id="referencia"
                name="referencia"
                v-model="filtro.referencia"
              />
            </div>
          </div>
          <div class="col-12 md:col-6">
            <div class="field">
              <label for="nombre">Nombre</label>
              <InputText
                id="nombre"
                name="nombre"
                v-model="filtro.nombre"
                placeholder="Escribe para filtrar por nombre"
              />
            </div>
          </div>
        </div>
        <div class="grid p-fluid">
          <div class="col-12 md:col-6">
            <div class="field">
              <label for="habilitado">Habilitado</label>
              <Dropdown
                v-model="estadoHabilitadosSeleccionado"
                :options="estadosHabilitados"
                optionLabel="name"
                placeholder="Seleccionar..."
              />
            </div>
          </div>
          <div class="col-12 md:col-6">
            <div class="field">
              <label for="publicado">Publicado</label>
              <Dropdown
                v-model="estadoPublicadosSeleccionado"
                :options="estadosPublicados"
                optionLabel="name"
                placeholder="Seleccionar..."
              />
            </div>
          </div>
        </div>
        <div class="grid p-fluid">
          <div class="col-12 md:col-6">
            <div class="field">
              <label for="categoria">Categoría</label>
              <Dropdown
                v-model="filtro.id_categoria"
                :options="categoriafiltro"
                optionLabel="nombre_amigable"
                filter
                optionValue="id"
                placeholder="Seleccionar..."
              />
            </div>
          </div>
          <div class="col-12 md:col-6">
            <div class="field">
              <label for="tienda">Tienda</label>
              <Dropdown
                v-model="filtro.id_tienda"
                filter
                :options="tiendafiltro"
                optionLabel="nombre_amigable"
                optionValue="id"
                placeholder="Seleccionar..."
              />
            </div>
          </div>
        </div>
        <!-- <template #footer>
          <Button
            label="Limpiar"
            icon="pi pi-trash"
            class="mr-2 p-button-secondary"
            @click="resetarFiltros()"
          />
          <Button
            label="Buscar"
            icon="pi pi-search"
            class="w-full"
            @click="filtrar()"
          />
        </template> -->
        <template #footer>
          <Button
            label="Limpiar"
            icon="pi pi-trash"
            class="p-button-secondary"
            @click="resetarFiltros()"
          />
          <Button
            label="Buscar"
            icon="pi pi-search"
            class="p-button-primaryp-image-preview-indicator"
            @click="filtrar()"
          />
        </template>
        <!-- <div class="flex mt-2">
          <Button
            label="Limpiar"
            icon="pi pi-trash"
            class="mr-2 p-button-secondary"
            @click="resetarFiltros()"
          />
          <Button
            label="Buscar"
            icon="pi pi-search"
            class="w-full"
            @click="filtrar()"
          />
        </div> -->
      </Dialog>
    </div>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { FilterMatchMode } from "primevue/api";
import { obtenerTodo, crear } from "@/api/clienteHttpService";
import { required } from "@vuelidate/validators";
import { decrypt } from "@/auth-guard";

import {
  actualizarProductosBackoffice,
  obtenerProductosFiltrados,
} from "@/api/productosServices";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      paginador: false,
      clicked: false,
      clickedHabilitado: false,
      clickedPublicado: false,
      total_productos: null,
      noEnviar: false,
      checkbox: false,
      ordernador: null,
      id_user: decrypt(localStorage.getItem("id")).id,

      estadoHabilitadosSeleccionado: {
        code: null,
      },

      tiendaSeleccionada: {
        id: null,
      },

      estadosHabilitados: [
        { name: "Habilitado", code: true },
        { name: "Inhabilitado", code: false },
      ],
      estadoPublicadosSeleccionado: {
        code: null,
      },

      estadosPublicados: [
        { name: "Habilitado", code: true },
        { name: "Inhabilitado", code: false },
      ],

      actualizacionEstadosHabilitados: [
        { name: "Seleccionar...", code: null },
        { name: "Habilitado", code: true },
        { name: "Inhabilitado", code: false },
      ],

      actualizacionEstadosHabilitadosSeleccionado: {
        code: null,
      },

      actualizacionEstadosPublicados: [
        { name: "Seleccionar...", code: null },
        { name: "Habilitado", code: true },
        { name: "Inhabilitado", code: false },
      ],

      actualizacionEstadosPublicadosSeleccionado: {
        code: null,
      },

      formData: {},
      productosCategoria: {},
      filtrosAplicados: {},
      formEditProducts: {},
      cat: null,
      categoria_seleccionada: null,
      categoria: null,
      tiendas: null,
      Imagen: null,
      messages: {
        severity: "",
        content: "",
      },
      loading: true,
      productos: null,
      userDialog: false,
      dialog: false,
      dialogFiltros: false,
      selectedProducts: null,
      filters: {},
      submitted: false,
      sortOptions: [
        { label: "Price High to Low", value: "!price" },
        { label: "Price Low to High", value: "price" },
      ],
      filtro: {},
      habilitados: true,
      publicados: true,
      dehabilitados: false,
      nopublicados: false,
      totalRecord: null,
      caracteristicasFiltradas: [],
      categoriafiltro: null,
      tiendafiltro: null,
    };
  },
  validations() {
    return {
      formData: {
        nombre: { required },
        descripcion: { required },
      },
    };
  },
  created() {
    this.obtenerProductos();
    this.obtenerCatPro();
    this.initFilters();
    this.obtenerCategoriaFiltro();
    this.obtenerTiendaFiltro();
  },
  methods: {
    formatDate(dateString) {
      const fecha = new Date(dateString);
      const opciones = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        fractionalSecondDigits: 3,
      };
      return new Intl.DateTimeFormat("es-ES", opciones).format(fecha);
    },
    capitalize(text) {
      if (!text) return "";
      return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
    },
    handleImageError(slotProps) {
      slotProps.data.media[0] =
        "https://www.unico.com.co/logos/Nuevo-logo-unico.com.co-2023.webp";
    },

    async obtenerElementosSeleccionados(habilitado, publicado, id_categoria) {
      if (this.selectedProducts.length > 0) {
        const idsSeleccionados = this.selectedProducts.map((item) => item.id);
        let resp = await crear(
          "/productos/tienda",
          {
            productos: idsSeleccionados,
            habilitado: habilitado,
            publicado: publicado,
            category_id: id_categoria,
          },
          "multipart/form-data"
        );
        if (resp == 200) {
          this.$toast.add({
            severity: "success",
            summary: "Correcto",
            detail: `Se han actualizado ${this.selectedProducts.length} productos`,
            life: 5000,
          });
          this.selectedProducts = [];
          this.obtenerProductos();
          this.actualizacionEstadosHabilitados.code = null;
          this.actualizacionEstadosPublicados.code = null;
          this.actualizacionEstadosHabilitadosSeleccionado = null;
          this.actualizacionEstadosPublicadosSeleccionado = null;
          this.formEditProducts.id_categoria = null;
          this.dialog = false;
        } else {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "Ha ocurrido un error",
            life: 5000,
          });
        }
      } else {
        this.$toast.add({
          severity: "warn",
          summary: "Alerta",
          detail: "Debes seleccionar al menos un elemento",
          life: 5000,
        });
      }
    },
    acortarDescripcion(descripcion) {
      const limite = 60;
      if (descripcion && descripcion.length > limite) {
        return descripcion.slice(0, limite) + "...";
      } else {
        return descripcion;
      }
    },
    async obtenerProductos(paginado = 0) {
      this.loading = true;
      this.filtro.paginado = paginado;
      const resp = await obtenerProductosFiltrados(this.filtro);
      // const paginado = "?paginado=" + pagina;
      // const resp = await obtenerTodo("/productos/backoffice/" + paginado);
      this.productos = resp.productos;
      this.total_productos = resp.total_productos;
      //Omitir la ultima posicion
      // if (pagina == 0) {
      //   const rangoTotal = this.rango[0].total / this.rango[0].paginado;
      //   this.totalRecords = Math.round(rangoTotal);
      // }
      // this.rango = resp.paginas;
      this.totalRecord = resp.paginas;
      this.loading = false;
    },
    async onPage(event) {
      await this.obtenerProductos(event.page);
    },

    async filtrar() {
      this.filtro = {
        referencia: this.filtro.referencia,
        nombre: this.filtro.nombre,
        id_categoria: this.filtro.id_categoria,
        id_tienda: this.filtro.id_tienda,
        habilitado: this.estadoHabilitadosSeleccionado.code,
        publicado: this.estadoPublicadosSeleccionado.code,
        paginado: this.totalRecord,
        recientes: this.clicked,
        orden: this.ordernador,
      };
      const claveFiltro = JSON.stringify(this.filtro);
      if (this.filtrosAplicados == claveFiltro) {
        this.$toast.add({
          severity: "warn",
          summary: "Alerta",
          detail: "Este filtro ya se ha aplicado",
          life: 5000,
        });
        return;
      }
      this.filtrosAplicados = claveFiltro;
      this.loading = true;
      this.paginador = true;
      this.obtenerProductos();
      if (
        (this.filtro.referencia == null || this.filtro.referencia == "") &&
        this.filtro.habilitado == null &&
        this.filtro.publicado == null &&
        this.filtro.id_categoria == null &&
        this.filtro.id_tienda == null &&
        this.filtro.recientes == false
      ) {
        this.checkbox = false;
      } else {
        this.checkbox = true;
      }
      this.dialogFiltros = false;
    },
    resetarFiltros() {
      this.filtro = {};
      this.estadoHabilitadosSeleccionado = {
        code: null,
      };
      this.estadoPublicadosSeleccionado = {
        code: null,
      };
    },
    async obtenerCatPro() {
      const resp = await obtenerTodo("/productos/backoffice/categorias");
      this.categorias = resp;
    },
    async obtenerCategoriaFiltro() {
      const resp = await obtenerTodo("/productos/backoffice/categorias");
      this.categoriafiltro = resp;
    },
    async obtenerTiendaFiltro() {
      const resp = await obtenerTodo("/tiendas");
      resp.map((tienda) => {
        return {
          id: tienda.id,
          nombre: tienda.nombre_amigable,
        };
      });
      this.tiendafiltro = resp;
    },

    filtroPorCategoria(ruta) {
      const results = this.productos.filter((producto) => {
        return producto.ruta.includes(ruta);
      });
      this.productosCopy = this.productos;
      this.productos = results;
    },
    editarProducto(formData) {
      this.formData = { ...formData };
      this.caracteristicas = this.formData.caracteristicas;
      let filtradas = [];
      for (const key in this.caracteristicas) {
        for (const key2 in this.caracteristicas[key]) {
          filtradas.push({
            id: this.caracteristicas[key][key2].id,
            valor: this.caracteristicas[key][key2].valor,
          });
        }
      }
      this.caracteristicasFiltradas = filtradas;
      this.userDialog = true;
    },
    formatoMonedaCOP(valor) {
      return new Intl.NumberFormat("es-CO", {
        style: "currency",
        currency: "COP",
        minimumFractionDigits: 0,
      }).format(valor);
    },
    handleSort(event) {
      if (event.sortField == "stock_general_producto") {
        if (event.sortOrder == 1) {
          this.ordernador = "1";
        } else {
          this.ordernador = "2";
        }
        this.filtrar();
      }
      if (event.sortField == "precio_original") {
        if (event.sortOrder == 1) {
          this.ordernador = "3";
        } else {
          this.ordernador = "4";
        }
        this.filtrar();
      }
      if (event.sortField == "precio_descuento") {
        if (event.sortOrder == 1) {
          this.ordernador = "5";
        } else {
          this.ordernador = "6";
        }
        this.filtrar();
      }
      if (event.sortField == "nombre_categoria") {
        if (event.sortOrder == 1) {
          this.ordernador = "7";
        } else {
          this.ordernador = "8";
        }
        this.filtrar();
      }
    },
    async guardarProducto(isFormValid) {
      try {
        this.noEnviar = true;
        if (isFormValid) {
          this.userDialog = false;
          this.loading = true;
          this.productosCategoria = {
            id: this.formData.id,
            nombre: this.formData.nombre,
            descripcion: this.formData.descripcion,
            id_categoria: this.formData.id_categoria,
            habilitado: this.formData.habilitado,
            publicado: this.formData.publicado,
            id_tienda: this.formData.id_tienda,
            resumen: this.formData.resumen,
            marca: this.formData.marca,
            meta_titulo: this.formData.meta_titulo,
            meta_descripcion: this.formData.meta_descripcion,
            nuevo: this.formData.nuevo,
            precio_original: this.formData.precio_original,
            precio_descuento: this.formData.precio_descuento,
            porcentaje_descuento: this.formData.porcentaje_descuento,
            etiquetas: this.formData.etiquetas,
            id_user_log: this.id_user,
            media: null,
            caracteristicas: this.caracteristicasFiltradas,
          };
          await actualizarProductosBackoffice(this.productosCategoria);
          this.$toast.add({
            severity: "success",
            summary: "Correcto",
            detail: "Producto actualizado con exito",
            life: 5000,
          });
          this.loading = false;
          this.obtenerProductos();
        }
      } catch (error) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Ha ocurrido un error",
          life: 5000,
        });
      }
    },
    handleChange(event) {
      this.categoria_seleccionada = event.value;
      this.formData.ruta = this.categoria_seleccionada[0].id;
    },
    hideDialog() {
      this.userDialog = false;
      this.submitted = false;
      this.formData = {};
    },
    subirImagen(event) {
      this.Imagen = event.files[0].objectURL;
      this.formData.imagen = event.files[0];
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
  },
};
</script>
<style scoped>
.field {
  margin-bottom: 0;
}
.flex-custom {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.nombre-column {
  display: flex;
  flex-grow: 0.5;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

</style>
