import { ref } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";

const useBannerSuperior = () => {
  const AgregarEnlace = ref(false);

  const formBannerSuperior = ref({
    textoBanner: "",
    textEnlace: "",
    urlEnlace: "",
  });

  const rules = {
    textoBanner: {
      required: helpers.withMessage("Escribe el mensaje del banner", required),
    },
    textEnlace: {
      required: helpers.withMessage("Escribe el mensaje del banner", required),
    },
    urlEnlace: {
      required: helpers.withMessage("Escribe el mensaje del banner", required),
    },
  };

  const v$ = useVuelidate(rules, formBannerSuperior);

  const guardarDatosBanner = () => {
    console.log(formBannerSuperior.value);
  };

  return { AgregarEnlace, v$, formBannerSuperior, guardarDatosBanner };
};

export default useBannerSuperior;
