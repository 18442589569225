<!-- Para usar este componente solo llamelo y uselo de la siguinete manera: 
  <unidadSeleccionada v-model="variable_a_asiganr valor" /> 
-->
<template>
  <Dropdown id="unidad_medida" name="unidad_medida" :options="unidades" optionLabel="nombre" optionValue="nombre"
    placeholder="Seleccione una medida" :filter="true" :loading="loading" />
</template>
<script>
import { obtenerTodo } from "@/api/clienteHttpService.js";
export default {
  name: 'unidadSeleccionada',
  data: () => ({
    unidades: [],
    loading: true
  }),
  created() {
    this.obtenerUnidades();
  },
  methods: {
    /* Un método que se llama cuando se crea el componente. 
      Está usando el método `obtenerTodo`  de el archivo `clienteHttpService.js` 
      para obtener todas las unidades de medida de la API. 
    */
    async obtenerUnidades() {
      this.unidades = await obtenerTodo("/unidadmedida");
      this.loading = false;
    },
  }
}
</script>