<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <Toast />
        <Toolbar class="mb-4">
          <template v-slot:start>
            <h5 class="m-4">Politicas</h5>
          </template>
          <template v-slot:end>
            <Button id="crearTrasnportadora" label="Crear politica" icon="pi pi-plus" class="p-button-success mr-2"
              @click="nuevaPolitica()" />
            <Button icon="pi pi-external-link" label="Exportar" @click="exportCSV($event)" />
          </template>
        </Toolbar>
        <!-- Inicio tabla listado de politica -->
        <DataTable ref="dt" :value="politicas" stripedRows v-model:selection="politicaSeleccionada" dataKey="id"
          :paginator="true" :rows="10" :filters="filters" :loading="loading"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 25]"
          currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords} politicas" responsiveLayout="scroll"
          breakpoint="1200px">
          <template #header>
            <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
              <span class="block mt-2 md:mt-0 p-input-icon-left">
                <i class="pi pi-search" />
                <InputText v-model="filters['global'].value" placeholder="Buscar..." />
              </span>
            </div>
          </template>
          <Column selectionMode="multiple"></Column>
          <!-- Nombre de la columna politica -->
          <Column field="titulo" header="Titulo" :sortable="true">
            <template #body="slotProps">
              {{ slotProps.data.titulo }}
            </template>
          </Column>
          <Column field="nombre" header="Politica" :sortable="true">
            <template #body="slotProps">
              {{ slotProps.data.nombre }}
            </template>
          </Column>
          <Column field="descripcion" header="Descripción Politica" :sortable="true">
            <template #body="slotProps">
              {{ slotProps.data.descripcion }}
            </template>
          </Column>
          <Column field="texto" header="Texto Politica" :sortable="true" :expander="true" headerStyle="width: 40%">
            <template #body="slotProps">
              <p class="texto-largo">
                {{ slotProps.data.texto }}
              </p>
            </template>
          </Column>
          <Column field="versión_politica" header="Versión Politica" :sortable="true">
            <template #body="slotProps">
              {{ slotProps.data.version }}
            </template>
          </Column>
          <Column field="fecha_expedición" header="Fecha expedición" :sortable="true">
            <template #body="slotProps">
              {{ new Intl.DateTimeFormat('es-CO').format(new Date(slotProps.data.fecha_expedida)) }}
            </template>
          </Column>
          <Column field="habilitado" header="Habilitada" :sortable="true">
            <template #body="slotProps">
              <em class="pi" :class="{
                'text-teal-500 pi-check-circle': slotProps.data.habilitado,
                'text-red-500 pi-times-circle': !slotProps.data.habilitado,
              }"></em>
            </template>
          </Column>
          <Column>
            <template #body="slotProps">
              <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2"
                @click="editarPolitica(slotProps.data)" />
            </template>
          </Column>
        </DataTable>
        <!-- Fin tabla listado de politicas -->

        <!-- Inicio modal formulario crear o editar politicas -->
        <Dialog v-model:visible="politicaDialog" :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
          :style="{ width: '60vw' }" :header="politica.nombre ? politica.nombre : 'Crear politica'" :modal="true"
          class="p-fluid">

          <div class="grid">
            <div class="col-12 ">
              <div class="field">
                <label for="titulo">Titulo*</label>
                <InputText id="titulo" name="titulo" v-model.trim="politica.titulo" required="true" type="text"
                  spellcheck="false" autofocus :class="{ 'p-invalid': noEnviar && v$.politica.titulo.$invalid }" />
                <small class="p-error" v-if="noEnviar && v$.politica.titulo.$invalid">
                  El campo nombre es requerido.
                </small>
              </div>
            </div>
            <div class="col-12 ">
              <div class="field">
                <label for="nombre">Nombre*</label>
                <InputText id="nombre" name="nombre" v-model.trim="politica.nombre" required="true" type="text"
                  spellcheck="false" autofocus :class="{ 'p-invalid': noEnviar && v$.politica.nombre.$invalid }" />
                <small class="p-error" v-if="noEnviar && v$.politica.nombre.$invalid">
                  El campo nombre es requerido.
                </small>
              </div>
            </div>

            <div class="col-12">
              <div class="field">
                <label for="descripcion">Descripción</label>
                <InputText id="descripcion" name="descripcion" v-model.trim="politica.descripcion" />
              </div>
            </div>

            <div class="col-12">
              <div class="field">
                <label for="descripcion">Texto de la politica</label>
                <Editor v-model.trim="politica.texto" />
              </div>
            </div>

            <div class="col-12 xl:col-4">
              <div class="field">
                <label for="version">Versión*</label>
                <InputText id="version" name="version" v-model.trim="politica.version" placeholder="Version 1.0"
                  :class="{ 'p-invalid': noEnviar && v$.politica.version.$invalid }" />
                <small class="p-error" v-if="noEnviar && v$.politica.version.$invalid">
                  Porfavor especifique una version para la politica.
                </small>
              </div>
            </div>

            <div class="col-12 md:col-6 xl:col-4">
              <div class="field">
                <label for="fecha_expedición">Fecha expedición</label>
                <Calendar id="fecha_expedición" name="fecha_expedición" v-model="politica.fecha_expedida"
                  :showIcon="true" :dateFormat="'dd/mm/yy'" :maxDate="fecha_actual" :manualInput="false"
                  placeholder="DD/MM/YYYY" :class="{ 'p-invalid': noEnviar && v$.politica.fecha_expedida.$invalid }" />
                <small class="p-error" v-if="noEnviar && v$.politica.fecha_expedida.$invalid">
                  El campo fecha de expedición no puede estar vacio.
                </small>
              </div>
            </div>

            <div class="col-12 md:col-6  xl:col-4">
              <div class="field">
                <label for="habilitado">¿Habilitado?</label>
                <ToggleButton v-model="politica.habilitado" onLabel="Si" offLabel="NO" onIcon="pi pi-check"
                  offIcon="pi pi-times" />
              </div>
            </div>

          </div>

          <template #footer>
            <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="ocultarDialog()" />
            <Button label="Guardar" icon="pi pi-check" class="p-button-text" @click="guardarPolitica(!v$.$invalid)" />
          </template>

        </Dialog>
        <!-- Fin formulario crear o editar politicas -->
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { FilterMatchMode } from "primevue/api";
import { obtenerTodo, crear, actualizar } from "@/api/clienteHttpService";
import { decrypt } from "@/auth-guard";
export default {
  name: 'politicas',
  setup() {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      politicas: null,
      fecha_actual: new Date(),
      loading: true,
      cargando: false,
      modalPolitas: false,
      politica: {
        id_user_log: decrypt(localStorage.getItem('id'))
      },
      politicaDialog: false,
      politicaSeleccionada: null,
      filters: {},
      noEnviar: false,
    }
  },
  validations() {
    return {
      politica: {
        nombre: { required },
        version: { required },
        fecha_expedida: { required },
      },
    }
  },
  async created() {
    this.initFilters();
    this.obtenerPoliticas();
  },
  methods: {
    nuevaPolitica() {
      this.politicaDialog = true;
      this.politica = {};
      this.politica.id_user_log = decrypt(localStorage.getItem('id'));
    },
    ocultarDialog() {
      this.politicaDialog = false;
    },

    async obtenerPoliticas() {
      this.loading = true;
      const resp = await obtenerTodo("/politicas");
      this.politicas = resp;
      this.loading = false;
    },
    async guardarPolitica(isFormValid) {
      if (isFormValid) {
        this.noEnviar = false;
        if (this.politica.id) {
          //Actualiza
          const actualiza = await actualizar("/politicas", this.politica);
          if (actualiza === 'OK') {
            this.politica = {};
            this.politicaDialog = false;
            this.obtenerPoliticas();
            this.$toast.add({ severity: 'success', summary: 'Éxito', detail: 'Los datos de la Politica fueron actualizados', life: 3000 });
          }
        } else {
          //Verifica que la politica no exista.
          if (this.politicas.find(politica => politica.nombre === this.politica.nombre && politica.version === this.politica.version)) {
            this.$toast.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Ya existe una politica con ese nombre y esa versión',
              life: 5000
            });
            return;
          }
          //Crea
          this.loading = true;
          const crea = await crear("/politicas", this.politica);
          console.log(this.politica.fecha_expedida);
          if (crea === 'Created') {
            this.politica = {};
            this.politicaDialog = false;
            this.$toast.add({ severity: 'success', summary: 'Exito!', detail: 'Politica Creada', life: 3000 });
            this.obtenerPoliticas();
          } else {
            this.$toast.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Ha ocurrido un error, no se pudo crear la politica ❌',
              life: 3000
            });
          }
          this.loading = false;
        }
      } else {
        this.noEnviar = true;
        this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Porfavor verifique los campos', life: 3000 });
      }
    },
    editarPolitica(politica) {
      this.politica = { ...politica };
      this.politicaDialog = true;
      this.politica.id_user_log = decrypt(localStorage.getItem('id'));
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
  },
};
</script>
<style scoped>
.texto-largo {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 80ch;
}
</style>