<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <Toast />
        <Toolbar class="mb-4">
          <template v-slot:start>
            <h5 class="m-4">Banner superior</h5>
          </template>
          <template v-slot:end>
            <Button
              id="crearBanner"
              label="Crear Banner"
              icon="pi pi-plus"
              class="p-button-success mr-2"
              @click="abrirModalCrearBanner()"
            />
          </template>
        </Toolbar>
        <!-- Inicio tabla listado de banners -->

        <!-- Inicio modal formulario crear o editar banners -->
        <Dialog
          v-model:visible="bannerDialog"
          :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
          :style="{ width: '60vw' }"
          header="Banner superior"
          :modal="true"
          class="p-fluid"
        >
          <formCrearBannerSuperior />
        </Dialog>
        <!-- Fin formulario crear o editar transportadoras -->
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
// import { obtenerTodo } from "@/api/clienteHttpService";
import formCrearBannerSuperior from "../../seccionesBannersHome/banner-superior/formCrearBannerSuperior.vue";

// const banner = ref(null);
// const banners = ref();
// const loading = ref(false);
const bannerDialog = ref(false);

const abrirModalCrearBanner = () => {
  bannerDialog.value = !bannerDialog.value;
};

// id_user: decrypt(localStorage.getItem("id")).id,

// const obtenerBanners = async () => {
//   loading.value = true;
//   const resp = await obtenerTodo("/banners");
//   banners.value = resp;
//   loading.value = false;
// };

// onMounted(() => {
//   obtenerBanners();
// });
</script>
<style scoped>
.texto-largo {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 80ch;
}
</style>
