import instance from "./services";

//Servicios Http para Productos

const URL = "/productos/backoffice";
const URL2= "/productos";
const URL3 = "/productos/backoffice/filtro";
const URL4 = "/scheduling/ejecutarIntegracion";
const URL5 = "/productos/integraciones/exportDoofinder";


//Obtener todas las Productos
const obtenerProductos = async () => {
  try {
    const resp = await instance.get(URL, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    });
    return resp.data;
  } catch (error) {
    return error.response.statusText;
  }
};

const actualizarDoofinder = async () => {
  try {
    const resp = await instance.get(URL5, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    });
    return resp.data;
  } catch (error) {
    return error.response.statusText;
  }
};

//Obtener todas las Productos
const obtenerProductosFiltrados = async (datos) => {
  try {
    const resp = await instance.post(URL3,datos, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    });
    return resp.data;
  } catch (error) {
    return error.response.statusText;
  }
};

//Obtener todas las Categorias de productos
const obtenerCategoriasProductos = async () => {
  try {
    const resp = await instance.get(URL, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    });
    return resp.data;
  } catch (error) {
    return error.response.statusText;
  }
};
//Crear una nueva Producto
const crearProductos = async (datos) => {
  try {
    const resp = await instance.post(URL, datos, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token"), "Content-Type": "multipart/form-data" },
    });
    return resp.statusText;
  } catch (error) {
    return error.response.statusText;
  }
}

const ejecutarIntegracion = async (datos) => {
  try {
    const resp = await instance.post(URL4, datos, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token"), "Content-Type": "multipart/form-data" },
    });
    return resp.statusText;
  } catch (error) {
    return error.response.statusText;
  }
}

//Actualizar una Producto
const actualizarProductos = async (datos) => {
  try {
    const resp = await instance.post(URL + '?_method=PUT', datos, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token"), "Content-Type": "multipart/form-data" },
    });
    return resp.statusText;
  } catch (error) {
    return error.response.statusText;
  }
}

//Actualizar una Producto
const actualizarProductosBackoffice = async (datos) => {
  try {
    const resp = await instance.post(URL2 + '?_method=PUT', datos, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token"), "Content-Type": "multipart/form-data" },
    });
    return resp.statusText;
  } catch (error) {
    return error.response.statusText;
  }
}

//Eliminar una Producto
const eliminarProductos = async (id) => {
  try {
    const resp = await instance.delete(`/transportadoras/${id}`, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    });
    return resp.statusText;
  } catch (error) {
    return error.response.statusText;
  }
}

export { obtenerProductos, crearProductos, actualizarProductos, eliminarProductos,obtenerCategoriasProductos,actualizarProductosBackoffice,obtenerProductosFiltrados, ejecutarIntegracion, actualizarDoofinder };
