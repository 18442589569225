<template>
  <form class="form-crear-banner">
    <div class="input-form">
      <label for="mensaje-banner">Texto banner</label>
      <InputText
        id="mensaje-banner"
        type="text"
        v-model="v$.textoBanner.$model"
        autocomplete="off"
      />

      <small class="texto_error" v-if="v$.textoBanner.$error">
        {{ v$.textoBanner.$errors[0].$message }}
      </small>
    </div>

    <div class="input-form">
      <label for="mensaje-banner">Agregar enlace</label>
      <InputSwitch v-model="AgregarEnlace" />
    </div>

    <section v-if="AgregarEnlace" class="form-banner">
      <div class="input-form">
        <label for="mensaje-enlace">Texto enlace</label>
        <InputText
          id="mensaje-enlace"
          type="text"
          v-model="formBannerSuperior.textEnlace"
          autocomplete="off"
          placeholder="Texto enlace"
        />
      </div>

      <div class="input-form">
        <label for="mensaje-enlace">Url enlace</label>
        <InputText
          id="mensaje-enlace"
          type="text"
          v-model="formBannerSuperior.urlEnlace"
          autocomplete="off"
          placeholder="Http://"
        />
      </div>
    </section>

    <hr />

    <section class="botones-form">
      <Button
        label="Guardar"
        outlined
        class="btn"
        icon="pi pi-save"
        :disabled="v$.$error || v$.$invalid"
        @click="guardarDatosBanner"
      />
      <Button label="Cancelar" severity="secondary" class="btn" />
    </section>
  </form>
</template>

<script setup>
import useBannerSuperior from "../../../composables/banner-superior/useBannerSuperior";

const { AgregarEnlace, guardarDatosBanner, v$, formBannerSuperior } =
  useBannerSuperior();
</script>

<style scoped>
@import "../../../assets//styles/banner-superior/formCrearBanner.css";
</style>
