<script>
import {FilterService, FilterMatchMode} from 'primevue/api';
export default {
    props: ['selectedItems', 'itemsInDB', 'country'],
    data: () => ({
        ciudadSeleccionada: null,
        ciudades: [],
        loading: true
    }),
    created() {
        this.obtenerCiudades().then(data => this.ciudades = data);
        this.findItemsOfDB(this.itemsInDB);
    },
    methods: {
        obtenerCiudades(){
            return fetch(`../data/ciudades-${this.country}.json`).then(res => res.json()).then(d => d.data);
        },
        buscarCiudad(event) {
            let query = event.query;
            let filtroCiudades = [];
            for (let departamentos of this.ciudades){
                let filtroItems = FilterService.filter(departamentos.items, ['label'], query, FilterMatchMode.CONTAINS);
                if (filtroItems && filtroItems.length) {
                    filtroCiudades.push({...departamentos, ...{items: filtroItems}});
                }
            }
            this.filtroCiudades = filtroCiudades;
		},
        findItemsOfDB(itemsInDB){
            this.ciudadSeleccionada = itemsInDB;
        },
        handleChange(event) {
            this.selectedItems(event.value.value);
        }
    }
}
</script>
<template>
    <AutoComplete v-model="ciudadSeleccionada" :suggestions="filtroCiudades" :forceSelection="true" @item-select="handleChange" @complete="buscarCiudad($event)" field="value" optionGroupLabel="label" optionGroupChildren="items" /> 
</template>