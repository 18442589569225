import instance from "./services";
import { encrypt, decrypt } from "../auth-guard";

const loginServices = async (email, password, remember_me) => {
  try {
    const resp = await instance.post("auth/login", {
      email: email,
      password: password,
      remember_me: remember_me,
    });

    if (resp.status == "200") {
      const token = {
        token: resp.data.access_token,
        expires_at: resp.data.expires_at,
      };
      localStorage.setItem("token", encrypt(token));
      return resp.status;
    }
  } catch (error) {
    return error.statusText;
  }
};

const userServices = async () => {
  try {
    const token = decrypt(localStorage.getItem("token"));
    const resp = await instance.get("auth/user", {
      headers: { Authorization: "Bearer " + token.token },
    });
    const id = {
      id: resp.data.id,
      name: resp.data.name,
      id_rol: resp.data.roles[0].id,
      roles: resp.data.roles,
      name_rol: resp.data.roles[0].name,
      id_tienda: resp.data.id_tienda,
      name_tienda: resp.data.name_tienda,
    };
    localStorage.setItem("id", encrypt(id));
    localStorage.setItem("id_rol", id.id_rol);
    for (let index = 0; index < id.roles.length; index++) {
      localStorage.setItem("roles_"+index, id.roles[index].name);
    }
    localStorage.setItem("store_key", resp.data.store_key);
    if (!localStorage.getItem("dark_mode")) {
      localStorage.setItem("dark_mode", false);
    }
    return resp.statusText;
  } catch (error) {
    return error.statusText;
  }
};

const resetPassServices = async (email) => {
  try {
    const resp = await instance.post("password/create", {
      email: email,
    });

    if (resp.status == "200") {
      return resp.status;
    }
  } catch (error) {
    return error.statusText;
  }
};
const changePassServices = async (req) => {
  try {
    const resp = await instance.post("password/reset", {
      email: req.email,
      password: req.password,
      password_confirmation: req.password_confirmation,
      token: req.token,
    });

    if (resp.status == "200") {
      return resp.statusText;
    }
  } catch (error) {
    return error.statusText;
  }
};

export { loginServices, resetPassServices, changePassServices, userServices };
