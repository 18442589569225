<template>
  <div class="grid">
    <div class="col-12">
      <div class="card" style="min-width: 320px">
        <Toast />
        <Toolbar class="mb-4">
          <template v-slot:start>
            <h4 class="m-4">Costo Transportadora
              <span v-if="nombre" class=" ml-1 text-primary text-3xl">
                {{ nombre }}
              </span>
            </h4>
          </template>
          <template v-slot:end class="flex flex-wrap">
            <ConfirmDialog></ConfirmDialog>
            <FileUpload mode="basic" v-tooltip.top="'Reemplace los costos con nuevos desde un archivo tipo csv'"
              name="demo[]" :customUpload="true" chooseLabel="Subir archivo de precios" :auto="true" class="m-2"
              @uploader="archivoCosto" accept=".csv" />
            <Button id="crearTrasnportadora" label="Crear nuevo costo" icon="pi pi-plus" class="p-button-success mr-2"
              @click="nuevoCosto()" />
            <Button icon="pi pi-external-link" label="Exportar" @click="exportCSV($event)" />
          </template>
        </Toolbar>
        <!-- Inicio tabla listado de costoTransportadora -->
        <DataTable ref="dt" :value="costoTransportadora" stripedRows v-model:selection="costotransportadoraSeleccionada"
          selectionMode="multiple" dataKey="id" :paginator="true" :rows="10" :filters="filters" :loading="loading"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 25]"
          currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords} Costos" responsiveLayout="scroll"
          breakpoint="1200px">
          <template #header>
            <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
              <span class="block mt-2 md:mt-0 p-input-icon-left">
                <i class="pi pi-search" />
                <InputText v-model="filters['global'].value" placeholder="Buscar..." />
              </span>
            </div>
          </template>
          <Column selectionMode="multiple"></Column>
          <!-- Nombre de la columna costoTransportadora -->
          <Column field="nombre" header="Transportadora" :sortable="true" class="cursor-pointer">
            <template #body="slotProps">
              {{ slotProps.data.nombre }}
            </template>
          </Column>
          <Column field="habilitado" header="Habilitado" :sortable="true" class="cursor-pointer">
            <template #body="slotProps">
              <em class="pi" :class="{
                'text-teal-500 pi-check-circle': slotProps.data.costo_habilitado,
                'text-red-500 pi-times-circle': !slotProps.data.costo_habilitado,
              }"></em>
            </template>
          </Column>
          <Column field="tipo" header="Tipo" :sortable="true" class="cursor-pointer">
            <template #body="slotProps">
              {{ slotProps.data.tipo }}
            </template>
          </Column>
          <Column field="ciudad_desde" header="Ciudad Desde" :sortable="true" class="cursor-pointer">
            <template #body="slotProps">
              {{ slotProps.data.ciudad_desde }}
            </template>
          </Column>
          <Column field="departamento_desde" header="Departamento Desde" :sortable="true" class="cursor-pointer">
            <template #body="slotProps">
              {{ slotProps.data.departamento_desde }}
            </template>
          </Column>
          <Column field="ciudad_hasta" header="Ciudad hasta" :sortable="true" class="cursor-pointer">
            <template #body="slotProps">
              {{ slotProps.data.ciudad_hasta }}
            </template>
          </Column>
          <Column field="departamento_hasta" header="Departamento Hasta" :sortable="true" class="cursor-pointer">
            <template #body="slotProps">
              {{ slotProps.data.departamento_hasta }}
            </template>
          </Column>
          <Column field="unidad_medida" header="Unidad medida" :sortable="true" class="cursor-pointer">
            <template #body="slotProps">
              {{ slotProps.data.unidad_medida }}
            </template>
          </Column>
          <Column field="peso_desde" header="Peso Desde" :sortable="true" class="cursor-pointer">
            <template #body="slotProps">
              {{ slotProps.data.peso_desde }}
            </template>
          </Column>
          <Column field="Peso_hasta" header="Peso hasta" :sortable="true" class="cursor-pointer">
            <template #body="slotProps">
              {{ slotProps.data.peso_hasta }}
            </template>
          </Column>
          <Column field="volumen" header="Volumen" :sortable="true" class="cursor-pointer">
            <template #body="slotProps">
              {{ slotProps.data.volumen }}
            </template>
          </Column>
          <Column field="valor_desde" header="Valor desde" :sortable="true" class="cursor-pointer">
            <template #body="slotProps">
              {{ slotProps.data.valor_desde }}
            </template>
          </Column>
          <Column field="valor_hasta" header="Valor Hasta" :sortable="true" class="cursor-pointer">
            <template #body="slotProps">
              {{ slotProps.data.valor_hasta }}
            </template>
          </Column>
          <Column field="talla" header="Talla" :sortable="true" class="cursor-pointer">
            <template #body="slotProps">
              {{ slotProps.data.talla }}
            </template>
          </Column>
          <Column field="precio" header="Precio" :sortable="true" class="cursor-pointer">
            <template #body="slotProps">
              {{
                  new Intl.NumberFormat(
                    'es-CO',
                    { style: 'currency', currency: 'COP', minimumFractionDigits: 0, }
                  ).format(slotProps.data.precio)
              }}
            </template>
          </Column>
          <Column>
            <template #body="slotProps">
              <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2"
                @click="editarCostoTransportadora(slotProps.data)" />
            </template>
          </Column>
          <template #empty>
            No Se encontraron resultados para la transportadora o aún no se le asignan precios.
          </template>

        </DataTable>
        <!-- Fin tabla listado de transportadoras -->

        <!-- Inicio modal formulario crear o editar transportadoras -->
        <Dialog v-model:visible="costoTransportadoraDialog" :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
          :style="{ width: '60vw' }"
          :header="transportadora.nombre ? `Nuevo costo para la transportadora ${transportadora.nombre}` : 'Nuevo costo para la transportadora'"
          :modal="true" class="p-fluid">

          <div class="grid">
            <div class="col-12 sm:col-4">
              <div class="field">
                <label for="tipo">Tipo de remesa*</label>
                <Dropdown autofocus="autofocus" id="tipo" name="tipo" v-model="costo.tipo" :options="tipos"
                  placeholder="Especifique el tipo de envio" filterPlaceholder="Buscar medida"
                  :class="noEnviar && v$.costo.tipo.$invalid ? 'p-invalid' : ''">
                </Dropdown>
                <small class="p-error" v-if="noEnviar && v$.costo.tipo.$invalid">
                  El campo tipo es requerido.
                </small>
              </div>
            </div>

            <div class="col-12 sm:col-4">
              <div class="field">
                <label for="desde">Ciudad Desde *</label>
                <Dropdown v-model="costo.ciudad_desde" :options="departamentosCiudades" optionLabel="label"
                  optionGroupLabel="label" optionGroupChildren="ciudades" optionValue="value"
                  :placeholder="costo.ciudad_desde ? costo.ciudad_desde : 'Seleccione un ciudad'"
                  filterPlaceholder="Buscar ciudad" :filter="true" :showClear="true"
                  emptyFilterMessage="No se encontro la ciudad" id="desde" name="desde"
                  :class="noEnviar && v$.costo.ciudad_desde.$invalid ? 'p-invalid' : ''">
                  <template #optiongroup="slotProps">
                    <div class="flex align-items-center country-item">
                      <div>{{ slotProps.option.label }}</div>
                    </div>
                  </template>
                </Dropdown>
                <small class="p-error" v-if="noEnviar && v$.costo.ciudad_desde.$invalid">
                  El campo ciudad desde es requerido.
                </small>
              </div>
            </div>

            <div class="col-12 sm:col-4">
              <div class="field">
                <label for="hasta">Ciudad Hasta *</label>
                <Dropdown v-model="costo.ciudad_hasta" :options="departamentosCiudades" optionLabel="label"
                  optionGroupLabel="label" optionGroupChildren="ciudades" optionValue="value"
                  :placeholder="costo.ciudad_hasta ? costo.ciudad_hasta : 'Seleccione un ciudad'"
                  filterPlaceholder="Buscar ciudad" :filter="true" :showClear="true"
                  emptyFilterMessage="No se encontro la ciudad" id="hasta" name="hasta"
                  :class="noEnviar && v$.costo.ciudad_hasta.$invalid ? 'p-invalid' : ''">
                  <template #optiongroup="slotProps">
                    <div class="flex align-items-center country-item">
                      <div>{{ slotProps.option.label }}</div>
                    </div>
                  </template>
                </Dropdown>
                <small class="p-error" v-if="noEnviar && v$.costo.ciudad_hasta.$invalid">
                  El campo ciudad hasta es requerido.
                </small>
              </div>
            </div>

            <div class="col-12 sm:col-4">
              <div class="field">
                <label for="unidad_medida">Unidad medida</label>
                <InputUnidadMedida v-model="costo.unidad_medida"
                  :class="noEnviar && v$.costo.unidad_medida.$invalid ? 'p-invalid' : ''" />
                <small class="p-error" v-if="noEnviar && v$.costo.unidad_medida.$invalid">
                  El campo precio es requerido, debido a la asignacion de un peso ⚖️.
                </small>
              </div>
            </div>

            <div class="col-12 sm:col-4">
              <div class="field">
                <label for="peso_desde">Peso desde</label>
                <InputText id="peso_desde" name="peso_desde" v-model="costo.peso_desde" placeholder="Peso minimo" />
                <small class="p-error" v-if="noEnviar && v$.costo.peso_desde.$invalid">
                  El campo tipo es requerido, debido a la asignacion de una medida y/o un peso hasta.
                </small>
              </div>
            </div>

            <div class="col-12 sm:col-4">
              <div class="field">
                <label for="peso_hasta">Peso Hasta</label>
                <InputText id="peso_hasta" name="peso_hasta" v-model="costo.peso_hasta" placeholder="Peso maximo" />
                <small class="p-error" v-if="noEnviar && v$.costo.peso_hasta.$invalid">
                  El campo tipo es requerido, debido a la asignacion de una medida y/o un peso desde.
                </small>
              </div>
            </div>

            <div class="col-12 sm:col-4">
              <div class="field">
                <label for="volumen">Volumen</label>
                <InputText id="volumen" name="volumen" v-model.trim="costo.volumen"
                  placeholder="Volumen del producto" />
              </div>
            </div>

            <div class="col-12 sm:col-4">
              <div class="field">
                <label for="valor_desde">Valor desde</label>
                <InputText id="valor_desde" name="valor_desde" v-model="costo.valor_desde"
                  placeholder="Valor minimo $50.000" />
              </div>
            </div>

            <div class="col-12 sm:col-4">
              <div class="field">
                <label for="valor_hasta">Valor hasta</label>
                <InputText id="valor_hasta" name="valor_hasta" v-model="costo.valor_hasta"
                  placeholder="Valor maximo $100.000" />
              </div>
            </div>

            <div class="col-12 md:col-4">
              <div class="field">
                <label for="talla">Talla</label>
                <InputText id="talla" name="talla" v-model.trim="costo.talla" placeholder="Talla producto" />
              </div>
            </div>

            <div class="col-12 md:col-4">
              <div class="field">
                <label for="precio">Precio*</label>
                <InputText id="precio" name="precio" v-model="costo.precio" placeholder="Precio envio"
                  :class="noEnviar && v$.costo.precio.$invalid ? 'p-invalid' : ''" />
                <small class="p-error" v-if="noEnviar && v$.costo.precio.$invalid">
                  El campo precio es requerido.
                </small>
              </div>
            </div>

            <div class="col-12 md:col-4">
              <div class="field">
                <label for="habilitado">¿Habilitado?</label>
                <ToggleButton id="habilitado" name="habilitado" v-model="costo.costo_habilitado" onLabel="Si"
                  offLabel="NO" onIcon="pi pi-check" offIcon="pi pi-times" />
              </div>
            </div>

          </div>
          <template #footer>
            <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="ocultarDialog()" />
            <Button label="Guardar" icon="pi pi-check" class="p-button-text"
              @click="guardarCostoTransportadora(!v$.$invalid)" />
          </template>

        </Dialog>
        <!-- Fin formulario crear o editar transportadoras -->
      </div>
    </div>
  </div>
</template>
<script>
import useVuelidate from '@vuelidate/core'
//import { required, url } from '@vuelidate/validators'
import { FilterMatchMode } from "primevue/api";
import { obtenerTodo, crear, actualizar } from "@/api/clienteHttpService.js";
import { required, requiredIf } from '@vuelidate/validators';
import { departamentosCiudades } from '@/assets/datos_estaticos/departamentos_ciudades.js';
import { decrypt } from "@/auth-guard";
/* import BuscadorCiudades from "@/modulos/config/components/BuscadorCiudades.vue"; */

export default {
  name: 'CostosTransportadora',
  setup() {
    return { v$: useVuelidate() }
  },
  /* components: {
    BuscadorCiudades,
  }, */
  data: () => ({
    id: Number,
    transportadora: {}, // Datos de la transportadora seleccionada en transportadoras
    costoTransportadora: [], // Datos de la transportadora extraidos de la base de datos
    costo: {}, // Datos que se van a enviar al servidor para crear o editar una transportadora
    costotransportadoraSeleccionada: null,
    unidadesMedida: [],
    filters: {},
    nombre: '',
    loading: true,
    costoTransportadoraDialog: false,
    noEnviar: false,
    tipos: ['PAQUETE', 'SOBRE'],
    archivo: null,
    urlPrimeraPagina: null,
    desde: 0,
    urlSigientePagina: null,
    path: null,
    porPagina: 0,
    urlPaginaAnterior: null,
    paginaActual: 0,
    hasta: 0,
  }),

  validations() {
    return {
      // validaciones para tabla de transportadoras, tanto para crear como para editar.
      costo: {
        tipo: { required },
        precio: { required },
        unidad_medida: { required },
        talla: { required },
        peso_desde: {
          required: requiredIf(this.costo.unidad_medida || this.costo.peso_hasta)
        },
        peso_hasta: {
          required: requiredIf(this.costo.unidad_medida || this.costo.peso_desde)
        },
        ciudad_desde: { required },
        ciudad_hasta: { required }
      }
    }
  },
  async created() {
    // Inicio carga de datos para tabla de transportadoras
    this.initFilters();
    this.id = this.$route.params.id; // id de la transportadora obtenido de los parametros enviados por la url.
    const data = await this.$route.params.data // datos de la transportadora obtenido de los parametros enviados por la url.
    if (data) {
      this.transportadora = JSON.parse(data);
      this.nombre = this.transportadora.nombre;
    } // se parsea el string a objeto.
    this.obtenerCostosTransportadora(this.id); // se obtienen los costos de la transportadora de la base de datos.
  },
  async mounted() {
    this.unidadesMedida = await obtenerTodo("/unidadmedida");
    this.departamentosCiudades = departamentosCiudades;
  },
  methods: {
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
    async obtenerCostosTransportadora(id) {
      const resp = await obtenerTodo(`/costosTransportadoras/${id}`);
      this.costoTransportadora = resp;

      if (this.costoTransportadora.length > 0) {
        this.nombre = this.costoTransportadora[0].nombre;
      } else if (!this.nombre) {
        const nombre = await obtenerTodo(`/costosTransportadoras/obtenerNombreTransportadora/${id}`);
        this.nombre = nombre;
      }
      this.loading = false;
    },
    async nuevoCosto() {
      this.costo = {};
      this.costoTransportadoraDialog = true;
      this.noEnviar = false;
      this.costo.id_transportadora = this.id;
    },

    async guardarCostoTransportadora(isFormValid) {
      if (isFormValid) {
        this.noEnviar = false;
        if (this.costo.id) {
          // actualiza
          const actualiza = await actualizar("/costosTransportadoras", this.costo);
          if (actualiza === 'Accepted') {
            this.$toast.add({ severity: 'success', summary: 'Exito!', detail: 'Costo transportadora modificado 👍', life: 3000 });
            this.costoTransportadoraDialog = false;
            this.obtenerCostosTransportadora(this.id);
          } else {
            this.$toast.add({ severity: 'error', summary: 'Error!', detail: 'No se pudo modificar el costo transportadora', life: 3000 });
            this.costoTransportadoraDialog = false;
          }
        } else {
          // crea
          const crea = await crear("/costosTransportadoras", this.costo, "json");
          if (crea === 'Created') {
            this.costoTransportadoraDialog = false;
            this.costo = {};
            this.nuevoCostotranspotadoraDialog = false;
            this.$toast.add({ severity: 'success', summary: 'Exito!', detail: 'Costo transportadora creado 👍', life: 3000 });
            this.obtenerCostosTransportadora(this.id);
          } else {
            this.$toast.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Ha ocurrido un error, no se pudo crear la transportadora 😵',
              life: 3000
            });
            this.costoTransportadoraDialog = false;
          }
        }
      } else {
        this.noEnviar = true;
        this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Porfavor verifique los campos', life: 3000 });
      }
    },
    async editarCostoTransportadora(costoTransportadora) {
      this.costo = { ...costoTransportadora };
      this.costoTransportadoraDialog = true;
      this.costo.id_user_log = localStorage.getItem('id');
    },
    ocultarDialog() {
      this.costoTransportadoraDialog = false;
    },

    async archivoCosto(event) {
      const nombreArchivo = event.files[0].name;
      this.$confirm.require({
        message: `¿Estas seguro de subir el archivo \n ${nombreArchivo}?`,
        header: 'Confirmación',
        icon: 'pi pi-exclamation-triangle',
        acceptClass: 'p-button-danger',
        acceptLabel: 'Si',
        rejectLabel: 'No',
        //Si se acepta la confirmacion
        accept: async () => {
          this.archivo = event.files[0];
          const data = new FormData();
          data.append('archivo', this.archivo);
          data.append('id_user_log', decrypt(localStorage.getItem("id")).id);
          data.append('id_transportadora', this.id);
          //Subir archivo excel
          this.loading = true;
          const subirArchivoExcel = await crear("/costosTransportadoras/subirArchivoCostosTransportadora", data, "multipart/form-data");
          if (subirArchivoExcel == "Created") {
            this.$toast.add({ severity: 'success', summary: 'Exito!', detail: 'Archivo subido 👍', life: 3000 });
            this.obtenerCostosTransportadora(this.id);
          } else {
            this.$toast.add({ severity: 'error', summary: 'Error!', detail: 'No se pudo subir el archivo', life: 3000 });
          }
        },
        //Si se rechaza la confirmacion, se muestra un mensaje de cancelado.
        reject: () => {
          this.$toast.add({ severity: 'error', summary: 'Cancelado', detail: `Has cancelado la subida del archivo ${nombreArchivo}`, life: 4000 });
        }
      });
      this.loading = false;
    },
  },
}
</script> 
<style>
.p-confirm-dialog-message {
  font-weight: bold;
}

.p-confirm-dialog-icon {
  color: gold !important;
}
</style>