import instance from "./services";

const obtenerUsuarios = async () => {
  try {
    const resp = await instance.get("/usuarios", {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    });
    return resp.data;
  } catch (error) {
    return error.response.statusText;
  }
};

const obtenerClientes = async () => {
  try {
    const resp = await instance.get("/prueba", {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    });
    return resp.data;
  } catch (error) {
    return error.response.statusText;
  }
};

const actualizarUsuarios = async (req) => {
  try {
    const resp = await instance.put("/usuarios",
    {
      id: req.id,
      name: req.name,
      last_name: req.last_name,
      email: req.email,
      phone: req.phone,
    },
    {headers: { Authorization: "Bearer " + localStorage.getItem("token") }}
    
    
);

    
    return resp.data[0];
    
  } catch (error) {
    return error.response.statusText;
  }
};

const crearUsuarios = async (req) => {
  try {
    const resp = await instance.post("/auth/signup", {
      name: req.name,
      last_name: req.last_name,
      email: req.email,
      phone: req.phone,
      password: req.password,
      password_confirmation: req.password_confirmation,
    });

    return resp.statusText;
  } catch (error) {
    console.log(error)
    return error.response.statusText;
    
  }
};

export {obtenerClientes, obtenerUsuarios, crearUsuarios, actualizarUsuarios };
