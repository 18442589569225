<template>
  <div class="surface-0 flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden">
    <div class="grid justify-content-center p-2 lg:p-0" style="min-width:80%">
      <div class="col-12 mt-5 xl:mt-0 text-center">
        <Logo />
      </div>
      <div class="col-12 xl:col-6"
        style="border-radius:56px; padding:0.3rem; background: linear-gradient(180deg, var(--primary-color), rgba(33, 150, 243, 0) 30%);">
        <div class="h-full w-full m-0 py-7 px-4"
          style="border-radius:53px; background: linear-gradient(180deg, var(--surface-50) 38.9%, var(--surface-0));">
          <div class="text-center mb-5">
            <div class="text-900 text-3xl font-medium mb-3">Recuperar contraseña</div>
          </div>
          <Message v-if="showMessage" :severity="messages.severity" :icon="messages.icon">{{ messages.content }}
          </Message>
          <div v-if="visibleSpinner" class="w-full md:w-10 flex justify-content-center mx-auto">
            <ProgressSpinner style="width:50px;height:50px" strokeWidth="5" animationDuration=".5s" />
          </div>

          <div class="w-full md:w-10 mx-auto">

            <label for="email1" class="block text-900 text-xl font-medium mb-2">Correo electrónico</label>

            <InputText :class="{ 'p-invalid': v$.email.$invalid && submitted }" placeholder="ejemplo@correo.com"
              type="email" v-model="email" class="w-full mb-3" style="padding:1rem;" :disabled="inputDesabilitado" />

            <Button @click="reset(!v$.$invalid)" label="Recuperar contraseña" class="w-full p-3 text-xl"></button>
            <div class="col-12 mt-5 text-center">
              <i class="pi pi-fw pi-arrow-left text-blue-500 mr-2" style="vertical-align:center;"></i>
              <router-link to="/" class="text-blue-500">Ir al inicio</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import { resetPassServices } from "../api/loginServices";
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      inputDesabilitado: false,
      visibleSpinner: false,
      email: "",
      submitted: false,
      showMessage: false,
      messages: {
        severity: "",
        content: "",
        icon: "",
      },
    };
  },
  validations() {
    return {
      email: { required, email },
    };
  },

  methods: {
    async reset(isFormValid) {
      if (!isFormValid) {
        this.messages.severity = "error";
        this.messages.content = "Ingrese un correo valido.";
        this.showMessage = !this.showMessage;
        return;
      } else {
        this.visibleSpinner = true;
        this.inputDesabilitado = true;
        const resp = await resetPassServices(this.email);
        if (resp == "200") {
          this.messages.severity = "info";
          this.messages.content = "Mensaje enviado";
          this.messages.icon = "pi pi-send";
          this.showMessage = !this.showMessage;
          this.email = "";
          this.visibleSpinner = false;
          this.inputDesabilitado = false;
        }
      }
    },
  },
};
</script>
