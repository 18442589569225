<template>
  <div v-once class="layout-topbar">
    <button class="p-link layout-menu-button layout-topbar-button mr-3 ml-0" @click="onMenuToggle">
      <i class="pi pi-bars"></i>
    </button>

    <!-- <router-link to="/" class="layout-topbar-logo"> -->
      <div class="layout-topbar-logo">
        <img alt="Logo" src="../../../assets/logos/logo_unico.webp"  />
      </div>
    <!-- </router-link> -->
    
    <button class="p-link layout-topbar-menu-button layout-topbar-button" @click="toggle">
      <i class="pi pi-ellipsis-v"></i>
    </button>

    <div class="layout-topbar-menu hidden lg:flex origin-top ">
      <button class="p-link layout-topbar-button" @click="toggle">
        <i class="pi pi-user"></i>
      </button>
    </div>
    <Menu ref="menu" :model="menu" :popup="true">
      <SelectButton />
    </Menu>
  </div>
</template>

<script>
import { clearLocalStorage, decrypt } from "../../../auth-guard";
import router from "../../../router";
export default {
  data() {
    return {
      darkMode: null,
      menu: [{
        items: [
          {
            separator: true
          },
          {
            label: 'Tema oscuro',
            icon: 'pi pi-moon',
            visible: () => !this.darkMode,
            command: () => {
              this.changeTheme(true)
            },
          },
          {
            label: 'Tema claro',
            icon: 'pi pi-sun',
            visible: () => this.darkMode,
            command: () => {
              this.changeTheme(false)
            },
          },
          {
            label: 'Cerrar sesión',
            icon: 'pi pi-fw pi-power-off',
            command: () => {
              clearLocalStorage();
              router.go("login");
            },
          }
          ]

      },
      ]
  }},
  methods: {
    toggle(event) {
      this.menu[0].label = this.user.name;
      this.$refs.menu.toggle(event);
    },
    onMenuToggle(event) {
      this.$emit("menu-toggle", event);
    },
    onTopbarMenuToggle(event) {
      this.$emit("topbar-menu-toggle", event);
    },
    changeTheme(mode) {
      this.darkMode = mode;
      this.theme = mode === false ? "/themes/lara-dark-indigo/theme.css" : "/themes/lara-light-indigo/theme.css";
      this.themeChanged = mode === true ? "/themes/lara-dark-indigo/theme.css" : "/themes/lara-light-indigo/theme.css" ;
      let themeElement = document.getElementById('theme-link');
      themeElement.setAttribute('href', themeElement.getAttribute('href').replace(this.theme, this.themeChanged));
      localStorage.setItem('dark_mode', mode);
    }
  },
  mounted() {
    this.darkMode = localStorage.getItem('dark_mode');
    this.changeTheme(this.darkMode);
  },
  computed: {
    darkTheme() {
      return this.$appState.darkTheme;
    },
    user(){
      /*
        user: {
          id:
          name:
        }
      */
      return decrypt(localStorage.getItem("id"));
    }
  },
};
</script>
