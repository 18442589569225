<template>
  <div class="card">
    <Toast />
    <Toolbar class="mb-4">
      <template v-slot:start>
        <div class="grid p-fluid">
          <h4 class="m-4">Integraciones</h4>
        </div>
      </template>
    </Toolbar>
    <Toolbar class="mb-4">
      <template v-slot:start class="block">
        <div class="block">
          <h5 class="mb-4">Operaciones</h5>
          <Button
            icon="pi pi-replay"
            label="Actualizar buscador"
            class="mr-2"
            @click="dialogDoofinder = true"
          ></Button>
        </div>
      </template>
    </Toolbar>
    <Dialog
      v-model:visible="dialogDoofinder"
      :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
      :style="{ width: '35vw' }"
      modal
      header="Actualizar Buscador"
      class="p-fluid"
    >
      <div>
        <p>Para actualizar el buscador, sigue los siguientes pasos:</p>
        <ol>
          <li>Haz clic en el botón para actualizar los índices.</li>
          <li>
            Una vez que los índices se hayan actualizado, accede a la URL de
            Doofinder.
          </li>
        </ol>
        <div v-if="indicesActualizados">
          <p>Archivo actualizado correctamente. Ahora, accede a Doofinder:</p>
          <a
            href="https://admin.doofinder.com/auth/login"
            target="_blank"
            class="p-button-link"
            >Ir a Doofinder para actualizar índices</a
          >
        </div>
      </div>
      <template #footer>
        <Button
          label="Actualizar"
          icon="pi pi-sync"
          @click="ejecutarDoofinder()"
          class="p-button-success w-5 mb-3 mt-2"
      /></template>
    </Dialog>
    <DataTable
      :value="parametros"
      :rows="10"
      :paginator="true"
      responsiveLayout="scroll"
      :filters="filters"
      :loading="loading"
    >
      <template #header>
        <div>
          <span class="block mt-2 md:mt-0 p-input-icon-left">
            <i class="pi pi-search" />
            <InputText
              v-model.trim="filters['global'].value"
              placeholder="Buscar..."
            />
          </span>
        </div>
      </template>
      <Column
        field="tienda"
        header="Tienda"
        :sortable="true"
        style="width: 10%"
      >
        <template #body="slotProps">
          {{ slotProps.data.tienda }}
        </template>
      </Column>
      <Column
        field="descripcion_integracion"
        header="Descripción"
        :sortable="true"
        style="width: 10%"
      >
        <template #body="slotProps">
          {{ slotProps.data.descripcion_integracion }}
        </template>
      </Column>
      <Column field="error" header="Estado" :sortable="true" style="width: 10%">
        <template #body="slotProps">
          <em
            class="pi"
            :class="definirIcono(slotProps.data.error)"
            style="font-size: 17px"
          ></em>
        </template>
      </Column>
      <Column
        field="acciones"
        header="Acciones"
        :sortable="false"
        style="width: 10%"
      >
        <template #body="slotProps">
          <!-- <Button
            icon="pi pi-sliders-v"
            class="p-button-success"
            @click="
              ejecutarIntegracionProductos(
                slotProps.data.tienda,
                slotProps.data.id_tipo_integracion
              )
            "
          /> -->
          <Button
            icon="pi pi-sliders-v"
            class="p-button-secondary"
            @click="(dialog = true), (dataTienda = slotProps.data)"
          />
        </template>
      </Column>
      <!-- <Column
          field="tipo_integracion"
          header="Tipo integración"
          :sortable="true"
          style="width: 20%"
        >
          <template #body="slotProps">
            {{ slotProps.data.tipo_integracion }}
          </template>
        </Column>
        <Column
          field="descripcion"
          header="Estado"
          :sortable="true"
          style="width: 20%"
        >
          <template #body="slotProps">
            {{ slotProps.data.descripcion }}
          </template>
        </Column>
        <Column
          field="created_at"
          header="Ultima ejecución"
          :sortable="true"
          style="width: 20%"
        >
          <template #body="slotProps">
            {{ slotProps.data.created_at }}
          </template>
        </Column>
        <Column
          field="opciones"
          header="Opciones"
          :sortable="false"
          style="width: 20%"
        >
          <template #body="slotProps">
            <Button
              label="Ejecutar"
              icon="pi pi-play"
              class="p-button-success w-5"
              @click="
                ejecutarIntegracionProductos(
                  slotProps.data.tienda,
                  slotProps.data.id_tipo_integracion
                )
              "
            />
          </template>
        </Column> -->
    </DataTable>
    <Dialog
      v-model:visible="dialog"
      modal
      header="Estado integraciones"
      :style="{ width: '70rem' }"
    >
      <DataTable
        :value="dataTienda.estados"
        :rows="3"
        :paginator="false"
        responsiveLayout="scroll"
      >
        <Column
          field="tipo_integracion"
          header="Tipo integración"
          :sortable="false"
          style="width: 10%"
        >
          <template #body="slotProps">
            {{ capitalizeFirstLetter(slotProps.data.tipo_integracion) }}
          </template>
        </Column>
        <Column
          field="descripcion"
          header="Descripción"
          :sortable="false"
          style="width: 10%"
        >
          {{ slotProps.descripcion }}
        </Column>

        <Column
          field="frecuencia"
          header="Frecuencia de ejecución"
          :sortable="true"
          style="width: 10%"
        >
          <template #body="slotProps">
            {{ capitalizeFirstLetter(slotProps.data.frecuencia) }}
          </template>
        </Column>
        <Column
          field="parametro"
          header="Parametro"
          :sortable="true"
          style="width: 10%"
        >
          <template #body="slotProps">
            {{ slotProps.data.parametro }}
          </template>
        </Column>
        <Column
          field="estado"
          header="Estado"
          :sortable="false"
          style="width: 10%"
        >
          <template #body="slotProps">
            <Tag
              severity="danger"
              :value="slotProps.data.estado"
              v-if="slotProps.data.estado == 'Error'"
            ></Tag>
            <Tag
              severity="success"
              :value="slotProps.data.estado"
              v-else-if="slotProps.data.estado == 'Correcto'"
            ></Tag>
          </template>
        </Column>
        <Column
          field="created_at"
          header="Ultima ejecución"
          :sortable="false"
          style="width: 10%"
        >
          <template #body="slotProps">
            <div v-if="slotProps.data.created_at">
              {{ formatDate(slotProps.data.created_at) }}
              {{
                new Date(slotProps.data.created_at).toLocaleTimeString(
                  undefined,
                  { hour: "2-digit", minute: "2-digit" }
                )
              }}
            </div>
          </template>
        </Column>
        <Column
          field="opciones"
          header="Opciones"
          :sortable="false"
          style="width: 20%"
        >
          <template #body="slotProps">
            <Button
              label="Ejecutar"
              icon="pi pi-play"
              class="p-button-success w-10"
              v-tooltip="
                'Advertencia: No presionar el botón de manera indiscriminada, ya que puede generar errores en la ejecución de los procesos.'
              "
              @click="
                ejecutarIntegracionProductos(
                  slotProps.data.tienda,
                  slotProps.data.id_tipo_integracion
                )
              "
            />
          </template>
        </Column>
      </DataTable>
    </Dialog>
  </div>
</template>
<script>
import { obtenerTodo } from "@/api/clienteHttpService.js";
import {
  ejecutarIntegracion,
  actualizarDoofinder,
} from "@/api/productosServices.js";
import { FilterMatchMode } from "primevue/api";
export default {
  name: "IntegracionesTable",
  data: () => ({
    parametros: null,
    loading: true,
    filters: {},
    dialog: false,
    dialogDoofinder: false,
    indicesActualizados: null,
  }),
  created() {
    this.obtenerIntegraciones();
    this.initFilters();
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      return new Intl.DateTimeFormat("es-CO").format(date);
    },
    async obtenerIntegraciones() {
      this.loading = true;
      const respuesta = await obtenerTodo("/scheduling/estadoIntegraciones");
      this.parametros = respuesta;
      this.loading = false;
    },
    definirIcono(estado) {
      if (estado == true) {
        return "text-yellow-500 pi-exclamation-circle";
      } else {
        return "text-teal-500 pi-check-circle";
      }
    },
    async ejecutarIntegracionProductos(tienda, id_tipo_integracion) {
      this.loading = true;
      try {
        await ejecutarIntegracion({ tienda, id_tipo_integracion });
        this.loading = false;
        this.obtenerIntegraciones();
        this.$toast.add({
          severity: "success",
          summary: "Ejecución",
          detail:
            "Proceso enviado a cola de ejecución correctamente, espere unos minutos para ver los resultados.",
          life: 6000,
        });
      } catch (error) {
        this.loading = false;
        this.$toast.add({
          severity: "error",
          summary: "Ejecución",
          detail: "Error en la ejecución",
          life: 3000,
        });
      }
    },
    async ejecutarDoofinder() {
      try {
        await actualizarDoofinder();
        this.$toast.add({
          severity: "success",
          summary: "Ejecución",
          detail: "Doofinder actualizado",
          life: 6000,
        });
        this.indicesActualizados = true;
      } catch (error) {
        this.loading = false;
        this.$toast.add({
          severity: "error",
          summary: "Ejecución",
          detail: "Error al ejecutar el doofinder",
          life: 3000,
        });
      }
    },
    calculateClass(estado) {
      if (estado === 34 || estado === 35 || estado === 37) {
        return "text-teal-500 pi-check-circle";
      } else if (
        estado === 33 ||
        estado === 36 ||
        estado == 38 ||
        estado == 39 ||
        estado == 40 ||
        estado == 41 ||
        estado == 42 ||
        estado == 43
      ) {
        return "text-red-500 pi-times-circle";
      } else if (estado === 44 || estado === 45) {
        return "text-yellow-500 pi-exclamation-circle";
      } else {
        return "text-red-500 pi-times-circle ";
      }
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
  },
};
</script>
<style scoped></style>
