<template>
  <div class="grid flex justify-content-center">
    <div class="col-12">
      <div class="card">
        <Toolbar class="mb-4">
          <template v-slot:start>
            <h5 class="m-4">Secciones home</h5>
          </template>

          <template v-slot:end>
            <section class="contenedor-btn-menu">
              <router-link v-for="link in links" :to="link.to" :key="link.name">
                <Button :label="link.name"></Button>
              </router-link>
            </section>
          </template>
        </Toolbar>

        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
const links = ref([
  { to: "banner-principal", name: "Banner principal" },
  { to: "secciones-banners", name: "Secciones banners" },
]);
</script>

<style scoped>
@import "../../assets/styles/bannerHome.css";
</style>
