<template>
  <div class="grid">
    <!-- <CardIndicadores /> -->
    <!-- <ventasPorMarca />
    <VentasCiudad /> -->

    <iframe
      title="Ecommerce 2.0"
      width="100%"
      height="528"
      style="height: calc(100vh - 120px)"
      src="https://app.powerbi.com/reportEmbed?reportId=ad3ee684-cfa9-463f-b2b7-76687e0c1259&autoAuth=true&ctid=f1130289-613d-4c62-a32b-a5dd96b853d3"
      frameborder="0"
      allowFullScreen="true"
      allowtransparency="true"
    ></iframe>

    <!--<VentasPorMes />
    <div class="col-12 xl:col-6">
      <div class="card hover:shadow-5">
        <div class="grid">
          <div class="col-6">
            <div class="grid">
              <div class="col-8">
                <h5 class="mb-2">Canales de pago</h5>
                <span class="block text-900 font-medium">Total Transacciones</span>
              </div>
              <div class="col-2 text-center">
                <div class="flex align-items-center justify-content-center bg-blue-200 border-round"
                  style="width:2.5rem;height:2.5rem">
                  <i class="pi pi-credit-card text-purple-900 text-xl"></i>
                </div>
              </div>
            </div>
            <div class="col-6">
              <span class="text-500 mr-5">Botón Bancolombia</span>
              <span class="text-green-500">$16.699.870</span> <br>
              <span class="text-500 mr-5">Corresponsales bancarios Grupo Aval</span>
              <span class="text-green-500">$16.699.870</span>
              <br>
              <span class="text-500 mr-5">Cuentas débito ahorro y corriente (PSE)</span>
              <span class="text-green-500">$16.699.870</span>
              <br>
              <span class="text-500 mr-5">MasterCard</span>
              <span class="text-green-500">$16.699.870</span>
              <br>
              <span class="text-500 mr-5">Visa</span>
              <span class="text-green-500">$16.699.870</span>
            </div>
          </div>
          <div class="col-6">
            <div class="flex flex-column">
              <h5>Mayor Ingreso</h5>
              <div class="flex align-items-center justify-content-center">
                <span class="text-500 ">Cuentas debito ahorro y corriente </span>
                <div class="flex align-items-center justify-content-center bg-green-300 border-round ml-3"
                  style="width:2.5rem;height:2.5rem">
                  <i class="pi pi-sort-up  text-white text-xl"></i>
                </div>
              </div>
              <h5>Menor ingreso</h5>
              <div class="flex align-items-center justify-content-center">
                <span class="text-500 ">Corresponsales bancarios grupo Aval </span>
                <span class="flex align-items-center justify-content-center bg-red-400 border-round ml-3"
                  style="width:2.5rem;height:2.5rem">
                  <i class="pi pi-sort-down  text-white text-xl"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <!-- <div class="col-12 xl:col-6">
      <div class="card hover:shadow-5">
        <div class="grid">
          <div class="col-6">
            <h5>Tipo de pedidos</h5>
        
            <Chart type="doughnut" :data="chartData" :options="lightOptions" />
          </div>
          <div class="col-6">
            <div class="flex flex-column">
              <div class="flex align-items-center justify-content-center h-10rem">
                <span class="text-500">Pedidos a domicilio</span>
                <p>
                  <i class="pi pi-star-fill  text-blue-500 text-xl"></i>
                  $99.364.462
                </p>
              </div>
              <div class="flex align-items-center justify-content-center h-10rem">
                <span class="text-500">Pedidos recogida en centro comercial</span>
                <p>
                  <i class="pi pi-star-fill  text-pink-500 text-xl"></i>
                  $15.503.462
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <!-- <Parametros /> -->
  </div>
</template>
<script>
// import parametros from '../components/Parametros.vue'
// import ventasPorMarca from '../components/VentasPorMarca.vue';
// import cardIndicadores from '../components/CardIndicadores.vue';
// import ventasCiudad from '../components/VentasCiudad.vue';
// import ventasPorMes from '../components/VentasPorMes.vue';
export default {
  name: "configuraciones",
  data: () => ({
    chartData: {
      labels: ["Domicilio", "Recogida centro comercial"],
      datasets: [
        {
          data: [200, 90],
          backgroundColor: ["#FF6384", "#36A2EB"],
          hoverBackgroundColor: ["#FF6384", "#36A2EB"],
        },
      ],
    },
    lightOptions: {
      plugins: {
        legend: {
          labels: {
            color: "#495057",
          },
        },
      },
    },
  }),
  methods: {},
  components: {
    // Parametros: parametros,
    // VentasPorMarca: ventasPorMarca,
    // VentasCiudad: ventasCiudad,
    // CardIndicadores: cardIndicadores,
    // VentasPorMes: ventasPorMes
  },
};
</script>
<style scoped>
.rounded-frame {
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
}
</style>
