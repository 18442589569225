<script>
import { obtenerTodo } from "@/api/clienteHttpService";
export default {
    props: ['selectedItems', 'itemsInDB'],
    data: () => ({
        transportadoraSeleccionada: [],
        transportadoras: null,
        db_items: [],
        loading: true
    }),
    async created() {
        try {
            const resp = await obtenerTodo("/transportadoras");
            this.transportadoras = resp;
            this.loading = false;
            this.findItemsOfDB(this.itemsInDB); //tcc , leonisa, etc
        } catch (error) {
            console.log(error);
        }
    },
    methods: {
        findItemsOfDB(id) {
            const regex = /(\d+)/g;
            this.db_items = id.match(regex).map((id_data) => {
                return this.transportadoras.filter((e) => e.id == id_data);
            });
            this.transportadoraSeleccionada = this.db_items.map((e) => {
                return e[0];
            });
            this.loading = false;
        },
        handleChange(event) {
            this.selectedItems(event.value);
        }
    }
}
</script>
<template>
    <MultiSelect v-model="transportadoraSeleccionada" 
    :options="transportadoras" 
    optionLabel="nombre"
    @change="handleChange" 
    placeholder="Seleccione..." 
    :loading="loading" />
</template>