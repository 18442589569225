<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <Toast />
        <Toolbar class="mb-4">
          <template v-slot:start>
            <h5 class="m-4">Secciones</h5>
          </template>
          <template v-slot:end>
            <Button
              id="crearSeccion"
              label="Crear Seccion"
              icon="pi pi-plus"
              class="p-button-success mr-2"
              @click="nuevaSeccion()"
            />
          </template>
        </Toolbar>
        <!-- Inicio tabla listado de banners -->
        <DataTable
          ref="dt"
          :value="secciones"
          v-model:selection="selectedProducts"
          dataKey="id"
          :paginator="false"
          :rows="20"
          :filters="filters"
          :loading="loading"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[20, 40]"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} productos"
          responsiveLayout="scroll"
        >
          <template #header>
            <div
              class="flex flex-column md:flex-row md:justify-content-between md:align-items-center"
            >
              <span class="block mt-2 md:mt-0 p-input-icon-left">
                <i class="pi pi-search" />
                <InputText placeholder="Buscar..." />
              </span>
            </div>
          </template>
          <Column field="nombre" header="Nombre" :sortable="true">
            <template #body="slotProps">
              {{ slotProps.data.nombre }}
            </template>
          </Column>
          <Column field="descripcion" header="Descripcion" :sortable="true">
            <template #body="slotProps">
              {{ slotProps.data.descripcion }}
            </template>
          </Column>
          <Column field="habilitado" header="Habilitado" :sortable="true">
            <template #body="slotProps">
              <em
                class="pi"
                :class="{
                  'text-teal-500 pi-check-circle': slotProps.data.habilitado,
                  'text-red-500 pi-times-circle': !slotProps.data.habilitado,
                }"
              ></em>
            </template>
          </Column>
          <Column headerStyle="min-width:10rem;">
            <template #body="slotProps">
              <Button
                icon="pi pi-pencil"
                class="p-button-rounded p-button-success mr-2"
                @click="editarSeccion(slotProps.data)"
              />
            </template>
          </Column>
        </DataTable>

        <!-- Inicio modal formulario crear o editar secciones -->
        <Dialog
          v-model:visible="seccionDialog"
          :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
          :style="{ width: '40vw' }"
          :header="seccion.nombre ? seccion.nombre : 'Crear sección'"
          :modal="true"
          class="p-fluid"
        >
          <div class="grid align-items-center">
            <div class="xl:col-12 lg:col-7 col-12">
              <div class="field">
                <label for="nombre">Nombre*</label>
                <InputText
                  id="nombre"
                  name="nombre"
                  v-model.trim="seccion.nombre"
                  required="true"
                  autofocus
                  :class="{ 'p-invalid': submitted && !seccion.nombre }"
                />
                <small
                  class="p-error p-invalid"
                  v-if="submitted && !seccion.nombre"
                  >Nombre es requerido.</small
                >
              </div>
            </div>
          </div>

          <div class="grid">
            <div class="xl:col-12 lg:col-7 col-12">
              <div class="field">
                <label for="nombre">Descripcion*</label>
                <InputText
                  id="nombre"
                  name="nombre"
                  v-model.trim="seccion.descripcion"
                  required="true"
                  autofocus
                  :class="{ 'p-invalid': submitted && !seccion.descripcion }"
                />
                <small
                  class="p-error p-invalid"
                  v-if="submitted && !seccion.nombre"
                  >Descripcion es requerida.</small
                >
              </div>
            </div>
          </div>
          <div v-if="exp" class="grid">
            <div  class="xl:col-6 lg:col-7 col-12">
              <div class="field">
                <label for="nombre">Estado</label>
              </div>
              <InputSwitch v-model="checked" />
            </div>
          </div>

          <div class="grid"></div>

          <template #footer>
            <Button
              label="Cancelar"
              icon="pi pi-times"
              class="p-button-text"
              @click="ocultarDialog()"
            />
            <Button
              label="Guardar"
              icon="pi pi-check"
              class="p-button-text"
              @click="guardarseccion(!v$.$invalid)"
            />
          </template>
        </Dialog>
        <!-- Fin formulario crear o editar transportadoras -->
      </div>
    </div>
  </div>
</template>

<script>
import { obtenerTodo, crear,actualizar } from "@/api/clienteHttpService";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

export default {
  name: "politicas",
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      secciones: null,
      seccion: {},
      seleccionarSecciones: null,
      seccionDialog: false,
      bannerImagen: null,
      submitted: false,
      checked: null,
      exp:false,
    };
  },
  validations() {
    return {
      seccion: {
        nombre: { required },
        descripcion:{required},
      },
    };
  },
  async created() {
    this.obtenerSecciones();
  },
  methods: {

    async nuevaSeccion() {
      this.seccionDialog = true;
      this.seccion = {};
      this.exp=false;
    },

    async obtenerSecciones() {
      this.loading=true;
      const resp = await obtenerTodo("/secciones");
      this.secciones = resp;
      this.loading=false;
    },

    editarSeccion(event) {
      this.seccion = { ...event };
      this.checked=event.habilitado;
      this.seccionDialog = true;
      this.exp=true;
    },

    async guardarseccion(isFormValid) {
      this.submitted = true;
      if (isFormValid) {
        if (this.seccion.id) {
          this.seccionDialog = false;
          this.loading = true;
          const id = localStorage.getItem("id");
           await actualizar(
            "/secciones",
            {
              id:this.seccion.id,
              nombre: this.seccion.nombre,
              descripcion: this.seccion.descripcion,
              habilitado:this.checked,
              id_user_log: id,
            },
            "multipart/form-data"
          );
          this.$toast.add({
            severity: "success",
            summary: "Success",
            detail: "Seccion Actualizada",
            life: 5000,
          });
          this.loading = false;
          this.banner = [];
          this.obtenerSecciones();
        } else {
          this.seccionDialog = false;
          this.loading = true;
          const id = localStorage.getItem("id");
       await crear(
            "/secciones",
            {
              nombre: this.seccion.nombre,
              descripcion: this.seccion.descripcion,
              id_user_log: id,
            },
            "multipart/form-data"
          );
          this.$toast.add({
            severity: "success",
            summary: "Success",
            detail: "Seccion Creada",
            life: 5000,
          });
          this.loading = false;
          this.seccion = [];
          this.obtenerSecciones();
        }
      } else {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Existen datos obligatorios",
          life: 5000,
        });
      }
    },
  },
};
</script>
<style scoped>
.texto-largo {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 80ch;
}
</style>