let CryptoJs = require("crypto-js");

const clearLocalStorage = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("id");
  localStorage.removeItem("pusherTransportNonTLS");
for (var i = 0; i < localStorage.length; i++) {
    var key = localStorage.key(i);
    if (key.startsWith("roles_")) {
      localStorage.removeItem(key);
    }
}
  localStorage.removeItem("pusherTransportNonTLS");
};

const guardsRoute = (to, from, next) => {
  if (localStorage.getItem("token")) {
    const token = decrypt(localStorage.getItem("token"));
    const current_date = new Date();
    const expires_at = new Date(token.expires_at);
    if((current_date.getTime()) > (expires_at.getTime())){
      clearLocalStorage();
      next({ name: "login" });
    }
    next();
  } else {
    next({ name: "login" });
  }
};

const guardsLogin = (to, from, next) => {
  if (!localStorage.getItem("token")) {
    const token = decrypt(localStorage.getItem("token")) ? decrypt(localStorage.getItem("token")): {expires_at:''};
    const current_date = new Date();
    const expires_at = new Date(token.expires_at);
    if((current_date.getTime()) > (expires_at.getTime())){
      clearLocalStorage();
      next();
    }
    next();
  } else {
    next({ name: "dashboard" });
  }
};

const checkRole = (to, from, next) => {
  const user = decrypt(localStorage.getItem("id"));
    if(to.meta.admin && (user.name_rol == 'admin')){
        next();
    }else if(to.meta.usuario && (user.name_rol == 'usuario')){
        next();
    } else if(to.meta.tienda && (user.name_rol == 'tienda')){
        next();
    }else{
      next({ name: "dashboard" });
    }
}

const encrypt = (data) => {
  const crypto_key = process.env.VUE_APP_CRYPTOKEY;
  if(data){
    return CryptoJs.AES.encrypt(JSON.stringify(data), crypto_key)
  }
}

const decrypt = (data) => {
  const crypto_key = process.env.VUE_APP_CRYPTOKEY;
  if(data){
    return JSON.parse(CryptoJs.enc.Utf8.stringify(CryptoJs.AES.decrypt(data, crypto_key)));
  }
}

export {guardsRoute, guardsLogin, clearLocalStorage, checkRole, encrypt, decrypt};