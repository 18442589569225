<template>
  <div class="grid flex justify-content-center">
    <div class="col-12">
      <div class="card">
        <Toast />
        <Toolbar class="mb-4">
          <template v-slot:start>
            <h5 class="m-4">Tiendas</h5>
          </template>
          <template v-slot:end>
            <Button label="Crear tienda" icon="pi pi-plus" class="p-button-success mr-2" @click="nuevaTienda()" />
            <Button icon="pi pi-external-link" label="Exportar" @click="exportCSV($event)" />
          </template>
        </Toolbar>
        <!-- Inicio tabla listado de tiendas -->
        <DataTable ref="dt" :value="tiendas" stripedRows dataKey="id" :paginator="true" :rows="10" :filters="filters"
          :loading="loading"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 25]"
          currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} tiendas" responsiveLayout="stack"
          breakpoint="960px">
          <template #header>
            <div>
              <span class="block mt-2 md:mt-0 p-input-icon-left">
                <i class="pi pi-search" />
                <InputText v-model.trim="filters['global'].value" placeholder="Buscar..." />
              </span>
            </div>
          </template>
          <Column selectionMode="multiple"></Column>
          <Column field="nombre" header="Tienda" :sortable="true">
            <template #body="slotProps">
              {{ slotProps.data.nombre_amigable }}
            </template>
          </Column>
          <Column field="id_tienda" header="Id tienda" :sortable="true">
            <template #body="slotProps">
              {{ slotProps.data.id_tienda }}
            </template>
          </Column>
          <Column field="ciudad" header="Ciudad" :sortable="true">
            <template #body="slotProps">
              {{ slotProps.data.ciudad }}
            </template>
          </Column>
          <Column field="stock_seguridad" header="Stock de Seg." :sortable="true">
            <template #body="slotProps">
              {{ slotProps.data.stock_seguridad }}
            </template>
          </Column>
          <Column field="celular" header="Celular" :sortable="true">
            <template #body="slotProps">
              {{ slotProps.data.celular }}
            </template>
          </Column>
          <Column field="correo" header="Correo" :sortable="true">
            <template #body="slotProps">
              {{ slotProps.data.correo }}
            </template>
          </Column>
          <Column field="habilitado" header="Habilitada" :sortable="true">
            <template #body="slotProps">
              <em class="pi" :class="{
                'text-teal-500 pi-check-circle': slotProps.data.habilitado,
                'text-red-500 pi-times-circle': !slotProps.data.habilitado,
              }"></em>
            </template>
          </Column>
          <Column>
            <template #body="slotProps">
              <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2"
                @click="editarTienda(slotProps.data)" />
            </template>
          </Column>
        </DataTable>
        <!-- Fin tabla listado de tiendas -->

        <!-- Inicio formulario crear o editar tiendas -->
        <Dialog v-model:visible="tiendaDialog" :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
          :style="{ width: '60vw' }" :header="tienda.nombre ? tienda.nombre : 'Crear tienda'" :modal="true"
          class="p-fluid">
          <div class="grid p-fluid">
            <div class="col-6 md:col-2">
              <div class="field">
                <label for="nombre">Nombre*</label>
                <InputText id="nombre" name="nombre" v-model.trim="tienda.nombre" autofocus
                  :class="{ 'p-invalid': submitted && v$.tienda.nombre.$invalid }" :disabled="editMode" />
                <small class="p-error" v-if="submitted && v$.tienda.nombre.$invalid">Escribe un nombre para la
                  tienda.</small>
              </div>
            </div>
            <div class="col-6 md:col-2">
              <div class="field">
                <label for="nombre">Id tienda</label>
                <InputText id="id_tienda" name="id_tienda" v-model.trim="tienda.id_tienda" :disabled="editMode"/>
              </div>
            </div>
            <div class="col-12 md:col-4">
              <div class="field">
                <label for="nombre_amigable">Nombre descriptivo*</label>
                <InputText id="nombre_amigable" name="nombre_amigable"
                  v-tooltip.top.focus="'Nombre de la tienda en unico.com.co'" v-model.trim="tienda.nombre_amigable"
                  :class="{ 'p-invalid': submitted && v$.tienda.nombre_amigable.$invalid }" />
                <small class="p-error" v-if="submitted && v$.tienda.nombre_amigable.$invalid">Escribe un nombre amigable
                  para la
                  tienda.</small>
              </div>
            </div>
            <div class="col-6 md:col-2">
              <div class="field">
                <label for="logo">Logo*</label>
                <FileUpload mode="basic" name="demo[]" accept="image/*" :maxFileSize="4000000" :customUpload="true"
                  @uploader="subirImagen" :auto="true" chooseLabel="Logo" />
              </div>
            </div>
            <div class="col-6 md:col-2">
              <Image imageClass="h-6rem" :src="logo" :alt="tienda.nombre" />
            </div>
          </div>
          <div class="grid p-fluid">
            <div class="col-12 md:col-4">
              <div class="field">
                <label for="descripcion">Descripción</label>
                <InputText id="descripcion" name="descripcion" v-model.trim="tienda.descripcion" />
              </div>
            </div>
            <div class="col-12 md:col-4">
              <div class="field">
                <label for="pais">País*</label>
                <InputPaises :selectedItems="handleInputPaises" :itemsInDB="tienda.pais"
                  :class="{ 'p-invalid': submitted && v$.tienda.pais.$invalid }" />
                <small class="p-error" v-if="submitted && v$.tienda.pais.$invalid">Escribe el país de la tienda.</small>
              </div>
            </div>
            <div class="col-12 md:col-4">
              <div class="field">
                <label for="ciudad">Ciudad*</label>
                <InputCiudades :selectedItems="handleInputCiudades" :itemsInDB="ciudad_estado"
                  :country="tienda.pais ? tienda.pais : 'Colombia'"
                  :class="{ 'p-invalid': submitted && v$.ciudad_estado.$invalid}" />
                <small class="p-error" v-if="submitted && v$.ciudad_estado.$invalid">Escoje una ciudad para la
                  tienda.</small>
              </div>
            </div>
          </div>
          <div class="grid p-fluid">
            <div class="col-12 md:col-4">
              <div class="field">
                <label for="responsable_tienda">Responsable de la tienda*</label>
                <InputText id="responsable_tienda" name="responsable_tienda" v-model.trim="tienda.responsable_tienda"
                  :class="{ 'p-invalid': submitted && v$.tienda.responsable_tienda.$invalid }" />
                <small class="p-error" v-if="submitted && v$.tienda.responsable_tienda.$invalid">Escribe el nombre del
                  responsable de la
                  tienda.</small>
              </div>
            </div>
            <div class="col-12 md:col-4">
              <div class="field">
                <label for="correo">Correo*</label>
                <InputText id="correo" name="correo" v-model.trim="tienda.correo"
                  :class="{ 'p-invalid': submitted && v$.tienda.correo.$invalid }" />
                <small class="p-error" v-if="submitted && v$.tienda.correo.$invalid">Escribe un correo para la
                  tienda.</small>
              </div>
            </div>
            <div class="col-12 md:col-4">
              <div class="field">
                <label for="celular">Celular*</label>
                <InputMask id="celular" name="celular" v-model="tienda.celular" mask="999 999 9999"
                  :class="{ 'p-invalid': submitted && v$.tienda.celular.$invalid }" />
                <small class="p-error" v-if="submitted && v$.tienda.celular.$invalid">Escribe un celular para la
                  tienda.</small>
              </div>
            </div>
          </div>
          <div class="grid p-fluid">
            <div class="col-12 md:col-4">
              <div class="field">
                <label for="numero_local">Número de local</label>
                <InputText id="numero_local" name="numero_local" v-model.trim="tienda.numero_local" />
              </div>
            </div>
            <div class="col-12 md:col-4">
              <div class="field">
                <label for="direccion">Dirección*</label>
                <InputText id="direccion" name="direccion" v-model.trim="tienda.direccion"
                  :class="{ 'p-invalid': submitted && v$.tienda.direccion.$invalid }" />
                <small class="p-error" v-if="submitted && v$.tienda.direccion.$invalid">Escribe una dirección para la
                  tienda.</small>
              </div>
            </div>
            <div class="col-12 md:col-4">
              <div class="field">
                <label for="telefono">Teléfono fijo del local</label>
                <InputMask id="telefono" name="telefono" mask="999 999 9999" v-model="tienda.telefono" />
              </div>
            </div>
          </div>
          <div class="grid p-fluid">
            <div class="col-12 md:col-4">
              <div class="field">
                <label for="stock_seguridad">Stock de seguridad*</label>
                <InputText id="stock_seguridad" name="stock_seguridad" v-model.number="tienda.stock_seguridad"
                  :class="{ 'p-invalid': submitted && v$.tienda.stock_seguridad.$invalid }" />
                <small class="p-error" v-if="submitted && v$.tienda.stock_seguridad.$invalid">Escribe un stock mínimo
                  para la
                  tienda.</small>
              </div>
            </div>
            <div class="col-12 md:col-4">
              <div class="field">
                <label for="id_tiempo_entrega">Tiempo de entrega*</label>
                <InputTiemposEntrega :selectedItems="handleInputTiemposEntrega" :itemsInDB="tienda.id_tiempo_entrega"
                  :class="{ 'p-invalid': submitted && v$.tienda.id_tiempo_entrega.$invalid }" />
                <small class="p-error" v-if="submitted && v$.tienda.id_tiempo_entrega.$invalid">Selecciona un tiempo de
                  entrega para la
                  tienda.</small>
              </div>
            </div>
            <div class="col-6 md:col-2">
              <div class="field">
                <label for="devoluciones">Devoluciones</label>
                <ToggleButton style="text-align: justify;" v-model="tienda.devoluciones" onLabel="Si" offLabel="No" />
              </div>
            </div>
            <div class="col-6 md:col-2">
              <div class="field">
                <label for="nombre_transportadora">Habilitada</label>
                <ToggleButton style="text-align: justify;" v-model="tienda.habilitado" onLabel="Si" offLabel="No" />
              </div>
            </div>
          </div>
          <div class="grid p-fluid">
            <div class="col-12 md:col-4">
              <div class="field">
                <label for="id_transportadora">Transportadora*</label>
                <InputTransportadora :selectedItems="handleInputTransportadora" :itemsInDB="tienda.id_transportadora"
                  :class="{ 'p-invalid': submitted && v$.tienda.id_transportadora.$invalid }" />
                <small class="p-error" v-if="submitted && v$.tienda.id_transportadora.$invalid">Selecciona una o más
                  transportadoras para la tienda.</small>
              </div>
            </div>
            <div class="col-12 md:col-4">
              <div class="field">
                <label for="url_amigable">URL amigable</label>
                <InputText id="url_amigable" name="url_amigable" v-model.trim="tienda.url_amigable" />
              </div>
            </div>
            <div class="col-12 md:col-4">
              <div class="field">
                <label for="gestor">Gestor de tienda</label>
                <InputText id="gestor" name="gestor" v-model.trim="tienda.gestor"
                  :class="{ 'p-invalid': submitted && v$.tienda.gestor }" />
                <small class="p-error" v-if="submitted && v$.tienda.gestor">Escribe un gestor para la tienda.</small>
              </div>
            </div>
          </div>
          <Divider align="left">
            <div class="inline-flex align-items-center">
              <b>Información técnica</b>
            </div>
          </Divider>
          <div class="grid p-fluid">
            <div class="col-12 md:col-4">
              <div class="field">
                <label for="id_tipo_integracion">Tipo de integración</label>
                <InputTipoIntegracion :selectedItems="handleInputTipoIntegracion"
                  :itemsInDB="tienda.id_tipo_integracion" />
              </div>
            </div>
            <div class="col-12 md:col-8">
              <div class="field">
                <label for="datos_integracion">Datos de integración</label>
                <Textarea id="datos_integracion" rows="5" name="datos_integracion" v-model="tienda.datos_integracion" />
              </div>
            </div>
          </div>
          <template #footer>
            <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="hideDialog()" />
            <Button label="Guardar" icon="pi pi-check" class="p-button-text" @click="guardarTienda(!v$.$invalid)" />
          </template>
        </Dialog>
        <!-- Fin formulario crear o editar tiendas -->
      </div>
    </div>
    <Toast />
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import { obtenerTodo,actualizar,crear} from "@/api/clienteHttpService";
import { required, email } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

export default {
  setup: () => ({ v$: useVuelidate() }),
  data: () => ({
    tiendas: null,
    loading: true,
    logo: "",
    tienda: {},
    ciudad_estado: "",
    tiendaDialog: false,
    selectedProducts: null,
    filters: {},
    submitted: false,
    autoValue: null,
    autoFilteredValue: [],
    editMode: false
  }),
  validations() {
    return {
      ciudad_estado: { required },
      tienda: {
        nombre: { required },
        nombre_amigable: { required },
        pais: { required },
        responsable_tienda: { required },
        correo: { required, email },
        celular: { required },
        direccion: { required },
        stock_seguridad: { required },
        id_transportadora: { required },
        id_tiempo_entrega: { required },
      },
    };
  },
  created() {
    this.initFilters();
    this.obtenerTienda();
  },
  methods: {
    handleInputTransportadora(transportadoras) {
      transportadoras.map((e) => { return e.id; });
      this.tienda.id_transportadora = JSON.stringify(transportadoras.map((e) => { return e.id; }));
    },
    handleInputTiemposEntrega(tiempo) {
      this.tienda.id_tiempo_entrega = tiempo[0] ? tiempo[0].id : null;
    },
    handleInputTipoIntegracion(tipo_integracion) {
      this.tienda.id_tipo_integracion = tipo_integracion[0] ? tipo_integracion[0].id : null;
    },
    handleInputPaises(pais) {
      this.tienda.pais = pais;
    },
    handleInputCiudades(ciudad) {
      this.ciudad_estado = ciudad;
    },
    showSuccess() {
      this.$toast.add({ severity: "success", summary: "Tienda: " + this.tienda.nombre_amigable, detail: "Creada correctamente", life: 3000 });
    },
    subirImagen(event) {
      this.logo = event.files[0].objectURL;
      this.tienda.logo = event.files[0];
    },
    async obtenerTienda() {
      try {
        const resp = await obtenerTodo("/tiendas");
        this.tiendas = resp;
        this.loading = false;
      }
      catch (error) {
        console.log(error);
      }
    },
    nuevaTienda() {
      this.editMode = false;
      this.tienda = {
        pais: "Colombia",
        devoluciones: false,
        habilitado: false,
      };
      this.logo = "";
      this.showDialog();
    },
    editarTienda(tienda) {
      this.editMode = true;
      this.tienda = { ...tienda };
      this.ciudad_estado = this.tienda.ciudad + ', ' + this.tienda.estado
      this.logo = this.tienda.logo;
      this.showDialog();
    },
    async guardarTienda(isFormValid) {
      this.submitted = true;
      this.tienda.ciudad = this.ciudad_estado.split(', ', 2)[0];
      this.tienda.estado = this.ciudad_estado.split(', ', 2)[1];
      var array = this.tienda.id_transportadora.replace(/[{}]/g, '').split(',');
      if (isFormValid) {
        if (this.tienda.id) {
          if (this.tienda.id_transportadora=='{NULL}'||this.tienda.id_transportadora=='{'+array[0]+'}'){
              console.log('no se pudo actualizar');
              this.$toast.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Asignar transportadora',
                    life: 5000
                });
          }else{
             console.log("Actualiza");
             console.log(this.tienda);
          const resp = await actualizar("/tiendas", this.tienda);
          if (resp == 'Created') {
            this.hideDialog();
            this.showSuccess();
            this.obtenerTienda();
          }
          }
        }
        else {
          const resp = await crear("/tiendas", this.tienda);
          if (resp == 'Created') {
            this.hideDialog();
            this.showSuccess();
            this.obtenerTienda();
          }
        }
      }
    },
    showDialog() {
      this.tiendaDialog = true;
    },
    hideDialog() {
      this.tiendaDialog = false;
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
  }
};
</script>
