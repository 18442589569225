<template>
  <div class="grid flex justify-content-center">
    <div class="col-12">
      <div class="card">
        <Toast
          :breakpoints="{ '920px': { width: '100%', right: '0', left: '0' } }"
        />
        <audio id="soundNotify" hidden>
          <source src="@/assets/sounds/SD_ALERT_44.mp3" type="audio/mpeg" />
        </audio>
        <Toolbar class="mb-4">
          <template v-slot:start>
            <h5 class="m-4 md:m-2 md:mb-4">Pedidos</h5>
            <InputTienda
              v-if="this.tienda.name_rol == 'admin'"
              :selectedItems="handleInputTienda"
              :limit="1"
            />
          </template>
          <template v-slot:end>
            <div class="grid p-fluid">
              <div class="col-3 md:col-3">
                <div class="field">
                  <label for="identificacion_cliente" class="mb-2"
                    >Identificación cliente</label
                  >
                  <InputText
                    type="text"
                    id="identificacion_cliente"
                    name="identificacion_cliente"
                    placeholder="Buscar"
                    v-model="identificacion_cliente_filtro"
                  ></InputText>
                </div>
              </div>
              <div class="col-3 md:col-3">
                <div class="field">
                  <label for="referencia_pedido" class="mb-2"
                    >Referencia de pedido</label
                  >
                  <InputText
                    type="text"
                    id="referencia_pedido"
                    name="referencia_pedido"
                    placeholder="Buscar"
                    v-model="referencia_pedido_filtro"
                  ></InputText>
                </div>
              </div>
              <div class="flex-custom col-4">
                <div></div>
                <div class="flex">
                  <Button
                    label="Buscar"
                    icon="pi pi-search"
                    class="p-button-primary w-full mr-2 h-2"
                    @click="filtrar()"
                  />
                  <Button
                    icon="pi pi-external-link"
                    label="Exportar"
                    @click="exportCSV()"
                    class="w-full mr-2"
                  />
                  <Dialog
                    v-model:visible="userDialogExport"
                    :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
                    :style="{ width: '30vw' }"
                    header="
                     Exportar excel
                    "
                    :modal="true"
                    class="p-fluid"
                  >
                    <p class="mb-2">
                      Selecciona un rango de fechas para exportar los pedidos
                    </p>
                    <div class="grid align-items-center">
                      <div class="col-12 md:col-12">
                        <div class="field">
                          <label for="fechaDesde">Fecha desde</label>
                          <Calendar
                            inputId="icon1"
                            v-model="fecha_desde"
                            :showIcon="true"
                            dateFormat="mm-dd-yy"
                            placeholder="Seleccionar..."
                          />
                        </div>
                      </div>
                      <div class="field col-12 md:col-12">
                        <label for="fechaHasta">Fecha hasta</label>
                        <Calendar
                          inputId="icon2"
                          v-model="fecha_hasta"
                          :showIcon="true"
                          dateFormat="mm-dd-yy"
                          placeholder="Seleccionar..."
                        />
                      </div>
                    </div>
                    <template #footer>
                      <Button
                        label="Cancelar"
                        icon="pi pi-times"
                        class="p-button-text"
                        @click="userDialogExport = false"
                      />
                      <Button
                        label="Exportar"
                        icon="pi pi-check"
                        class="p-button-text"
                        @click="exportarPedidosTienda()"
                      />
                    </template>
                  </Dialog>
                </div>
              </div>
            </div>
          </template>
        </Toolbar>
        <!-- <Toolbar class="mb-4">
          <template #start>
            <h5 class="m-4">Pedidos</h5>
            <InputTienda
              v-if="this.tienda.name_rol == 'admin'"
              :selectedItems="handleInputTienda"
              :limit="1"
            />
          </template>
        </Toolbar> -->
        <TabView>
          <TabPanel header="Pedidos por gestionar">
            <DataTable
              ref="dt"
              :value="pedidosProducto"
              dataKey="id"
              :loading="loading"
              stripedRows
              responsiveLayout="scroll"
              @sort="handleSort"
            >
              <Column header="Info. cliente">
                <template #body="slotProps">
                  <div class="flex">
                    <em
                      class="pi pi-user m-2 text-blue-500 text-xl cursor-pointer"
                      v-tooltip.left="'Información del cliente'"
                      @click="verCliente(slotProps.data)"
                    ></em>
                  </div>
                </template>
              </Column>
              <Column header="Opciones">
                <template #body="slotProps">
                  <div class="flex">
                    <em
                      v-if="this.tienda.name_rol == 'tienda'"
                      class="pi pi-check m-2 text-green-500 text-xl cursor-pointer"
                      v-tooltip.top="'Aceptar pedido'"
                      @click="aceptarProductoPedido(slotProps.data)"
                    ></em>
                    <em
                      v-if="this.tienda.name_rol == 'tienda'"
                      class="pi pi-times m-2 text-red-500 text-xl cursor-pointer"
                      v-tooltip.top="'Rechazar pedido'"
                      @click="cancelarPedido(slotProps.data)"
                    ></em>
                  </div>
                </template>
              </Column>
              <Column field="created_at" header="Fecha" :sortable="true">
                <template #body="slotProps">
                  {{ formatDate(slotProps.data.created_at) }}
                </template>
              </Column>
              <Column header="Ref. Pedido">
                <template #body="slotProps">
                  {{ slotProps.data.referencia_pedido }}
                </template>
              </Column>
              <Column header="Ref. Producto">
                <template #body="slotProps">
                  {{ slotProps.data.referencia_producto }}
                </template>
              </Column>
              <Column field="foto" header="Foto" :sortable="false">
                <template #body="slotProps">
                  <Image
                    :src="slotProps.data.foto"
                    alt="Image"
                    width="50"
                    height="50"
                    preview
                  />
                </template>
              </Column>
              <Column field="producto" header="Producto" :sortable="false">
                <template #body="slotProps">
                  {{ slotProps.data.producto }}
                </template>
              </Column>
              <Column header="Talla">
                <template #body="slotProps">
                  {{ slotProps.data.talla }}
                </template>
              </Column>
              <Column header="Color">
                <template #body="slotProps">
                  <div class="flex flex-column align-items-center">
                    <div class="mb-2">
                      {{ capitalizeFirstLetter(slotProps.data.color) }}
                    </div>
                    <div
                      v-if="slotProps.data.color == 'multicolored'"
                      :style="{
                        width: '20px',
                        height: '20px',
                        background: slotProps.data.color,
                      }"
                      class="items_color multicolor"
                    ></div>
                    <div
                      v-else
                      :style="{
                        width: '20px',
                        height: '20px',
                        background: slotProps.data.color,
                      }"
                      class="items_color"
                    ></div>
                  </div>
                </template>
              </Column>
              <Column header="Precio">
                <template #body="slotProps">
                  ${{ formatThousands(slotProps.data.precio) }}
                </template>
              </Column>
              <Column header="Cant.">
                <template #body="slotProps">
                  {{ slotProps.data.cantidad }}
                </template>
              </Column>
              <Column header="Estado">
                <template #body="slotProps">
                  <span class="product-badge status-payed">{{
                    slotProps.data.estado
                  }}</span>
                </template>
              </Column>
            </DataTable>
            <Paginator
              :rows="1"
              :totalRecords="totalRecords"
              @page="onPage($event)"
            ></Paginator>
          </TabPanel>
          <TabPanel header="Histórico de pedidos">
            <HistoricoTabla
              :referencia_pedido_filtro="referencia_pedido_filtro"
              :identificacion_cliente_filtro="identificacion_cliente_filtro"
              ref="historicoTabla"
            />
          </TabPanel>
          <TabPanel header="Devolución de pedidos">
            <div>
              <DevolucionesTabla
                :referencia_pedido_filtro="referencia_pedido_filtro"
                :identificacion_cliente_filtro="identificacion_cliente_filtro"
                ref="DevolucionesTabla"
              />
            </div>
          </TabPanel>
        </TabView>
        <Dialog
          v-model:visible="userDialog"
          :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
          :style="{ width: '30vw' }"
          header="Rechazar pedido"
          :modal="true"
        >
          <div class="confirmation-content">
            <em
              class="pi pi-exclamation-triangle mr-3"
              style="font-size: 2rem"
            />
            <span
              >Lamentamos que tengas que rechazar este pedido, por favor
              indícanos el motivo:</span
            >
          </div>
          <div class="grid p-fluid">
            <div class="col pt-3">
              <Dropdown
                v-model="actualizar_pedido.id_detalle_estado"
                :options="estadosPedido"
                optionLabel="descripcion"
                optionValue="id"
                placeholder="Seleccione..."
                :class="{
                  'p-invalid':
                    submitted_reject &&
                    v$.actualizar_pedido.id_detalle_estado.$invalid,
                }"
              />
            </div>
          </div>
          <template #footer>
            <Button
              label="Rechazar"
              class="p-button-outlined p-button-danger"
              @click="cancelar()"
            />
          </template>
        </Dialog>

        <Dialog
          v-model:visible="clienteDialog"
          :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
          :style="{ width: '30vw' }"
          header="Información del cliente"
          :modal="true"
        >
          <div class="confirmation-content pb-3">
            <span
              >Utiliza está información para realizar la factura de los
              productos:</span
            >
          </div>
          <table class="infoCliente">
            <tr>
              <th>Identificación:</th>
              <td>{{ formatThousands(pedido.numero_identificacion) }}</td>
            </tr>
            <tr>
              <th>Nombre:</th>
              <td>{{ pedido.nombre_cliente }} {{ pedido.apellido_cliente }}</td>
            </tr>
            <tr>
              <th>Dirección:</th>
              <td>{{ pedido.direccion }}</td>
            </tr>
            <tr>
              <th>Ciudad:</th>
              <td>
                {{ pedido.ciudad_cliente }} - {{ pedido.departamento_cliente }}
              </td>
            </tr>
            <tr>
              <th>Celular:</th>
              <td>{{ formatPhone(pedido.celular_cliente) }}</td>
            </tr>
            <tr>
              <th>Correo:</th>
              <td>{{ pedido.email_cliente }}</td>
            </tr>
          </table>
          <template #footer>
            <Button
              label="Cerrar"
              class="p-button-outlined"
              @click="hideDialogCliente()"
            />
          </template>
        </Dialog>
        <Dialog
          v-model:visible="guiaDialog"
          :header="pedido.referencia ? pedido.referencia : ''"
          :modal="true"
          class="p-fluid"
        >
          <div class="card">
            <div
              class="flex justify-content-center flex-wrap card-container blue-container"
            >
              <div
                class="flex align-items-center justify-content-center w-4rem h-2rem"
              >
                <em
                  class="pi pi-file-pdf mr-20 text-black-500 text-xl cursor-pointer"
                  v-tooltip.left="'Descargar guía'"
                  @click="generarGuia(pedido.productos[0].urlrotulos)"
                ></em>
              </div>
              <div
                class="flex align-items-center justify-content-center w-4rem h-2rem"
              >
                <em
                  class="pi pi-file-pdf mr-20 text-black-500 text-xl cursor-pointer"
                  v-tooltip.left="'Descargar relación de envío'"
                  @click="generarGuia(pedido.productos[0].urlrelacionenvio)"
                ></em>
              </div>
            </div>

            <div
              class="flex justify-content-center flex-wrap card-container blue-container"
            >
              <div
                class="flex align-items-center justify-content-center w-4rem h-2rem"
              >
                <label for="">Guía</label>
              </div>
              <div
                class="flex align-items-center justify-content-center w-4rem h-3rem"
              >
                <label for="">Relación de envío</label>
              </div>
            </div>
          </div>
          <div
            class="flex justify-content-end flex-wrap card-container blue-container h-2rem"
          >
            <a
              href="http://clientes.aplicaciones.tcc.com.co/ClientesTCCWeb/Login.html"
              ><Button
                label="Ir a tcc"
                icon="pi pi-arrows-h"
                class="p-button-text h-3rem"
              ></Button
            ></a>
          </div>
        </Dialog>
      </div>
    </div>
  </div>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { FilterMatchMode } from "primevue/api";
import { obtenerTodo, crear } from "@/api/clienteHttpService";
import Echo from "laravel-echo";
import { decrypt } from "@/auth-guard";
window.Pusher = require("pusher-js");
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      messages: {
        severity: "",
        content: "",
      },
      loading: true,

      pedidosProducto: [],
      historico: [],
      referencia_pedido_filtro: null,
      identificacion_cliente_filtro: null,
      fecha_desde: null,
      fecha_hasta: null,
      orden: null,
      active: 0,
      pedido: {},
      mostrarContenido: false,
      actualizar_pedido: {},
      estados: null,
      estadosPedido: null,
      userDialog: false,
      userDialogExport: false,
      clienteDialog: false,
      filters: {},
      submitted_reject: false,
      totalRecords: null,
      filtrotemp: null,
      devoluciones: [],
      guiaDialog: false,
    };
  },
  validations() {
    return {
      actualizar_pedido: {
        id_detalle_estado: { required },
      },
    };
  },
  mounted() {
    this.obtenerEstado();
    this.obtenerEstadoPedido();
    window.Echo = new Echo({
      broadcaster: "pusher",
      key: process.env.VUE_APP_WSKEY,
      wsHost: process.env.VUE_APP_WSHOST,
      wsPort: process.env.VUE_APP_WSPORT,
      forceTLS: false,
      disableStats: true,
      encrypted: true,
    });
    window.Echo.channel("pedidos").listen("NuevoPedido", (e) => {
      e.message.productos.forEach((element) => {
        this.$toast.removeAllGroups();
        if (element.id_tienda == this.tienda.id_tienda) {
          this.$toast.add({
            severity: "success",
            summary: "Tienes un nuevo pedido",
            detail: "Ref: " + e.message.referencia,
          });
          const soundNotify = document.getElementById("soundNotify");
          soundNotify.play();
          this.obtenerPedidos();
        }
      });
    });
  },
  created() {
    this.tienda = decrypt(localStorage.getItem("id"));
    this.obtenerPedidos();
    this.initFilters();
  },
  methods: {
    exportarPedidosTienda() {
      this.loading = true;
      let filtrosSeleccionados = {
        fecha_desde: this.fecha_desde ? this.fecha_desde.toISOString() : null,
        fecha_hasta: this.fecha_hasta ? this.fecha_hasta.toISOString() : null,
      };
      let filtrados = Object.fromEntries(
        Object.entries(filtrosSeleccionados).filter((value) => value[1])
      );
      //validar si el objeto esta vacio
      if (
        filtrosSeleccionados.fecha_desde == null ||
        filtrosSeleccionados.fecha_hasta == null
      ) {
        this.$toast.add({
          severity: "warn",
          summary: "Alerta",
          detail: "Debe de seleccionar un rango de fechas para exportar",
          life: 3000,
        });
        this.loading = false;
        return;
      }
      let parametrosUrl = "?";
      Object.entries(filtrados).forEach(([key, value]) => {
        parametrosUrl =
          parametrosUrl + "&" + key + "=" + this.formatDateExportar(value);
      });
      location.href = `${process.env.VUE_APP_BASE_URL_BACKEND}export/pedidosTienda${parametrosUrl}&id_tienda=${this.tienda.id_tienda}`;
      this.loading = false;
      this.$toast.add({
        severity: "success",
        summary: "Exportación exitosa",
        detail: "Se ha exportado el archivo correctamente",
        life: 3000,
      });
      this.fecha_desde = null;
      this.fecha_hasta = null;
    },
    formatDateExportar(fecha) {
      const date = new Date(fecha);
      const year = date.getUTCFullYear();
      const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Los meses van de 0 a 11
      const day = String(date.getUTCDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
    filtrar() {
      this.obtenerPedidos();
      this.$refs.historicoTabla.obtenerPedidos();
      this.$refs.DevolucionesTabla.obtenerPedidos();
    },
    cargarContenido() {
      console.log("cargarContenido");
      this.mostrarContenido = true;
    },
    handleSort(event) {
      if (event.sortOrder == 1 && event.sortField == "created_at") {
        this.ordernador = "1";
      } else if (event.sortOrder == -1 && event.sortField == "created_at") {
        this.ordernador = "2";
      }
      this.obtenerPedidos();
    },
    async obtenerPedidos(pagina = 0) {
      this.loading = true;
      this.pedidosProducto = [];
      this.devoluciones = [];
      this.historico = [];
      let complemento = "";
      if (this.referencia_pedido_filtro !== null) {
        complemento += `&referencia_pedido=${this.referencia_pedido_filtro}`;
      }
      if (this.identificacion_cliente_filtro !== null) {
        complemento += `&numero_identificacion=${this.identificacion_cliente_filtro}`;
      }
      const pedidos = await obtenerTodo(
        "/pedidos/tiendaBack/" +
          this.tienda.id_tienda +
          "?paginado=" +
          pagina +
          "&orden=" +
          this.orden +
          "&estados=5" +
          complemento
      );
      if (!pedidos.message) {
        this.pedidosProducto = pedidos.pedidos;
        this.totalRecords = pedidos.paginas;
        // pedidos.forEach((pedido) => {
        //   pedido.productos.forEach((producto) => {
        //     producto.identificacion_cliente = pedido.identificacion_cliente;
        //     producto.nombre_cliente = pedido.nombre_cliente;
        //     producto.celular = pedido.celular;
        //     producto.email = pedido.email;
        //     producto.ciudad = pedido.ciudad;
        //     producto.departamento = pedido.departamento;
        //     switch (producto.id_detalle_estado) {
        //       case 5:
        //         this.pedidosProducto.push(producto);
        //         break;
        //       case 14:
        //         this.devoluciones.push(producto);
        //         break;
        //       default:
        //         // this.historico.push(producto);
        //         if ([6,11,14,19,20,27,28,29].includes(producto.id_detalle_estado)) {
        //           this.historico.push(producto);
        //         }
        //         break;
        //     }
        //   });
        // })
      }
      this.loading = false;
    },
    async verCliente(pedido) {
      this.pedido = { ...pedido };
      this.clienteDialog = true;
    },
    handleInputTienda(tienda) {
      this.tienda.id_tienda = tienda[0].id;
      this.obtenerPedidos();
    },
    async aceptarProductoPedido(pedido) {
      this.loading = true;
      this.actualizar_pedido = {
        id_tienda: pedido.id_tienda,
        referencia: pedido.referencia_pedido,
        id_unique: pedido.id_unique,
        id_estado: 6,
      };
      const aceptado = await crear("/tiendas/pedido", this.actualizar_pedido);
      console.log(aceptado);
      this.$toast.add({
        severity: "success",
        summary: "Pedido aceptado",
        detail: "Notificaremos al cliente que su pedido está listo.",
        life: 5000,
      });
      //this.obtenerPedidos();
      location.reload();
    },
    async obtenerEstado() {
      const est = await obtenerTodo("/estados");
      this.estados = est;
    },
    async obtenerEstadoPedido() {
      const est = await obtenerTodo("/estados/pedido");
      this.estadosPedido = est;
    },
    async cancelar() {
      this.submitted_reject = true;
      if (!this.v$.actualizar_pedido.id_detalle_estado.$invalid) {
        this.actualizar_pedido = await crear(
          "/tiendas/pedido",
          this.actualizar_pedido
        );
        this.$toast.add({
          severity: "error",
          summary: "Pedido rechazado",
          detail:
            "Notificaremos al cliente que su pedido no podrá ser entregado",
          life: 5000,
        });
        this.userDialog = false;
        this.obtenerPedidos();
      }
    },
    async onPage(event) {
      await this.obtenerPedidos(event.page);
    },
    async cancelarPedido(pedido) {
      // this.pedido = { ...pedido };
      this.actualizar_pedido = {
        id_tienda: pedido.id_tienda,
        referencia: pedido.referencia_pedido,
        id_unique: pedido.id_unique,
        id_estado: 11,
        id_detalle_estado: "",
      };
      this.userDialog = true;
    },
    hideDialog() {
      this.userDialog = false;
      this.submitted = false;
      this.formData = {};
    },
    hideDialogCliente() {
      this.clienteDialog = false;
    },
    exportCSV() {
      this.userDialogExport = true;
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      return new Intl.DateTimeFormat("es-CO").format(date);
    },
    formatThousands(number) {
      return new Intl.NumberFormat("es-CO").format(number);
    },
    formatPhone(phoneNumber) {
      return phoneNumber.replace(
        /(\d{1,4})(\d{3})(\d{3})(\d{4})/,
        "$1 $2 $3 $4"
      );
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
  },
};
</script>
<style>
table.infoCliente {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  border: 1px solid #ddd;
}

table.infoCliente th,
td {
  text-align: left;
  padding: 10px;
}

table.infoCliente tr:nth-child(even) {
  background-color: #f2f2f2;
}

.product-badge {
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;
  background: #abc9fb;
  color: #183462;
}

.product-badge.status-payed {
  background: #c8e6c9;
  color: #256029;
}

.product-badge.status-facturado {
  background: #c8e6c9;
  color: #256029;
}

.product-badge.status-cancelado {
  background: #ffcdd2;
  color: #c63737;
}

.items_color {
  border: 1px solid #999;
  border-radius: 50%;
  height: 1.5rem;
  width: 1.5rem;
}
.multicolor {
  background: linear-gradient(70deg, #fff810 30%, transparent 0),
    linear-gradient(30deg, #63e89e 60%, #ff7ee3 0);
}
</style>
