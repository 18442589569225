<script>
export default {
    props: ['selectedItems', 'itemsInDB'],
    data: () => ({
        paisSeleccionado: null,
        paises: null,
        filtroPaises: null,
        loading: true
    }),
    created() {
        this.obtenerPaises().then(data => this.paises = data);
        this.findItemsOfDB(this.itemsInDB);
    },
    methods: {
        obtenerPaises(){
            return fetch('../data/paises.json').then(res => res.json()).then(d => d.data);
        },
        paisDefault(){
            return 'Colombia';
        },
        buscarPais(event) {
            if (!event.query.trim().length) {
                this.filtroPaises = [...this.paises];
            }
            else {
                this.filtroPaises = this.paises.filter((pais) => {
                    return pais.name.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }
		},
        findItemsOfDB(name){
            this.paisSeleccionado = name ? name : this.paisDefault();
        },
        handleChange(event) {
            this.selectedItems(event.value.name);
        }
    }
}
</script>
<template>
    <AutoComplete v-model="paisSeleccionado" :suggestions="filtroPaises" :forceSelection="true" @item-select="handleChange" @complete="buscarPais($event)" field="name" /> 
</template>